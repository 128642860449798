import {Component, OnInit} from '@angular/core';
import {ImageItem, ImageSize, ThumbnailsPosition} from '@ngx-gallery/core';
import {Lightbox} from 'ngx-lightbox';

@Component({
  selector: 'app-galerie',
  templateUrl: './galerie.component.html',
  styleUrls: ['./galerie.component.css']
})
export class GalerieComponent implements OnInit {

  album = [
    {
      src: 'assets/images/gallery/_DSC7031.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6947.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC7006.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6953.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6996.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6969.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6974.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6941.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC7020.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6983.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6952.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC6992.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC7011.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC7014.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    },
    {
      src: 'assets/images/gallery/_DSC7024.jpg',
      thumb: 'assets/images/gallery/_DSC7031.jpg',
    }
  ];

  constructor(private lightbox: Lightbox) {
  }


  ngOnInit() {


  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }
}
