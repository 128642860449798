<header class="fixed-top scroll-change" data-menu-anima="fade-bottom">
  <div class="navbar navbar-default mega-menu-fullwidth navbar-fixed-top" role="navigation">
    <div class="navbar navbar-main">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" (click)="navbarToggle()">
            <i class="fa fa-bars"></i>
          </button>
          <a class="navbar-brand" href="index.html">
            <img class="logo-default" src="/assets/images/logo-text.png" alt="logo"/>
            <img class="logo-retina" src="/assets/images/logo-text.png" alt="logo"/>
          </a>
        </div>
        <div class="navbar-collapse" [hidden]="navbarCollapsed" (window:resize)="onResize($event)">
          <div class="nav navbar-nav navbar-right">
            <ul class="nav navbar-nav">
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a role="button" [routerLink]="['/']">Home</a>
              </li>
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a role="button" routerLink="/servicii">Servicii</a>
              </li>
              <!--


              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a class="dropdown-toggle" role="button" routerLink="/galerie-foto">Galerie foto</a>
              </li>
              -->
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a role="button" routerLink="/colaboratori">Colaboratori</a>
              </li>
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a role="button" routerLink="/legislatie">Legislatie</a>
              </li>
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a class="dropdown-toggle" role="button" routerLink="/acces-proprietari">Acces Proprietari</a>
              </li>
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                <a class="dropdown-toggle" role="button" routerLink="/contact">Formular Contact</a>
              </li>

              <li>
                <a class="dropdown-toggle " style="color:#FF6A00" role="button" href="tel:0720695418"><b>Contactati-ne: 0720.695.418</b></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
