import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-chestionar',
  templateUrl: './chestionar.component.html',
  styleUrls: ['./chestionar.component.css']
})
export class ChestionarComponent implements OnInit {
  public data: any = {};

  errorMessage: any;
  showErrorMessage: boolean;
  showSuccessMessage: boolean;

  sending: boolean;

  constructor(private http: HttpClient) { }

  save(): void {
    if (this.sending) {
      return;
    }
    this.sending = true;

    this.showErrorMessage = false;
    this.showSuccessMessage = false;
    const emailPattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');

    if (!this.data.name) {
      this.showErrorMessage = true;
      this.errorMessage = 'Numele este obligatoriu';
      this.sending = false;
      return;
    }

    if (!this.data.message) {
      this.showErrorMessage = true;
      this.errorMessage = 'Textul solicitarii/sesizarii este obligatoriu';
      this.sending = false;
      return;
    }

    if (this.data.email && !emailPattern.test(this.data.email)) {
      this.showErrorMessage = true;
      this.errorMessage = 'Adresa de email este invalida';
      this.sending = false;
      return;
    }

    if (!this.data.agree) {
      this.showErrorMessage = true;
      this.errorMessage = 'Trebuie sa fiti de acord cu Termenii si conditiile administrator-bloc-bucuresti.ro';
      this.sending = false;
      return;
    }


    const arr = window.location.href.split('/');
    this.data.domain = arr[0] + '//' + arr[2];

    // add request to send email or into mysql
    this.http.post<any>('https://ts5f4i4003.execute-api.eu-west-1.amazonaws.com/dev/email/send', this.data).subscribe(
      res => {
        this.showSuccessMessage = true;
        this.data = {};
        this.sending = false;
      },
      (err: HttpErrorResponse) => {
        this.showErrorMessage = true;
        this.errorMessage = 'Serverul a intors o eroare. Va rugam reincercati';
        this.sending = false;
      });
  }

  ngOnInit(): void {
  }

}


