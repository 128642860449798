import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public data: any = {};

  name: any;
  email: any;
  subject: any;
  message: any;
  phone: any;
  errorMessage: any;
  showErrorMessage: boolean;
  showSuccessMessage: boolean;

  sending: boolean;
  private href: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  save(): void {
    if (this.sending) {
      return;
    }
    this.sending = true;

    this.showErrorMessage = false;
    this.showSuccessMessage = false;
    const emailPattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');

    if (!this.name || !this.email || !this.phone || !this.message) {
      this.showErrorMessage = true;
      this.errorMessage = 'Toate campurile sunt obligatorii';
      this.sending = false;
      return;
    }

    if (!emailPattern.test(this.email)) {
      this.showErrorMessage = true;
      this.errorMessage = 'Adresa de email este invalida';
      this.sending = false;
      return;
    }

    this.data.nameFrom = this.name;
    this.data.emailFrom = this.email;
    this.data.phoneFrom = this.phone;
    this.data.message = this.message;


    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': '5VDLEwcVsT9TAJiVV9UIY1IvpfLi54qU5k68JrS4' });
    const options = { headers: headers };

    // add request to send email or into mysql
    this.http.post<any>('https://t58kfuw6xb.execute-api.eu-central-1.amazonaws.com/default/contact-form', this.data, options).subscribe(
      res => {
        this.showSuccessMessage = true;
        this.name = '';
        this.email = '';
        this.phone = '';
        this.message = '';
        this.sending = false;
      },
      (err: HttpErrorResponse) => {
        this.showErrorMessage = true;
        this.errorMessage = 'Serverul a intors o eroare. Va rugam reincercati';
        this.sending = false;
      });
  }


}
