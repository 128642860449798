<app-header></app-header>

<div
  class="section-bg-image bg-top"
  style="background-image: url(&quot;assets/images/bckg1.png&quot;);">
  <div class="container content">
    <div class="row vertical-row">
      <div class="col-md-12">
        <div class="title-base text-left">
          <hr/>
          <h2>GALERIE FOTO</h2>
        </div>
      </div>
    </div>
    <br/><br/><br/>

    <div class="row ">
      <div *ngFor="let image of album; let i=index" class="col-md-4 boxed-inverse shadow-1 margin-top-20">
        <img [src]="image.src" (click)="open(i)"/>
      </div>
    </div>


  </div>

</div>

<app-footer></app-footer>
