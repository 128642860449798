import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-echipa',
  templateUrl: './echipa.component.html',
  styleUrls: ['./echipa.component.css']
})
export class EchipaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
