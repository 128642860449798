<footer class="footer-base">
  <div class="content">
    <div class="container">
      <div class="row">
        <div id="block-signflowblockfootercolumnlogo" class="col-md-3 footer-center text-left">
          <img class="img-rounded" width="120" src="/assets/images/logo.png" alt="alt">
        </div>
        <div id="block-signflowblockfootercolumnaddress" class="col-md-6 footer-left text-left padding-top-20">
          <p>Servicii Administrare Imobile - Bucuresti</p>
          <div class="tag-row">
            <span><a href="mailto:contact@administrator-bloc-bucuresti.ro">contact@administrator-bloc-bucuresti.ro</a></span>
            <span><a href="tel:0720695418">0720.695.418</a></span>
            <span><a href="tel:0741101036">0741.101.036</a></span>
          </div>
        </div>
        <div id="block-signflowblockfootercolumnsocialnetwork" class="col-md-3 footer-left text-right text-left-sm padding-top-20">
          <div class="btn-group social-group btn-group-icons">
            <a target="_blank" href="https://www.facebook.com/Administrator-Bloc-Bucuresti-110530384978430" >
              <i class="fa fa-facebook text-xs circle"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row copy-row">
      <div class="col-md-12 copy-text">
        © {{currentYear}} Servicii Administrare Imobile - Metropolitan Expert Solutions
        |
        <a href="/#/termeni-conditii" target="_blank">Termenii si conditii</a>
        <span>Solutie dezvoltata de <a
        href="https://romdev.ro">RomDev Stack S.R.L.</a></span>
      </div>
    </div>
  </div>
</footer>
