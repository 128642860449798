import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {ServicesComponent} from './services/services.component';
import {HeaderComponent} from './common/header/header.component';
import {FooterComponent} from './common/footer/footer.component';
import {EchipaComponent} from './echipa/echipa.component';
import {LegislationComponent} from './legislation/legislation.component';
import {ChestionarComponent} from './chestionar/chestionar.component';
import {GalerieComponent} from './galerie/galerie.component';
import {ContactComponent} from './contact/contact.component';

import 'hammerjs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {LightboxModule} from 'ngx-lightbox';
import { TCComponent } from './tc/tc.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'servicii', component: ServicesComponent},
  {path: 'colaboratori', component: EchipaComponent},
  {path: 'legislatie', component: LegislationComponent},
  {path: 'galerie-foto', component: GalerieComponent},
  {path: 'acces-proprietari', component: ChestionarComponent},
  {path: 'contact', component: ContactComponent},
  { path: 'termeni-conditii', component: TCComponent},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { useHash: true });

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    HeaderComponent,
    FooterComponent,
    EchipaComponent,
    LegislationComponent,
    ChestionarComponent,
    GalerieComponent,
    ContactComponent,
    TCComponent
  ],
  imports: [
    BrowserModule,
    appRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    LightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
