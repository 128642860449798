<app-header></app-header>

<div class="section-empty">
  <div class="container content">
    <div class="row vertical-row">
      <div class="col-md-7 col-sm-6">
        <div class="title-base text-left">
          <hr/>
          <h2>Colaboratori</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="block-signflowblocksectioncontentleaderofarchitecture"
     class="section-bg-image border-bottom bg-top bg-no-repeat block-bg"
     style="background-image: url(&quot;assets/images/bckg2.png&quot;);">
  <div class="container content padding-top-20">
    <div class="row">
      <div class="col-md-6 boxed-inverse shadow-1">
        <h3>METROPOLITAN EXPERT DESIGN SRL <br/><small>Executant renovari si amenajari interiore</small></h3>
        <p>&nbsp;</p>
        <p>Colaborator: </p>
        <div>
          <ul>
            <li>CAPITAL INVEST DESIGN</li>
            <li>DERVAN BUILDING</li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 boxed-inverse shadow-1">
        <h3>REAL INSTAL SRL <br/><small>Executii instalatii sanitare si termice</small></h3>
        <p>&nbsp;</p>
        <p>Colaborator: </p>
        <div>
          <ul>
            <li>ROMSTAL</li>
            <li>BLACK SEA</li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="space"/>
    <div class="row">
      <div class="col-md-6 boxed-inverse shadow-1">
        <h3>PROCONFORT GRUP SRL <br/><small>Executant instalatii gaze naturale si revizii periodice - FIRMA  AUTORIZATA  ANRE</small></h3>
        <p>&nbsp;</p>
        <p>Colaborator: </p>
        <div>
          <ul>
            <li>DISTRIGAZ SUD</li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 boxed-inverse shadow-1">
        <h3>ALL IN ONE PROFESIONAL SOLUTION S.R.L. <br/><small>DEZINFECȚIE / DEZINSECȚIE / DERATIZARE</small></h3>
        <p>&nbsp;</p>
        <div>
          <ul>
            <li><a href="https://www.allinonesolution.ro" target="_blank">www.allinonesolution.ro</a> </li>
          </ul>
        </div>
      </div>
      <hr class="space"/>

</div>
  </div>
<app-footer></app-footer>
