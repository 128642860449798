<div class="container clear-padding">
  <div class="col-md-12 search-section">
    <h4 class="center product-search-title">Termeni si conditii pentru utilizarea siteului administrator-bloc-bucuresti.ro</h4>
    <br/>
    <p class="justify">PENTRU UTILIZAREA ACESTUI SITE ESTE NECESARA CITIREA SI ACCEPTAREA IN TOTALITATE A ACESTOR TERMENI SI CONDITII. VIZITAREA IN CONTINUARE A ACESTUI SITE PRESUPUNE ACCEPTAREA INTEGRALA A ACESTOR TERMENI SI CONDITII DE UTILIZARE A SITE-ULUI administrator-bloc-bucuresti.ro.</p>

    <p class="justify">Continutul website-lui administrator-bloc-bucuresti.ro este protejat de legile dreptului de autor. Toate drepturile sunt rezervate administrator-bloc-bucuresti.ro. Toate materialele (incluzand, fara limitari, toate articolele, textele, imaginile, logo-urile si design-ul) continute in acest site sunt protejate de legile dreptului de autor si nu pot fi reproduse, distribuite, transmise sau publicate fara acordul scris al administrator-bloc-bucuresti.ro. Este interzisa modificarea continutului acestor pagini, precum si copierea, distribuirea, transmiterea, publicarea, reproducerea, realizarea de creatii derivate din orice informatie sau serviciu obtinut de pe sau prin intermediul site-ului.</p>

    <p class="justify">Cu toate acestea, este posibila descarcarea sau copierea materialelor specifice disponibile in acest sens. Fiind destinate exclusiv scopurilor personale, non-comerciale, utilizarea lor in scopuri comerciale este interzisa.</p>

    <p class="justify">Pentru orice intrebare sau cerere de utilizare a materialelor de pe acest website, ne puteti contacta la contact@romdev.ro.</p>

    <p class="justify">Pentru a raspunde intrebarilor utilizatorilor si pentru a obtine date referitoare la accesul pe site-ul nostru, poate fi necesara furnizarea de informatii cu caracter personal, cum ar fi numele, e-mail-ul si numarul de telefon. Aceste informatii insa nu vor fi folosite pentru a transmite alte informatii despre serviciile institutiei noastre.</p>

    <p class="justify">administrator-bloc-bucuresti.ro se obliga sa asigure confidentialitatea vizitatorilor site-ului sau. Nu vor fi transmise catre terti nici un fel de informatii cu caracter personal, exceptie facand tertii parteneri care asigura buna functionare a acestui site.</p>

    <p class="justify">Pot fi furnizate catre terte persoane atat informatii de ordin general, trafic pe site, cat si alte informatii statistice care nu contin date cu caracter personal.</p>

    <p class="justify">Acest site utilizeaza tehnologii care permit colectarea anumitor informatii cu caracter tehnic despre utilizator: adresa de internet, sistemul de operare folosit, tipul browser-ului, si informatii despre traficul realizat pe site-ul nostru.</p>

    <p class="justify">Toate aceste informatii colectate prin intermediul site-ului de catre administrator-bloc-bucuresti.ro au scopul de a ajuta la imbunatatirea serviciilor sale.</p>

    <p class="justify">In functie de numarul de programari neonorate, entitatea isi rezerva dreptul sa limiteze accesul la programari al clientului respectiv.</p>

    <p class="justify">Va rugam sa cititi in continuare modul in care administrator-bloc-bucuresti.ro prelucreaza datele cu caracter personal si politica fisierelor de tip cookie.</p>

  </div>
</div>


<hr class="space"/>
<!-- START: LOGIN/REGISTER -->
<div class="row login-row clear-padding" >

  <div class="container clear-padding">
    <div class="col-md-12 search-section">
      <h4 class="center product-search-title">Informare asupra datelor cu caracter personal prelucrate de administrator-bloc-bucuresti.ro</h4>
      <br/>
      <p class="justify">administrator-bloc-bucuresti.ro colecteaza minimum de date posibil necesar pentru identificarea pacientilor in vederea imbunatatirii calitatii serviciului.
      <p class="justify">administrator-bloc-bucuresti.ro nu a instrainat si nu va instraina catre terti niciodata aceste date. Daca doriti stergerea tuturor datelor colectate de administrator-bloc-bucuresti.ro despre dumneavoastra, trimiteti un mail la contact@romdev.ro, specificand numele site-ului precum si numele dumneavostra si ne vom ocupa cu celeritate de acest lucru.</p>

      <hr/>
      <h4 class="center product-search-title">Informare asupra fisierelor de tip cookie de pe site-ul administrator-bloc-bucuresti.ro</h4>
      <br/>

      <h4>Ce este un fisier de tip cookie?</h4>
      <p class="justify">Un fisier de tip cookie este un fisier de date de obicei salvat intr-un anumit director pe hrd-disk-ul computerului* dumneasvoastra atunci cand accesati un serviciu oferit online in browser-ul dumneavoastra. Fisierul de tip cookie este folosit pentru a identifica computerul in care acest fisier a fost salvat pentru o anumita perioada.
      <p class="justify"><i>* Termenul computer se refera la echipamentul (latop, calculator personal, tableta, telefon mobil de tip smart, etc.) folosit de dumneavoastra pentru a accesa un site web, o aplicatie, etc.</i></p>
      <br/>
      <h4>Pentru ce folosim fisierele de tip cookie?</h4>
      <p class="justify">In functie de preferintele dumneavoastra, folosim fisiere de tip cookie pentru a va facilita usurinta accesului la serviciile pe care le oferim. </p>
      <br/>

      <p class="justify">Fisierele de tip cookie pe care le folosim ne permit sa:
      <ul>
        <li>Primim statistici cu privire la volumele de trafic si la paginile/sectiunile accesate de dumneavoastra pentru a va oferi pe viitor servicii relevante si experiente ergonomice;</li>
        <li>Adaptam prezentarea continutului si selectarea de preferinte (limba, rezolutia ecranului, sistemul de operare, etc.) pentru setarile oferite de computerul dumneavoastra in timp ce navigati websiteul nostru;</li>
        <li>Stocam informatiile pe care le-ati introdus intr-un anumit formular pe websiteul nostru;
      </ul>
      <br/>
      <h4>Ce fisiere de tip cookie foloseste websiteul nostru?</h4>

      <br/>
      <p class="justify"><b>Fisiere de tip cookie: Preferintiale</b></p>
      <p class="justify">Folosim aceste fisiere de tip cookie pentru a permite websiteului nostru sa tine minte date legate de preferintele dumneavostra, ca de exemplu: limba si locatia din care accesati serviciul. Aceste fisiere de tip cookie sunt folosite de Google:</p>
      <table class="cookie-table">
        <tr>
          <th>Numele fisierului de tip cookie</th>
          <th>Scopul fisierului de tip cookie</th>
          <th>Durata fisierului de tip cookie</th>
        </tr>
        <tr>
          <td>NID</td>
          <td>Fisierul de tip cookie contine un ID unic folosit de Google pentru a tine minte preferintele dumneavoastra si alte informarii, ca de exemplu: limba preferata, cate rezultate de cautare doriti sa va fie afisate pe pagina si daca doriti sa nu ca Filtru de Cautare in Siguranta al lui Google sa fie sau nu activat.</td>
          <td>6 luni</td>
        </tr>
      </table>
      <br/>
      <p class="justify"><b>Fisiere de tip cookie: Analitice</b></p>
      <p class="justify">Folosim aceste fisiere de tip cookie pentru a analiza utilizarea si performanta websiteului nostru, pentru a obtine statistici, volume de trafic si modul de utilizare a diferitelor pagini si sectiuni care formeaza intregul website cu scopul de imbunatati relevanta informatiilor prezentate si caliatatea serviciilor ofertite</p>
      <p class="justify">De asemenea folosim aceste fisiere de tip cookie pentru a numara vizitele si a gestiona numarul total de vizitatori. Aceste fisiere de tip cookie sunt folosite de Google Analytics:</p>
      <table class="cookie-table">
        <tr>
          <th>Numele fisierului de tip cookie</th>
          <th>Scopul fisierului de tip cookie</th>
          <th>Durata fisierului de tip cookie</th>
        </tr>
        <tr>
          <td>_utma</td>
          <td>GOOGLE ANALYTICS - cookie care nu expira, tine evidenta primei accesari a site-ului, a numarului de accesari si a ultimei accesari</td>
          <td>Nelimitata</td>
        </tr>
        <tr>
          <td>_utmb</td>
          <td>GOOGLE ANALYTICS - marcheaza momentul in care un utilizator intra pe website</td>
          <td>30 minute</td>
        </tr>
        <tr>
          <td>_utmc</td>
          <td>GOOGLE ANALYTICS - marcheaza momentul in care un utilizator paraseste website-ul</td>
          <td>Pana la inchiderea sesiunii utilizatorului sau a browseului</td>
        </tr>
        <tr>
          <td>_utmt</td>
          <td>GOOGLE ANALYTICS - fisier de tip care indica tipul de request si tipul evenimentului, tranzactiei, etc.</td>
          <td>10 minute</td>
        </tr>
        <tr>
          <td>_utmz</td>
          <td>GOOGLE ANALYTICS - fisier de tip cookie care expira dupa 6 luni si contine informatii despre utilizator - de unde a venit, ce motor de cautare a folosit, pe ce link a dat click, ce keyword a folosit si de unde a accesat site-ul.</td>
          <td>6 luni</td>
        </tr>
      </table>
      <br/>
      <h4>Cum sa controlati/stergeti fisierele de tip cookie?</h4>
      <p class="justify">Puteti decide sa acceptati sau nu fisiere de tip cookie pe dispozitivul dumneavoastra in orice moment. </p>
      <p class="justify">Daca ati acceptat salvarea fisierelor de tip cookie de catre browserul dumneavoastra, acestea vor fi stocate intr-o locatie specifica pe sistemul de fisiere de pe dispozitivul dumneavoastra. </p>
      <p class="justify">Daca nu permiteti salvarea fisierelor de tip cookie de catre browserul dumneavoastra, sau daca decideti sa stergeti fisierele de tip cookie deja stocate, exista posibilitatea de a nu beneficia de anumite functionalitati ale websiteului</p>
      <p class="justify">Mai jos gasiti detalii cu privire la stergerea si controlul fisierelor de tip cookie de pe websiteul nostru</p>
      <p class="justify">In functie de browserul folosit de dumneavoastra, instructiunile pot fi diferite:</p>
      <p ><b>Chrome:</b> <a href="https://support.google.com/chrome/answer/95647" target="_blank">https://support.google.com/chrome/answer/95647</a></p>
      <p ><b>Internet Explorer:</b> <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a></p>
      <p ><b>Firefox:</b> <a href="https://support.mozilla.org/ro/kb/cookie-urile" target="_blank">https://support.mozilla.org/ro/kb/cookie-urile</a></p>
      <p ><b>Opera:</b> <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">https://help.opera.com/en/latest/web-preferences/#cookies</a></p>

      <br/>
      <p class="justify">Cu privire la fisirele de tip cookie de tip analitice, folosite pentru a masura traficul de pe website-ul nostru, va rugam sa faceti click pe link-urile de mai jos pentru a afla mai multe informatii si pentru a afla cum le puteti dezactiva/controla:

      <p class="justify"><a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/analytics/answer/6004245</a></p>
      <p class="justify"><a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></p>



    </div>
  </div>
</div>
<!-- END: LOGIN/REGISTER -->

