<app-header></app-header>
<div class="section-bg-image bg-top bg-no-repeat block-bg"
     style="background-image: url(&quot;assets/images/bckg2.png&quot;);">

  <div class="container content">
    <div class="row vertical-row">
      <div class="col-md-12">
        <div class="title-base text-left">
          <hr/>
          <h2>Date si Formular Contact</h2>
        </div>
      </div>

    </div>


<!--
    <div class="row vertical-row">
      <div class="col-md-12 boxed-inverse shadow-1">
        <div class="row">
          <div class="col-md-8">
            <h4>Telefon: <a href="tel:0723999399">0723.999.399</a>; <a href="tel:0770737123">0770.737.123</a><br>
              Email: <a href="mailto:barbusimona@yahoo.com">barbusimona@yahoo.com</a></h4>
            <div>
              <br/>
              <p>Apelati oricare dintre numerele de telefon mentionate sau prezentati-va la unul din punctele noastre de
                lucru!</p>
              <p>Pentru examinarile care necesita o anumita pregatire veti primi indicatii in momentul programarii.</p>
              <p>Pentru serviciile nedecontate de C.A.S.M.B. si pentru pacientii care nu indeplinesc calitatea de
                asigurat, plata se face conform tarifelor afisate.</p>
              <p><b>Atentie!</b> Aduceti la consult si rezultatele unor eventuale examinari anterioare!</p>
            </div>
            <hr class="space s">
          </div>
          <div class="col-md-4">
            <img src="assets/images/logo.png" alt="Simnovomed" class="img-rounded">
          </div>
        </div>
      </div>
    </div>

    <hr class="space"/>
    <div class="google-map row-17">
      <iframe style="width:100%;height:350px;" class="contact-map"
              src="https://www.google.com/maps/d/u/0/embed?mid=1Gcct_GOj58XezckUTxzw9UdifYe69Zji"
              allowfullscreen=""></iframe>
    </div>
-->
  </div>
</div>

<div class="section-empty">
  <div class="container content">
    <div class="row proporzional-row">
      <div class="col-md-6 col-sm-12">
        <h2 class="text-color">Formular de contact</h2>
        <hr class="space s"/>
        <form action="" class="form-box form-ajax" method="post">
          <div class="row">
            <div class="col-md-12">
              <p>Nume</p>
              <input type="text" name="name" id="name" class="form-control" placeholder="Nume" [(ngModel)]="name"
                     required/>
              <span class="error_msg" id="name_error_msg"></span>

            </div>
            <div class="col-md-6">
              <p>Adresa Email</p>
              <input type="email" name="email" id="email" class="form-control" placeholder="Adresa Email"
                     [(ngModel)]="email" required/>
              <span class="error_msg" id="email_error_msg"></span>
            </div>
            <div class="col-md-6">
              <p>Numar de telefon</p>
              <input type="text" name="phone" id="phone" class="form-control" placeholder="Numar de telefon" [(ngModel)]="phone"
                     required/>
              <span class="error_msg" id="phone_error_msg"></span>
            </div>
          </div>
          <hr class="space xs"/>
          <div class="row">
            <div class="col-md-12">
              <p>Mesajul Dumneavoastra</p>
              <textarea rows="7" name="message" id="exampleTextarea" class="form-control" placeholder="Mesajul Dumneavoastra"
                        [(ngModel)]="message" required></textarea>
              <span class="error_msg" id="message_error_msg"></span>
              <hr class="space s"/>
              <div class="alert alert-success" *ngIf="showSuccessMessage">
                <strong>Succes!</strong> Datele dumneavoastra au fost trimise cu succes.
              </div>
              <div class="alert alert-warning" *ngIf="showErrorMessage">
                <strong>Eroare!</strong> {{errorMessage}}.
              </div>
              <button *ngIf="!sending" type="submit" name="submit" id="submit" class="btn btn-default" (click)="save()">Trimite</button>
              <span  *ngIf="sending"><i>Se trimit datele..</i></span>
            </div>
          </div>
        </form>

      </div>
      <div class="col-md-6 boxed-inverse shadow-1 col-sm-12">
        <div class="row">
          <div class="col-md-12">
            <ul class="fa-ul">
              <li><b class="green">Iulian</b></li>
              <li>
                <i class="fa-li fa fa-phone"></i>
                Telefon : <a href="tel:0720695418">0720.695.418</a><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sau&nbsp; :  <a href="tel:0741101036">0741.101.036</a><br>
              </li>
              <li>
                <i class="fa-li fa fa-envelope"></i>
                Email: <a href="mailto:contact@administrator-bloc-bucuresti.ro">contact@administrator-bloc-bucuresti.ro</a>
              </li>
            </ul>
            <hr class="space"/>
            <ul class="fa-ul">
              <li><b class="green">Parteneri si colaboratori</b></li>
              <li>
                <i class="fa-li fa fa-building"></i>
                <b>Metropolitan Expert Design SRL</b>
              </li>
              <li>
                <i class="fa-li fa fa-building"></i>
                <b>Real Instal SRL</b>
              </li>
              <li>
                <i class="fa-li fa fa-building"></i>
                <b>Proconfort Grup SRL</b>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
