<app-header></app-header>

<div class="section-bg-image" style="background-image: url(&quot;assets/images/bckg3.png&quot;);">
  <div class="container content">
    <div class="row vertical-row">
      <div class="col-md-7 col-sm-6">
        <div class="title-base text-left">
          <hr/>
          <h2>Legislatie - Legea 196/2018</h2>
        </div>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="wpb_wrapper">
      <div class="wpb_text_column wpb_content_element ">
        <div class="wpb_wrapper">
          <h4>Legea 196/2018 privind înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari şi administrarea condominiilor Parlamentul României adoptă prezenta lege. </h4>
          <p>ART. 11<br>
            (1) Asociaţia de proprietari este forma juridică de organizare şi de reprezentare a intereselor comune ale proprietarilor dintr-un condominiu, cu personalitate juridică, fără scop lucrativ, înfiinţată în condiţiile prezentei legi, care are ca scop administrarea, exploatarea, întreţinerea, repararea, reabilitarea şi modernizarea proprietăţii comune, menţinerea în stare bună a imobilului, respectarea drepturilor şi asumarea obligaţiilor de către toţi proprietarii.</p>

        </div>
      </div>

      <div class="wpb_text_column wpb_content_element ">
        <div class="wpb_wrapper">
          <h4>Legea 196/2018 ART. 45</h4>
          <p>Organele asociaţiei de proprietari sunt:<br>
            a) adunarea generală;<br>
            b) comitetul executiv;<br>
            c) preşedintele;<br>
            d) cenzorul sau comisia de cenzori.</p>

        </div>
      </div>

      <div class="wpb_text_column wpb_content_element ">
        <div class="wpb_wrapper">
          <h4>Legea 196/2018 ART. 53</h4>
          <p>Adunarea generală a proprietarilor membri ai asociaţiei de proprietari are următoarele atribuţii:<br>
            a) alege şi revocă din funcţie preşedintele, ceilalţi membri ai comitetului executiv şi cenzorul/comisia de cenzori;<br>
            b) adoptă şi modifică statutul şi regulamentul condominiului;<br>
            c) adoptă, modifică sau revocă hotărâri;<br>
            d) adoptă şi modifică bugetul de venituri şi cheltuieli;<br>
            e) adoptă hotărâri privind executarea lucrărilor de întreţinere, reparaţii, modernizare, consolidare şi reabilitare termică şi eficienţă energetică a condominiului;<br>
            f) în baza acordului scris al tuturor proprietarilor adoptă hotărâri privind contractarea de împrumuturi de la bănci în vederea acoperirii cheltuielilor pentru consolidarea şi modernizarea condominiului, modernizarea instalaţiilor şi dotărilor aferente, reabilitarea termică în scopul creşterii performanţei energetice, precum şi pentru reabilitarea structural-arhitecturală a anvelopei în vederea creşterii calităţii ambiental-arhitecturale a condominiului, potrivit prevederilor legale;<br>
            g) adoptă hotărâri asupra fondului anual de salarii şi indemnizaţii;<br>
            h) adoptă hotărâri asupra cuantumului indemnizaţiilor, precum şi asupra numărului şi funcţiilor personalului încadrat cu contract individual de muncă sau contract de prestări servicii în cadrul asociaţiei de proprietari pentru buna administrare, gestionare şi funcţionare a condominiului, dar şi asupra valorii şi a modalităţilor de contractare, în limita bugetului de venituri şi cheltuieli;<br>
            i) stabileşte modalităţile şi tranşele de plată a contribuţiilor fiecărui proprietar, pentru fiecare dintre categoriile de cheltuieli, conform reglementărilor în vigoare;<br>
            j) stabileşte sistemul de penalizări pentru restanţele afişate pe lista de plată ce privesc cheltuielile asociaţiei de proprietari, conform prevederilor legale în vigoare;<br>
            k) mandatează preşedintele şi comitetul executiv pentru angajarea şi eliberarea din funcţie a administratorului, cenzorului/comisiei de cenzori sau a altor angajaţi ori prestatori, în scopul administrării şi bunei funcţionări a condominiului;<br>
            l) poate stabili plafoanele minime de la care achiziţionarea bunurilor sau contractarea serviciilor se face pe baza ofertelor operatorilor economici care satisfac cerinţele asociaţiei de proprietari, conform legislaţiei în vigoare;<br>
            m) exercită alte atribuţii care i-au fost conferite prin statut, prin acordul de asociere sau prin votul proprietarilor membri ai asociaţiei.</p>

        </div>
      </div>

      <div class="wpb_text_column wpb_content_element ">
        <div class="wpb_wrapper">
          <h4>Legea 196/2018 ART. 66</h4>
          <p>(1) Administratorul are, în principal, următoarele atribuţii:<br>
            a) administrează imobilul, propune şi supraveghează lucrări având ca scop conservarea şi întreţinerea acestuia;<br>
            b) supraveghează atât lucrările care privesc administrarea, cât şi personalul angajat;<br>
            c) în caz de urgenţă, cu acordul comitetului executiv, iniţiază executarea tuturor lucrărilor necesare protejării imobilului;<br>
            d) prestează serviciile prevăzute în contractul de administrare cu responsabilitate şi în mod profesional;<br>
            e) execută dispoziţiile prevăzute în hotărârile adunării generale a asociaţiei de proprietari, în conformitate cu prezenta lege, regulamentul condominiului şi conform contractului de administrare;<br>
            f) organizează şi conduce contabilitatea în partidă simplă şi activitatea de casierie;<br>
            g) gestionează, separat pentru fiecare asociaţie, bunurile materiale şi fondurile băneşti ale asociaţiei, conform hotărârilor adunării generale şi deciziilor comitetului executiv;<br>
            h) propune, cel târziu până la sfârşitul perioadei de recalculare a cheltuielilor, bugete anuale şi prognoze pe termen mediu în care trebuie să includă sumele achitate, lucrările de întreţinere şi lucrările de reabilitare şi îmbunătăţire, sumele necesare din fondurile asociaţiei, alte cheltuieli previzibile şi o estimare generală a cheltuielilor asociaţiei de proprietari;<br>
            i) efectuează formalităţile necesare în angajarea contractelor cu furnizorii de servicii pentru exploatarea şi întreţinerea condominiului, derularea şi urmărirea realizării acestor contracte;<br>
            j) asigură gestionarea condominiului conform hotărârilor adunării generale şi deciziilor comitetului executiv, cu respectarea prevederilor legale în vigoare;<br>
            k) are obligaţia prezentării tuturor datelor, documentelor şi informaţilor privind conţinutul şi respectarea contractului de administrare, precum şi a acordurilor de plată în rate a cheltuielilor anuale, a cuantumului prestaţiilor convenite, la solicitarea oricărui proprietar;<br>
            l) gestionează, conform hotărârilor adunării generale sau comitetului executiv, fondul de rulment şi fondul de reparaţii constituite la dispoziţia asociaţiei de proprietari, precum şi alte fonduri constituite de către aceasta;<br>
            m) calculează, întocmeşte, supune verificării cenzorilor/comisiei de cenzori, supune aprobării comitetului executiv şi afişează la avizier lista lunară a cheltuielilor de întreţinere, întocmită conform reglementărilor în vigoare, în termen de maximum 5 zile de la primirea ultimei facturi de la furnizorii de servicii;<br>
            n) întocmeşte lunar şi depune semestrial la compartimentul specializat în sprijinirea şi îndrumarea asociaţiilor de proprietari pe a cărei rază teritorială se află condominiul situaţia soldurilor elementelor de activ şi pasiv;<br>
            o) afişează lunar, la avizier, lista de venituri şi cheltuieli ale asociaţiei, care cuprinde inclusiv veniturile obţinute din exploatarea proprietăţii comune;<br>
            p) asigură, prin controale periodice, respectarea normelor generale de apărare împotriva incendiilor la utilizarea părţilor comune, funcţionalitatea permanentă, la gabaritele proiectate, a căilor de evacuare în caz de incendiu şi a celor de acces, intervenţie şi salvare şi comunică de urgenţă preşedintelui asociaţiei de proprietari/comitetului executiv neregulile identificate;<br>
            q) îndeplineşte orice alte atribuţii stabilite în cadrul adunării generale a asociaţiei de proprietari în conformitate cu legea.</p>

        </div>
      </div>

      <hr class="space"/>

      <a *ngIf="!showfull" (click)="showfullText()" class="circle-button btn btn-whatsapp btn-lg shadow-1 margin-bottom-50">
        CONSULTATI INTEGRAL TEXTUL LEGII 196/2018</a>

      <div class="container" *ngIf="showfull">
        <div class="wpb_wrapper">
          <div class="wpb_text_column wpb_content_element  legea196">
            <div class="wpb_wrapper">
              <p>L E G E A 196/2018</p>
              <p>privind înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari şi administrarea condominiilor</p>
              <p>Parlamentul României adoptă prezenta lege.</p>
              <p>&nbsp;</p>
              <p>Capitolul I</p>
              <p>Dispoziţii generale, definiţii</p>
              <p>Art.1. – (1) Prezenta lege reglementează aspectele juridice,</p>
              <p>economice şi tehnice cu privire la:</p>
              <ol>
                <li>a) înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari din cadrul condominiilor formate din cel puţin 3 unităţi de proprietate imobiliară;</li>
                <li>b) administrarea, întreţinerea şi folosirea imobilelor sau grupurilor de imobile din condominii care au în structura lor locuinţe sau spaţii cu altă destinaţie decât aceea de locuinţă, aflate în proprietatea a cel puţin 3 persoane fizice sau juridice, fiecare proprietar având o proprietate individuală şi o cotă-parte indiviză din proprietatea comună.</li>
              </ol>
              <p>&nbsp;</p>
              <p>(2) Prezenta lege se aplică proprietarilor, chiriaşilor, asociaţiilor de proprietari şi asociaţiilor de chiriaşi din condominii.</p>
              <p>&nbsp;</p>
              <p>Secţiunea 1</p>
              <p>Definiţii</p>
              <p>Art.2. – În sensul prezentei legi, termenii şi expresiile de mai jos au</p>
              <p>următoarele semnificaţii:</p>
              <ol>
                <li>a) acord de asociere – actul care consfinţeşte decizia a cel puţin jumătate plus unu din numărul proprietarilor dintr-un condominiu de a se asocia în condiţiile prezentei legi;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>b) ansamblu rezidenţial – imobilul format din teren pe care sunt amplasate izolat, înşiruit sau cuplat locuinţe sau locuinţe şi construcţii cu altă destinaţie, în care există proprietăţi comune şi proprietăţi individuale;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>c) asociaţie de chiriaşi – formă de asociere autonomă şi fără scop lucrativ a chiriaşilor dintr-un condominiu în scopul reprezentării intereselor lor în raporturile cu proprietarii, precum şi cu alte persoane juridice sau persoane fizice;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>d) asociaţie de proprietari – formă de asociere autonomă şi fără scop lucrativ a proprietarilor dintr-un condominiu, având ca scop administrarea, exploatarea, întreţinerea, repararea, reabilitarea şi modernizarea proprietăţii comune, menţinerea în stare bună a imobilului, respectarea drepturilor şi asumarea obligaţiilor de către toţi proprietarii;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>e) atestat – actul care dovedeşte calitatea de administrator de condominii, prin care persoanele fizice care deţin certificate de</li>
              </ol>
              <p>calificare, conform lit.f), sunt atestate de către primar, la propunerea compartimentelor specializate în sprijinirea, îndrumarea şi controlul asociaţiilor de proprietari din cadrul autorităţii administraţiei publice locale, în baza unei hotărâri a consiliului local;</p>
              <p>&nbsp;</p>
              <ol>
                <li>f) certificat de calificare profesională – actul care dovedeşte calificarea profesională a persoanelor fizice pentru a îndeplini ocupaţia de administrator de condominii, în conformitate cu legislaţia privind învăţământul superior sau privind formarea profesională a adulţilor;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>g) cheltuieli ale asociaţiei de proprietari – totalitatea cheltuielilor sau obligaţiilor financiare ale asociaţiei de proprietari care sunt legate de exploatarea, repararea, întreţinerea, modernizarea, reabilitarea proprietăţii comune, precum şi cheltuielile cu serviciile de care beneficiază proprietarii şi care nu sunt facturate acestora în mod individual;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>h) condominiu – imobil format din teren cu una sau mai multe construcţii, în care există cel puţin 3 proprietăţi individuale reprezentate de locuinţe şi locuinţe sau spaţii cu altă destinaţie, după caz şi cote-părţi indivize de proprietate comună;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>i) contract de administrare – acordul scris încheiat între o asociaţie de proprietari şi un administrator de condominii care poate fi persoană fizică, persoană fizică autorizată, sau o persoană juridică cu obiect de activitate administrarea condominiilor în vederea prestării unor activităţi şi îndeplinirii unor obiective stabilite de asociaţia de proprietari, conform legii. Contractul de administrare este un contract cu titlu oneros şi se încheie în formă scrisă;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>j) convenţie individuală de facturare – act juridic încheiat între un furnizor de servicii de utilităţi publice, asociaţia de proprietari şi proprietar individual din condominiu, anexă la contractul de furnizare a serviciilor de utilităţi publice încheiat între un furnizor de servicii de utilităţi publice şi asociaţia de proprietari, prin care se stabilesc condiţiile de furnizare, utilizare, facturare şi plată a serviciilor de utilităţi publice furnizate la nivel de utilizator individual din cadrul unui condominiu;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>k) cota de contribuţie la cheltuielile asociaţiei de proprietari – suma corespunzătoare din cheltuielile asociaţiei, pe care fiecare proprietar din condominiu este obligat să o plătească lunar, conform prevederilor prezentei legi;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>l) cotă-parte indiviză – cota-parte de proprietate comună forţată, exprimată procentual, care îi revine fiecărui proprietar individual şi este calculată ca raportul între suprafaţa utilă a proprietăţii individuale şi suma suprafeţelor utile ale tuturor proprietăţilor individuale din condominiu. Suma cotelor-părţi indivize trebuie să fie egală cu 100%, în caz contrar, acestea trebuie recalculate prin grija preşedintelui asociaţiei de proprietari, conform suprafeţelor utile;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>m) locatar – persoana fizică sau juridică care, în baza unui contract de locaţiune, dobândeşte de la cealaltă parte, numită locator, dreptul de folosinţă al unei locuinţe sau spaţiu cu altă destinaţie pentru o perioadă determinată, în schimbul unui preţ, denumit chirie;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>n) locator – persoană fizică sau juridică care, în baza unui contract de locaţiune, se obligă să asigure celeilalte părţi, numite locatar, folosinţa unei locuinţe sau spaţiu cu altă destinaţie pentru o perioadă determinată, în schimbul unui preţ, denumit chirie;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>o) membru al asociaţiei de proprietari – proprietarul semnatar al acordului de asociere sau a unei cereri depuse la asociaţie;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>p) părţi comune – părţile din clădire şi/sau din terenul aferent acesteia, care nu sunt proprietăţi individuale şi sunt destinate folosinţei tuturor proprietarilor sau unora dintre aceştia, precum şi alte bunuri care, potrivit legii sau voinţei părţilor, sunt în folosinţă comună. Părţile comune sunt bunuri accesorii în raport cu proprietăţile individuale, care constituie bunurile principale. Toate părţile comune formează obiectul proprietăţii comune. Părţile comune nu pot fi folosite decât în comun şi sunt obiectul unui drept de coproprietate forţată;</li>
                <li>q) părţi comune aferente tronsoanelor sau scărilor care nu pot fi delimitate – cel puţin o instalaţie pe verticală care alimentează în comun tronsoane ori scări sau părţi de construcţie comune tronsoanelor ori scărilor, care nu pot fi definite separat din punct de vedere fizic ca poziţie şi suprafaţă;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>r) proprietar al ansamblului rezidenţial/dezvoltator al ansamblului rezidenţial – persoana juridică care efectuează toate operaţiunile imobiliare în vederea construirii, finalizării şi predării către beneficiari a unor locuinţe, precum şi coordonarea surselor de finanţare necesare realizării acestor operaţiuni;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>s) proprietar din condominiu/proprietar – persoana fizică sau juridică, titular al unui drept de proprietate asupra a cel puţin o unitate de proprietate imobiliară dintr-un condominiu;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>t) proprietate individuală – locuinţa sau spaţiul cu altă destinaţie decât aceea de locuinţă, parte dintr-un condominiu, destinată locuirii sau altor activităţi, deţinută în proprietate exclusivă;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>u) regulament al condominiului – document de ordine interioară al asociaţiei de proprietari care conţine totalitatea instrucţiunilor, normelor, regulilor care stabilesc şi asigură ordinea şi buna funcţionare a unui condominiu;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>v) statut – act cuprinzând ansamblul de dispoziţii, adoptat de cel puţin jumătate plus unu din numărul proprietarilor dintr-un condominiu, prin care se reglementează scopul, structura şi modul de organizare şi funcţionare a asociaţiei de proprietari, conform prevederilor prezentei legi;</li>
              </ol>
              <p>&nbsp;</p>
              <ol>
                <li>w) unitate de proprietate imobiliară – proprietatea individuală, definită conform lit.t), care constituie bunul principal, împreună cu cota parte indiviză din proprietatea comună.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Secţiunea a 2-a</p>
              <p>Dispoziţii generale</p>
              <p>&nbsp;</p>
              <p>Art.3. – (1) În scopul exercitării drepturilor şi obligaţiilor ce le revin asupra proprietăţii comune, în cazul condominiilor, se constituie asociaţii de proprietari, care se organizează şi funcţionează conform prevederilor prezentei legi.</p>
              <p>(2) Pentru promovarea valorilor civice ale democraţiei şi statului de drept şi pentru reprezentarea la nivel local şi naţional, asociaţiile de proprietari se pot asocia în federaţii, uniuni, ligi ale asociaţiilor de proprietari din municipii, oraşe sau judeţe, respectiv la nivel naţional, în condiţiile prevăzute de lege.</p>
              <p>(3) În sensul art.2 lit.h), constituie condominiu:</p>
              <ol>
                <li>a) clădiri cu mai multe locuinţe sau spaţii cu altă destinaţie în care există proprietăţi individuale şi proprietăţi comune;</li>
                <li>b) clădiri multietajate; un tronson cu una sau mai multe scări din cadrul clădirii multietajate în cazul în care se poate delimita proprietatea comună;</li>
                <li>c) un ansamblu rezidenţial format din locuinţe şi/sau construcţii cu altă destinaţie, amplasate izolat, înşiruit sau cuplat, în care există proprietăţi comune şi proprietăţi individuale.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.4. – (1) În condominii, unităţile de proprietate imobiliară se supun prevederilor prezentei legi ca entităţi individuale.</p>
              <p>(2) Niciun act de dispoziţie nu poate să aibă ca obiect exclusiv cota-parte indiviză din coproprietatea forţată asupra părţilor comune ale condominiului, decât odată cu înstrăinarea locuinţei/spaţiului cu altă destinaţie care constituie bunul principal.</p>
              <p>(3) Părţile comune din condominii şi drepturile aferente acestora pot face obiectul unei acţiuni de partaj numai atunci când aceste părţi încetează a mai fi destinate folosinţei comune, în condiţiile legii.</p>
              <p>(4) În aplicarea prevederilor prezentei legi, dovada dreptului de proprietate şi a celorlalte drepturi reale asupra unei unităţi de proprietate imobiliară se face potrivit dispoziţiilor Legii nr.287/2009 privind Codul civil, republicată, cu modificările ulterioare.</p>
              <p>&nbsp;</p>
              <p>Art.5. – În cazul modificării suprafeţei utile a unei proprietăţi individuale, toate cotele-părţi indivize din condominiu se modifică</p>
              <p>proporţional.</p>
              <p>&nbsp;</p>
              <p>Art.6. – (1) În vederea înscrierii condominiului se aplică prevederile art. 26 din Legea cadastrului şi a publicităţii imobiliare nr.7/1996, republicată, cu modificările şi completările ulterioare.</p>
              <p>(2) Înscrierea condominiului se face de către biroul de cadastru şi publicitate imobiliară în circumscripţia căruia se situează acesta, la solicitarea oricărui proprietar din condominiu sau a asociaţiei de proprietari.</p>
              <p>(3) Condominiile se identifică prin adresă poştală şi, acolo unde există, prin număr cadastral şi număr de carte funciară.</p>
              <p>&nbsp;</p>
              <p>Art.7. – În cazul unităţilor de proprietate imobiliară, cu mai mult de un proprietar, raporturile de coproprietate sunt reglementate potrivit dreptului comun.</p>
              <p>&nbsp;</p>
              <p>Art.8. – Unităţile deţinătoare a cărţii tehnice a clădirilor sunt obligate să transmită asociaţiilor de proprietari, cu titlu gratuit, cărţile tehnice ale construcţiilor, la solicitarea acestora. Unităţile în arhiva cărora se găsesc proiectele clădirilor sunt obligate să elibereze asociaţiilor de proprietari copii ale proiectelor de clădiri elaborate.</p>
              <p>Costul executării copiilor este suportat de asociaţia de proprietari solicitantă. În lipsa cărţii tehnice a construcţiei şi/sau a proiectului, asociaţia de proprietari are obligaţia reconstituirii acestora, în conformitate cu prevederile legale în vigoare.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 3-a</p>
              <p>Raportul dintre autorităţile administraţiei publice şi asociaţiile de proprietari</p>
              <p>&nbsp;</p>
              <p>Art.9. – (1) Autorităţile administraţiei publice centrale ori locale sau orice alte instituţii publice care deţin în proprietate locuinţe sau spaţii cu altă destinaţie decât aceea de locuinţă în condominii, au aceleaşi drepturi şi obligaţii ca oricare alt proprietar din condominiu şi au obligaţia să mandateze persoane fizice sau juridice pentru a fi reprezentate în cadrul asociaţiei de proprietari.</p>
              <p>(2) În cazul în care în condominiu sunt locuinţe sau locuinţe şi spaţii cu altă destinaţie decât cea de locuinţă, care aparţin domeniului public sau privat al statului, persoana fizică/juridică care exercită unul dintre celelalte drepturi reale asupra locuinţei sau spaţiului cu altă destinaţie decât cea de locuinţă, decât dreptul de proprietate, poate, cu acordul proprietarului, reprezenta statul în cadrul asociaţiei de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.10. – (1) Autorităţile administraţiei publice locale organizează în cadrul aparatului de specialitate al primarului un compartiment specializat în sprijinirea, îndrumarea şi controlul asociaţiilor de proprietari.</p>
              <p>(2) Autorităţile administraţiei publice locale, prin compartimentele prevăzute la alin.(1) asigură, la cerere, informarea asociaţiilor şi proprietarilor din condominii cu privire la cadrul normativ privind organizarea şi funcţionarea asociaţiilor de proprietari.</p>
              <p>(3) Compartimentele prevăzute la alin.(1) informează asociaţiile de proprietari cu privire la interdicţiile prevăzute în regulamentul local de urbanism sau în regulamentele de intervenţie aferente zonelor de intervenţie prioritară, aplicabile condominiilor, stabilite în cadrul programelor multianuale destinate creşterii calităţii arhitectural ambientale a clădirilor, de reabilitare termică şi de reducere a riscului seismic al construcţiilor existente, precum şi cu privire la obligativitatea montării contoarelor pentru individualizarea consumurilor la nivel de proprietate individuală, conform legislaţiei în vigoare.</p>
              <p>(4) Compartimentele prevăzute la alin.(1) exercită controlul asupra activităţii financiar-contabile din cadrul asociaţiilor de proprietari, din oficiu ori la solicitarea unuia sau mai multor membri ai asociaţiei de proprietari.</p>
              <p>(5) La propunerea compartimentelor specializate în sprijinirea, îndrumarea şi controlul asociaţiilor de proprietari din cadrul autorităţii administraţiei publice locale, primarul, în baza unei hotărâri a consiliului local, atestă persoanele fizice în vederea dobândirii calităţii de administrator de condominii.</p>
              <p>(6) Atestatul prevăzut la alin.(5) se emite în baza următoarelor documente:</p>
              <ol>
                <li>a) certificatul de calificare profesională pentru ocupaţia de administrator de condominii, conform prevederilor art.2 lit.f);</li>
                <li>b) cazierul judiciar care să ateste că nu a suferit nicio condamnare, prin hotărâre judecătorească rămasă definitivă, pentru o infracţiune de natură economico-financiară;</li>
                <li>c) cazierul fiscal.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Capitolul II</p>
              <p>Înfiinţarea şi înregistrarea asociaţiei de proprietari</p>
              <p>Secţiunea 1</p>
              <p>Procedura de înfiinţare şi înregistrare a asociaţiilor de proprietari</p>
              <p>&nbsp;</p>
              <p>Art.11. – (1) Asociaţia de proprietari este forma juridică de organizare şi de reprezentare a intereselor comune ale proprietarilordintr-un condominiu, cu personalitate juridică, fără scop lucrativ, înfiinţată în condiţiile prezentei legi, care are ca scop administrarea, exploatarea, întreţinerea, repararea, reabilitarea şi modernizarea proprietăţii comune, menţinerea în stare bună a imobilului, respectarea drepturilor şi asumarea obligaţiilor de către toţi proprietarii.</p>
              <p>(2) Consecinţele neluării măsurilor de organizare şi funcţionare cu privire la administrarea şi gestiunea proprietăţii comune sunt în răspunderea juridică a tuturor proprietarilor sau a reprezentanţilor acestora, după caz.</p>
              <p>&nbsp;</p>
              <p>Art.12. – Pentru realizarea scopului menţionat la art.11 alin.(1), asociaţia de proprietari înfiinţată în condiţiile prezentei legi dobândeşte drepturi şi îşi asumă obligaţii faţă de terţi, în ceea ce priveşte administrarea condominiului.</p>
              <p>&nbsp;</p>
              <p>Art.13. – (1) Asociaţia de proprietari se înfiinţează prin acordul scris a cel puţin jumătate plus unu din numărul proprietarilor dintr-un condominiu.</p>
              <p>(2) În condominiile cu mai multe tronsoane sau scări se pot constitui asociaţii de proprietari pe fiecare tronson ori scară în parte numai în condiţiile în care proprietatea comună aferentă tronsoanelor sau scărilor poate fi delimitată şi în condiţiile în care există branşamente separate pe fiecare scară sau tronson.</p>
              <p>(3) Divizarea unei asociaţii de proprietari existente în două sau mai multe asociaţii, pe scări sau tronsoane se face, numai în condiţiile în care proprietatea comună aferentă tronsoanelor sau scărilor poate fi delimitată şi în condiţiile în care există branşamente separate pe fiecare scară sau tronson, la propunerea majorităţii proprietarilor din scara sau tronsonul respectiv, cu acordul majorităţii proprietarilor din asociaţia ce urmează a se diviza.</p>
              <p>(4) Dacă este cazul, asociaţiile de proprietari înfiinţate pe scări sau tronsoane de clădire îşi reglementează între ele raporturile juridice cu privire la aspectele tehnice comune acestora, printr-o anexă la acordul de asociere.</p>
              <p>&nbsp;</p>
              <p>Art.14. – În cazul condominiilor din ansamblurile rezidenţiale, proprietarii/dezvoltatorii ansamblurilor rezidenţiale, în calitate de</p>
              <p>vânzători, trebuie să informeze la momentul înstrăinării pe cumpărători cu privire la necesitatea constituirii asociaţiilor de proprietari, în condiţiile prezentei legi.</p>
              <p>&nbsp;</p>
              <p>Art.15. – (1) Anterior constituirii asociaţiei de proprietari, proprietarii unităţilor de proprietate imobiliară din condominii se întrunesc pentru a hotărî cu privire la înfiinţarea asociaţiei de proprietari sau, după caz, la reorganizarea asociaţiei de locatari în asociaţie de proprietari. Totodată, se hotărăşte şi cu privire la persoana fizică sau juridică însărcinată cu întocmirea</p>
              <p>acordului de asociere şi a statutului, cu privire la termenul de finalizare a acestora, data de convocare a adunării de constituire şi la modalitatea de decontare a costurilor generate de această acţiune. Hotărârea proprietarilor se consemnează într-un proces-verbal care se semnează de toţi cei prezenţi.</p>
              <p>(2) Cu cel puţin 10 zile înaintea datei fixate pentru adunarea proprietarilor de constituire a asociaţiei de proprietari, proprietarii se convoacă prin afişare la avizier şi prin tabel convocator. Tabelul convocator se semnează de cel puţin cincizeci la sută plus unul din numărul proprietarilor din condominiu.</p>
              <p>(3) În cadrul adunării proprietarilor pentru constituirea asociaţiei de proprietari se prezintă şi se adoptă statutul asociaţiei de proprietari, care cuprinde cel puţin următoarele informaţii:</p>
              <ol>
                <li>a) denumirea, sediul, durata de funcţionare ale asociaţiei de proprietari;</li>
                <li>b) scopul asociaţiei de proprietari, conform prevederilor legale;</li>
                <li>c) structura veniturilor şi a cheltuielilor, cu respectarea prevederilor legale în vigoare;</li>
                <li>d) membrii asociaţiei de proprietari, precum şi drepturile şi obligaţiile acestora;</li>
                <li>e) structura organizatorică şi modul de funcţionare, cu respectarea prevederilor prezentei legi;</li>
                <li>f) repartizarea cheltuielilor asociaţiei de proprietari, conform legii;</li>
                <li>g) condiţiile în care se dizolvă asociaţia de proprietari;</li>
                <li>h) modalitatea de soluţionare a litigiilor, relaţiile contractuale, penalizările, cazurile de urgenţă în care se permite, în condiţiile legii, accesul în proprietatea individuală a preşedintelui sau a unui membru al comitetului executiv;</li>
                <li>i) asigurarea, după caz, a unui spaţiu de administrare special amenajat, unde se va putea desfăşura serviciul de administrare şi în care pot fi păstrate în siguranţă documentele asociaţiei.</li>
              </ol>
              <p>(4) Statutul asociaţiei de proprietari stabileşte modul de organizare, regulile generale de funcţionare şi atribuţiile asociaţiei de proprietari, a adunării generale, ale comitetului executiv, cenzorului/comisiei de cenzori şi administratorului de condominii, denumit în continuare administrator, cu respectarea prevederilor legale în vigoare.</p>
              <p>&nbsp;</p>
              <p>Art.16. – (1) Hotărârea proprietarilor privind constituirea asociaţiei de proprietari se consemnează prin proces-verbal, înregistrat în registrul unic al asociaţiei de proprietari care cuprinde procesele-verbale ale adunării generale, comitetului executiv şi cenzorului/comisiei de cenzori, semnate de toţi proprietarii care şi-au dat consimţământul.</p>
              <p>Procesul-verbal semnat de către proprietarii care au hotărât, în condiţiile art. 13 alin. (1), constituirea asociaţiei de proprietari constituie anexă la acordul de asociere.</p>
              <p>(2) Proprietarii îşi dau consimţământul, de regulă, în adunarea proprietarilor în vederea constituirii asociaţiei de proprietari. În cazul proprietarilor absenţi, consimţământul poate fi exprimat prin semnarea procesului-verbal prevăzut la alin.(1), în termen de 30 de zile de la data la care a avut loc adunarea de constituire, în condiţiile art.15.</p>
              <p>&nbsp;</p>
              <p>Art.17. – (1) Cererea pentru dobândirea personalităţii juridice a asociaţiei de proprietari împreună cu statutul, acordul de asociere şi procesul-verbal al adunării de constituire, se depun şi se înregistrează la judecătoria în a cărei rază teritorială se află condominiul.</p>
              <p>(2) Statutul şi acordul de asociere se întocmesc în baza prezentei legi.</p>
              <p>(3) Acordul de asociere trebuie să conţină:</p>
              <ol>
                <li>a) adresa şi individualizarea proprietăţii individuale, potrivit actului de proprietate;</li>
                <li>b) numele şi prenumele tuturor proprietarilor asociaţi;</li>
                <li>c) descrierea proprietăţii, cuprinzând: descrierea condominiului, structura condominiului, numărul de etaje, numărul de proprietăţi individuale structurate pe număr de camere, numărul de clădiri, numărul spaţiilor cu altă destinaţie decât aceea de locuinţă, suprafaţa terenului aferent condominiului, numărul cadastral sau topografic al imobilului şi numărul de carte funciară, pentru imobilele înscrise în evidenţele de cadastru şi carte funciară;</li>
                <li>d) enumerarea şi descrierea părţilor aflate în proprietate comună, precum şi regulile de folosinţă a părţilor comune potrivit prezentei legi;</li>
                <li>e) cota-parte indiviză ce revine fiecărui proprietar din proprietatea comună;</li>
                <li>f) declaraţiile pe proprie răspundere a proprietarilor privind informaţiile referitoare la suprafeţele utile şi construite în situaţia în care acestea diferă de cele înscrise în actele de proprietate asupra locuinţelor sau a spaţiilor cu altă destinaţie.</li>
              </ol>
              <p>(4) Asociaţia de proprietari dobândeşte personalitate juridică în baza încheierii judecătorului desemnat de preşedintele judecătoriei în a cărei circumscripţie teritorială se află condominiul.</p>
              <p>(5) Încheierea se dă fără citarea părţilor şi este executorie.</p>
              <p>(6) Încheierea este supusă numai apelului în termen de 5 zile de la comunicare, preşedintele asociaţiei fiind obligat să informeze în scris toţi proprietarii în aceeaşi zi, prin afişare la avizier. Apelul se judecă cu citarea părţilor.</p>
              <p>(7) Înscrierea ulterioară în asociaţie a proprietarilor care nu au fost prezenţi la adunarea proprietarilor în vederea constituirii asociaţiei de proprietari sau care au dobândit calitatea de proprietari ulterior adunării de constituire, după caz, se face la cererea scrisă a acestora, prin semnarea unui act adiţional la acordul de asociere. Prin grija preşedintelui asociaţiei de proprietari, actele adiţionale se ataşează dosarului de infiinţare a asociaţiei, se păstrează la sediul asociaţiei şi seprezintă compartimentelor de specialitate din cadrul primăriei, la solicitare a acestora.</p>
              <p>(8) Acordul de asociere şi statutul asociaţiei de proprietari, actualizate, se depun de către preşedintele asociaţiei de proprietari, ori de câte ori sunt modificări, la judecătoria în a cărei rază teritorială se află condominiul.</p>
              <p>(9) Odată cu pierderea calităţii de proprietar în condominiu, încetează statutul de membru al respectivei asociaţii de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.18. – Dacă numărul proprietarilor dintr-un condominiu scade sub 3 ca urmare a unor acte de înstrăinare, proprietarul sau proprietarii cer judecătoriei, în a cărei circumscripţie teritorială se află imobilul, încetarea personalităţii juridice.</p>
              <p>&nbsp;</p>
              <p>Art.19. – (1) După dobândirea personalităţii juridice, asociaţia de proprietari se înregistrează la organul fiscal local în a cărui rază teritorială se află condominiul.</p>
              <p>(2) După înfiinţarea asociaţiei de proprietari, aceasta se identifică prin denumire, adresa condominiului, încheierea preşedintelui judecătoriei în a cărei circumscripţie teritorială se află condominiul sau orice alt document emis la înfiinţare în baza legii şi prin codul de identificare fiscală al asociaţiei. Actele emise de către asociaţiile de proprietari cuprind obligatoriu datele de identificare.</p>
              <p>(3) Toate asociaţiile de proprietari sunt obligate să afişeze, la loc vizibil, pe peretele exterior al imobilului, în imediata apropiere a căii de acces în condominiu, sediul şi denumirea asociaţiei respective.</p>
              <p>&nbsp;</p>
              <p>Legea 196/2018 – Art.20. – (1) Fiecare asociaţie de proprietari este obligată să deţină un singur cont bancar.</p>
              <p>(2) În vederea plăţii cotelor de contribuţie la cheltuielile asociaţiei de proprietari, datele de identificare ale contului bancar prevăzut la alin.(1) se comunică tuturor proprietarilor prin afişare la avizier, odată cu lista de plată a cheltuielilor asociaţiei de proprietari.</p>
              <p>(3) Prin datele de identificare ale conutui bancar menţionate la alin. (2) se înţelege numele titularului contului, banca la care este deschis contul, sucursala şi codul IBAN.</p>
              <p>&nbsp;</p>
              <p>Legea 196/2018 – Art.21. – (1) Pentru modificarea sau completarea statutului ori a acordului de asociere, în cadrul adunării generale a asociaţiei de proprietari este necesar acordul a cel puţin jumătate plus unu din numărul proprietarilor din condominiu.</p>
              <p>(2) Modificările şi/sau completările trebuie să fie motivate şi aprobate prin hotărâre a adunării generale a asociaţiei de proprietari într-o şedinţă convocată şi desfăşurată cu respectarea prevederilor statutului asociaţiei de proprietari şi ale prezentei legi.</p>
              <p>(3) Orice modificare sau completare a statutului sau a acordului de asociere se înregistrează la judecătoria care a emis încheierea judecătorească de înfiinţare, fără alte formalităţi.</p>
              <p>&nbsp;</p>
              <p>(4) Modificările şi/sau completările aduse statutului sau acordului de asociere fără respectarea prevederilor alin.(3) nu sunt opozabile faţă de proprietarii care nu sunt membri ai asociaţiei de proprietari sau faţă de terţi.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 2-a</p>
              <p>Regulamentul condominiului</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Art.22. – (1) Proprietarii din condominii trebuie să adopte un document scris cu privire la regulile şi condiţiile de folosinţă a părţilor comune ale condominiului şi normele de conduită şi bună vecinătate dintre proprietari, numit regulament al condominiului.</p>
              <p>(2) Regulamentul condominiului se iniţiază de către preşedintele asociaţiei sau de către comitetul executiv al asociaţiei de proprietari, se dezbate şi se adoptă în adunarea generală a asociaţiei de proprietari cu acordul majorităţii proprietarilor din condominiu, şi este adus la cunoştinţă, prin afişare la avizier sau prin înmânarea unei copii conforme a acestuia, tuturor proprietarilor, în termen de 7 zile de la adoptare.</p>
              <p>(3) Regulile de folosinţă a proprietăţii comune nu vor fi afectate de schimbarea titularului dreptului de proprietate.</p>
              <p>(4) Regulamentul condominiului nu poate impune nicio restricţie exercitării drepturilor proprietarilor în afara celor care sunt justificate prin destinaţia imobilului, aşa cum este prevăzută în proiectul tehnic şi cartea tehnică a construcţiei.</p>
              <p>(5) Toţi proprietarii, precum şi chiriaşii din condominiu sau persoanele care sunt găzduite pe perioadă determinată în condominiu sunt obligaţi să respecte regulamentul de condominiu. Responsabilitatea informării chiriaşilor şi a persoanelor care tranzitează condominiul referitor la prevederile regulamentului condominiului aparţine proprietarului.</p>
              <p>(6) În cazul nerespectării regulamentului condominiului de către unul dintre proprietari sau chiriaşi, ceilalţi proprietari pot sesiza preşedintele şi/sau comitetul executiv al asociaţiei de proprietari, cu privire la aceste nereguli.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 3-a</p>
              <p>Asociaţiile de chiriaşi</p>
              <p>&nbsp;</p>
              <p>Art.23. – Asociaţiile de chiriaşi se constituie şi se organizează în conformitate cu prevederile Ordonanţei Guvernului nr.26/2000 cu privire la asociaţii şi fundaţii, aprobată cu modificări şi completări prin Legea nr.246/2005, cu modificările şi completările ulterioare.</p>
              <p>&nbsp;</p>
              <p>Art.24. – (1) Proprietarii condominiilor, persoane fizice sau juridice, destinate în întregime închirierii, în care sunt constituite asociaţii de chiriaşi, pot să încredinţeze asociaţiilor de chiriaşi administrarea părţilor comune din condominiu.</p>
              <p>(2) Administrarea părţilor comune conform prevederilor alin.(1) se face numai în baza unui contract de dare în administrare semnat între proprietar şi asociaţia de chiriaşi, numai cu acordul majorităţii chiriaşilor membri ai asociaţiei de chiriaşi.</p>
              <p>&nbsp;</p>
              <p>Art.25. – (1) Asociaţiile de chiriaşi nu pot efectua modificări constructive în condominiu, nu pot schimba destinaţia proprietăţiicomune din condominiu, nu pot înstrăina şi nu pot închiria bunurile sau spaţiile comune, fără acordul scris al proprietarului, în condiţiile legii.</p>
              <p>(2) Asociaţiile de chiriaşi desfăşoară numai activităţile prevăzute în contractul de dare în administrare.</p>
              <p>&nbsp;</p>
              <p>Art.26. – Darea în administrare a părţilor comune către asociaţiile de chiriaşi nu aduce nicio atingere drepturilor şi obligaţiilor titularului dreptului de proprietate, conform legislaţiei în vigoare.</p>
              <p>&nbsp;</p>
              <p>Capitolul III</p>
              <p>Drepturile şi obligaţiile proprietarilor</p>
              <p>Secţiunea 1</p>
              <p>Drepturile proprietarilor din condominii</p>
              <p>&nbsp;</p>
              <p>Art.27. – Toţi proprietarii membri ai asociaţiei de proprietari au dreptul să participe, cu drept de vot, la adunarea generală a asociaţiei de proprietari, să îşi înscrie candidatura, să candideze, să aleagă şi să fie aleşi în structura organizatorică a asociaţiei de proprietari. Pentru a beneficia de dreptul de a fi ales, persoana în cauză trebuie sa aibă capacitate deplină de exerciţiu.</p>
              <p>&nbsp;</p>
              <p>Art.28. – (1) Proprietarii din condominiu au dreptul să fie informaţi în legătură cu toate aspectele ce privesc activitatea asociaţiei, să solicite în scris şi să primească copii după orice document al acesteia.</p>
              <p>Proprietarii care solicită copii după documentele asociaţiei de proprietari vor suporta costul de multiplicare al acestora.</p>
              <p>(2) În vederea bunei informări a proprietarilor, asociaţia de proprietari amplasează la loc vizibil un avizier la care sunt afişatedocumente în conformitate cu prevederile prezentei legi.</p>
              <p>(3) Proprietarii din condominii au dreptul să primească explicaţii cu privire la calculul cotei de contribuţie la cheltuielile asociatiei de proprietari şi, după caz, să o conteste în scris, în termen de 10 zile de la afişarea listei de plată. Preşedintele asociaţiei de proprietari este obligat să răspundă, în scris, la contestaţie în termen de 10 zile de la primirea acesteia.</p>
              <p>(4) În cazul în care preşedintele sau administratorul nu ia toate măsurile necesare în scopul facilitării accesului proprietarilor ladocumentele contabile sau la orice alt document al asociaţiei de proprietari, conform legii, proprietarii au dreptul de a notifica despre aceasta comitetul executiv şi cenzorul/comisia de cenzori. În situaţia nesoluţionării sau a soluţionării necorespunzătoare a solicitărilor notificate în termen de 10 de zile de la depunerea acestora, proprietarii se pot adresa compartimentelor prevăzute la art.10.</p>
              <p>&nbsp;</p>
              <p>Art.29. – (1) În cazurile în care unul dintre proprietari împiedică, cu rea credinţă şi sub orice formă, folosirea normală a condominiului sau a unor părţi componente, potrivit destinaţiei acestora şi creează prejudicii celorlalţi proprietari, proprietarii prejudiciaţi sau orice persoană care se consideră vătămată într-un drept al său se poate adresa în scris preşedintelui, comitetului executiv al asociaţiei de proprietari şi instituţiilor cu atribuţii privind respectarea ordinii şi liniştii publice sau,după caz, instanţelor judecătoreşti.</p>
              <p>(2) Dacă o hotărâre a adunării generale a asociaţiei de proprietari, o decizie a comitetului executiv sau a preşedintelui este contrară prevederilor prezentei legi sau este de natură să prejudicieze interesele proprietarilor, aceştia sau orice persoană care se consideră vătămată într-un drept al său poate sesiza cenzorul/comisia de cenzori, compartimentele prevăzute la art.10 sau, după caz, pot solicita instanţelor judecătoreşti anularea în tot sau în parte a hotărârii/deciziei şi/sau repararea pagubei cauzate.</p>
              <p>(3) Orice persoană sau orice proprietar care se consideră vătămată/vătămat într-un drept al său din cauza neîndeplinirii sauîndeplinirii defectuoase a atribuţiilor de către preşedinte, membri ai comitetului executiv, cenzor/comisia de cenzori sau de către administrator, se poate adresa în scris compartimentelor prevăzute la art.10 sau, după caz, instanţelor judecătoreşti.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 2-a</p>
              <p>Obligaţiile proprietarilor din condominii</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Art.30. – (1) Proprietarii din condominii au obligaţia să notifice preşedintelui orice schimbare intervenită în structura şi numărulmembrilor familiei prin deces, căsătorii sau naşteri, persoanele luate în spaţiu, precum şi locatarii/comodatarii ca urmare a închirierii sau a împrumutării locuinţei.</p>
              <p>(2) Notificarea prevăzută la alin.(1) se face în scris în termen de 10 zile de la schimbarea intervenită, de la luarea în spaţiu, respectiv de la semnarea contractului de închiriere/comodat.</p>
              <p>(3) În condiţiile prezentei legi şi cu respectarea prevederilor legislaţiei în vigoare privind calitatea în construcţii, proprietarul este obligat să menţină proprietatea sa individuală, locuinţă sau spaţiu cu altă destinaţie decât aceea de locuinţă, în stare bună din punct de vedere tehnic şi funcţional, pe propria cheltuială. Niciun proprietar, chiriaş sau angajat al asociaţiei de proprietari nu poate încălca, afecta sau prejudicia dreptul de proprietate comună sau individuală a celorlalţi proprietari dincondominiu şi nu poate afecta funcţionarea normală şi întreţinerea condominiului.</p>
              <p>(4) În cadrul proprietăţii individuale, proprietarul are obligaţia să păstreze şi să întreţină în stare de funcţionare şi siguranţă spaţiile interioare, echipamentele şi instalaţiile din dotarea tehnică a proprietăţii individuale, respectiv instalaţiile sanitare, de încălzire, canalizare, alimentare cu energie electrică, gaz, apă, precum şi altele de această natură, pe cheltuiala sa, astfel încât să nu aducă prejudicii celorlalţi proprietari din condominiu.</p>
              <p>(5) Dacă proprietarul unei locuinţe ori al unui spaţiu cu altă destinaţie provoacă daune oricărei părţi din proprietatea comună sau din proprietatea individuală a altui proprietar din condominiu, respectivul proprietar are obligaţia să repare stricăciunile sau să suporte cheltuielile pentru lucrările de reparaţii.</p>
              <p>(6) Raporturile juridice stabilite de comun acord între locator şi locatar, precum şi nerespectarea obligaţiilor contractuale de către locatar nu absolvă proprietarul, în calitate de locator, de obligaţiile sale faţă de asociaţia de proprietari sau faţă de furnizorii de utilităţi publice, prevăzute în prezenta lege.</p>
              <p>&nbsp;</p>
              <p>Art.31. – (1) Cu un preaviz motivat scris de 5 zile emis de comitetul executiv al asociaţiei de proprietari, proprietarul este obligat să permită accesul preşedintelui sau al unui membru al comitetului executiv, administratorului şi al unei persoane calificate în realizarea lucrărilor de construcţii/reparaţii, după caz, în proprietatea sa individuală, atunci când este necesar să se inspecteze, să se repare ori să se înlocuiască elemente din proprietatea comună, la care se poate avea acces numai din respectiva proprietate individuală. Fac excepţie cazurile de urgenţă când termenul pentru preaviz este de 24 de ore.</p>
              <p>(2) În cazul în care proprietarul nu permite accesul în proprietatea sa, în conformitate cu prevederile alin.(1), acesta răspunde civil şi penal, după caz, pentru toate prejudiciile create proprietarilor afectaţi, în condiţiile legii.</p>
              <p>(3) Proprietarii din condominiu care suferă un prejudiciu ca urmare a executării lucrărilor prevăzute la alin.(1) sunt despăgubiţi de către asociaţia de proprietari, din fondul de reparaţii.</p>
              <p>(4) În cazul în care prejudiciul este cauzat de către executantul lucrărilor de intervenţie, persoană fizică/juridică, despăgubirea va fi recuperată de la acesta, în condiţiile legii.</p>
              <p>(5) Lucrările de intervenţie la elementele componente ale proprietăţii comune, efectuate în baza alin.(1), se execută în condiţiistabilite de comun acord şi consemnate printr-un acord semnat de către proprietar şi asociaţia de proprietari.</p>
              <p>(6) În situaţia în care, pe parcursul executării lucrărilor de intervenţie în baza prevederilor alin.(1), se afectează în orice modproprietatea individuală, cheltuielile necesare pentru aducerea la starea tehnică iniţială a acestora se suportă în condiţiile alin.(3) şi/sau (4), după caz.</p>
              <p>&nbsp;</p>
              <p>Art.32. – (1) Proprietarii sunt obligaţi să ia măsuri pentru consolidarea şi modernizarea condominiului, modernizarea echipamentelor, instalaţiilor şi dotărilor aferente, montarea contoarelor pentru individualizarea consumurilor la nivel de proprietate individuală, reabilitarea termică în scopul creşterii performanţei energetice, precum şi reabilitarea structural-arhitecturală a anvelopei condominiului pentru creşterea calităţii arhitectural-ambientale a acestuia, potrivit prevederilor legale, în condiţiile menţinerii aspectului armonios şi unitar al întregului condominiu, indiferent de natura intervenţiilor.</p>
              <p>(2) În cazul condominiilor de tipul imobilelor colective multietajate, modificarea aspectului faţadei se face numai în mod unitar pe întreg condominiul, indiferent de numărul asociaţiilor de proprietari constituite pe scări sau tronsoane, în baza unei documentaţii tehnice elaborate în condiţiile legii, de către arhitecţi cu drept de semnătură şi cu respectarea prevederilor legale în vigoare privind autorizarea executării lucrărilor de construcţii, regimul monumentelor istorice şi al zonelor protejate,</p>
              <p>reabilitare termică pentru creşterea performanţei energetice a clădirilor şi reabilitarea structural-arhitecturală a anvelopei acestora pentru creşterea calităţii arhitectural-ambientale a clădirilor.</p>
              <p>(3) Înainte de modificarea aspectului faţadei condominiului, preşedintele asociaţiei de proprietari solicită, în scris, autorităţii administraţiei publice locale toate informaţiile şi restricţiile referitoare la culoare, aspect, materiale şi altele asemenea, stabilite prin regulamentul local de urbanism sau prin regulamentele de intervenţie aferente zonelor de intervenţie prioritară stabiliteîn cadrul programelor multianuale privind creşterea calităţii arhitectural ambientale a clădirilor.</p>
              <p>(4) În cazul condominiilor cu mai multe tronsoane sau scări legate structural, lucrările de consolidare sau modernizare, reabilitare termică şi structural-arhitecturală se vor realiza în mod unitar pentru întregul ansamblul, cu respectarea întocmai a caracterului arhitectural al condominiului şi al caracterului ambiental al ansamblului/zonei de amplasament.</p>
              <p>(5) Proprietarii construcţiilor, persoane fizice sau juridice, şi asociaţiile de proprietari, precum şi persoanele juridice care au în administrare construcţii încadrate în clasele de risc seismic şi/sau afectate de seisme, sunt obligaţi să ia măsurile prevăzute de lege pentru reducerea riscului seismic al construcţiilor.</p>
              <p>&nbsp;</p>
              <p>Art.33. – (1) Proprietarii din condominii care îşi înstrăinează locuinţele sau spaţiile cu altă destinaţie decât aceea de locuinţă sunt obligaţi ca la întocmirea actelor de înstrăinare să facă dovada plăţii la zi a cotelor de contribuţie la cheltuielile asociaţiei de proprietari şi a utilităţilor publice.</p>
              <p>(2) Notarii publici vor autentifica actele de înstrăinare a unităţilor de proprietate imobiliară din condominii numai dacă este îndeplinită una din următoarele condiţii:</p>
              <ol>
                <li>a) proprietarul prezintă o adeverinţă din partea asociaţiei de proprietari, care reprezintă dovada achitării până la acea dată a cotelor de contribuţie la cheltuielile asociaţiei de proprietari, iar în cazul neachitării integrale, menţionarea cuantumului debitelor existente la acea dată. Adeverinţa va avea număr de înregistrare din evidenţele asociaţiei, data eliberării şi termenul de valabilitate de 30 zile calendaristice. Adeverinţa se prezintă notarului public, de către înstrăinător, în original cu semnătura olografă a administratorului asociaţiei de proprietari şi a preşedintelui, cu precizarea numelui şi prenumelui acestora, şi cu ştampila asociaţiei de proprietari. Prin excepţie, semnătura oricăruia dintre aceştia este suficientă, caz în care în cuprinsuladeverinţei se va menţiona motivul lipsei celei de-a doua semnături. În situaţia în care condominiul nu are constituită asociaţie de proprietari, sau deşi constituită, aceasta nu are preşedinte şi administrator în funcţie, sau plata cotelor de contribuţie la cheltuielile comune ale asociaţiei de proprietari sau/şi pentru utilităţile publice se face printr-o altă modalitate agreată de proprietarii condominiului, înstrăinătorul va da o declaraţie pe proprie răspundere cu privire la acest aspect, declaraţie care va fi menţionată în cuprinsul actului notarial de înstrăinare şi va prezenta notarului public copia ultimei facturi împreună cu dovada achitării utilităţilor publice şi/sau a cotelor de contribuţie la cheltuielile asociaţiei de proprietari şi/sau cuantumul debitelor acestora de la acea dată. Factura prezentată nu poate fi pentru o perioadă anterioară mai mare de 2 luni;</li>
                <li>b) în cazul existenţei unor datorii către asociaţia de proprietari sau către furnizorii de utilităţi publice în cazul facturării individuale a serviciilor, notarul public va autentifica actul de înstrăinare dacă dobânditorul acceptă expres că va prelua în întregime toate debitele înstrăinătorului către asociaţia de proprietari precum şi toate debitele către toţi furnizorii de utilităţi publice.</li>
              </ol>
              <p>(3) Dispoziţiile alin.(2) se aplică în mod corespunzător şi la pronunţarea unei hotărârii judecătoreşti care ţine loc de act de înstrăinare, prin care se înstrăinează locuinţele sau spaţiile cu altă destinaţie decât aceea de locuinţă dintr-un condominium.</p>
              <p>(4) Actele de înstrăinare, autentificate de notarul public cu nerespectarea prevederilor alin.(2) sunt anulabile.</p>
              <p>(5) La înstrăinarea proprietăţii, proprietarul este obligat să transmită către dobânditor, orice documente pe care le deţine şi care pot avea consecinţe asupra drepturilor şi obligaţiilor privitoare la proprietatea care este înstrăinată, inclusiv certificatul de performanţă energetică aferent acesteia, întocmit în condiţiile legii. În actul de înstrăinare părţile vor declara în mod</p>
              <p>corespunzător despre îndeplinirea acestor obligaţii.</p>
              <p>(6) La încheierea actului autentic sau, după caz, la pronunţarea hotărârii judecătoreşti ori a încheierii actului de adjudecare, notarul public sau, după caz, instanţa de judecată ori executorul judecătoresc vor menţiona în act că noului proprietar îi revine obligaţia să prezinte preşedintelui asociaţiei de proprietari, în termen de 10 zile lucrătoare de la efectuarea transferului</p>
              <p>dreptului de proprietate, informaţiile necesare, conform actelor de proprietate, în vederea calculului cotelor de contribuţie la cheltuielile asociaţiei de proprietari pentru respectiva locuinţă sau pentru spaţiile cu altă destinaţie decât aceea de locuinţă si datele de contact ale proprietarului.</p>
              <p>(7) În cazul închirierii, constituirii unor drepturi reale de uz, uzufruct, abitaţie, precum şi în cazul unor contracte de comodat pentru spaţiile din condominiu, obligaţia de plată a cotelor de întreţinere revine proprietarului, acesta recuperând de la beneficiarii dreptului de folosinţă a spaţiilor respective, contravaloarea acestora în condiţiile stabilite prin contractul de închiriere/constituire/comodat.</p>
              <p>(8) La solicitarea oricărui proprietar, preşedintele şi/sau administratorul sunt obligaţi să elibereze adeverinţa prevăzută la alin.(2) lit.a) care să dovedească achitarea la zi a cotelor de contribuţie la cheltuielile asociaţiei de proprietari sau existenţa unor datorii către asociaţia de proprietari sau către furnizorii de utilităţi publice, după caz, în termen de 3 zile lucrătoare de la primirea solicitării acesteia.</p>
              <p>(9) Preşedintele sau administratorul asociaţiei de proprietari are obligaţia solicitării notării în cartea funciară a debitelor mai vechi de 3 luni şi care depăşesc valoarea salariului de bază minim brut pe ţară garantat în plată. Birourile de cadastru şi publicitate imobiliară vor proceda la notarea acestor debite în cartea funciară a locuinţelor sau spaţiilor cu altă destinaţie a acestor debite.</p>
              <p>(10) Notarea în cartea funciară a debitelor prevăzute la alin.(9) este scutită de plata taxei de timbru.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 3-a</p>
              <p>Modul de utilizare a proprietăţii individuale şi a părţilor comune</p>
              <p>&nbsp;</p>
              <p>Art.34. – (1) Utilizarea proprietăţii individuale este stabilită de proprietar în conformitate cu destinaţia acesteia.</p>
              <p>(2) Proprietarul poate aduce îmbunătăţiri sau modificări proprietăţii sale individuale, cu respectarea prevederilor legale în vigoare referitoare la autorizarea executării lucrărilor de construcţii de către autoritatea administraţiei publice competente, fără a pune în pericol integritatea structurală a condominiului, rezistenţa mecanică, stabilitatea şi siguranţa clădirii sau a altor proprietăţi individuale, precum şi buna funcţionare a instalaţiilor aferente acestora.</p>
              <p>(3) Modificările suprafeţelor construite sau utile se comunică preşedintelui asociaţiei de proprietari şi organului fiscal local în termen de 30 zile de la încheierea lucrărilor, şi se introduc în cartea tehnică a construcţiei şi în baza de date a asociaţiei, în vederea stabilirii consumurilor corespunzătoare cu noile suprafeţe utile.</p>
              <p>&nbsp;</p>
              <p>Art.35.- (1) În cazul clădirilor cu mai multe locuinţe sau spaţii cu altă destinaţie şi în cazul clădirilor multietajate, constituie părţi comune din condominiu, dacă actele de proprietate nu prevăd altfel, următoarele:</p>
              <ol>
                <li>a) terenul pe care se află clădirea, compus atât din suprafaţa construită, cât şi din cea neconstruită necesară, potrivit naturii saudestinaţiei construcţiei, pentru a asigura exploatarea normală a acesteia;</li>
                <li>b) fundaţia, curţile, grădinile, căile de acces;</li>
                <li>c) clădirea propriu-zisă, elementele de echipament comun, inclusiv părţile de canalizare aferente, care traversează proprietăţile private;</li>
                <li>d) corpurile de clădiri destinate serviciilor comune;</li>
                <li>e) locurile de trecere, scările şi casa scărilor şi coridoarele; pereţii perimetrali şi despărţitori dintre proprietăţi şi/sau spaţiile comune; subsolul; coşurile de fum; holurile; instalaţiile de apă şi canalizare, electrice, de telecomunicaţii, de încălzire şi de gaze de la branşament/racord până la punctul de distribuţie către părţile aflate în proprietate individuală; canalele pluviale; paratrăsnetele; antenele colective; podul; pivniţele, boxele, spălătoria, uscătoria; ascensorul; interfonul – partea de instalaţie</li>
              </ol>
              <p>de pe proprietatea comună; rezervoarele de apă; centrala termică proprie a clădirii; crematoriul; tubulatura de evacuare a deşeurilor menajere; structura, structura de rezistenţă; faţadele; acoperişul; terasele;</p>
              <ol>
                <li>f) alte bunuri care, potrivit legii, actului de proprietate sau voinţei părţilor, sunt în folosinţă comună.</li>
              </ol>
              <p>(2) În cazul ansamblurilor rezidenţiale formate din locuinţe şi/sau construcţii cu altă destinaţie, amplasate izolat, înşiruit sau cuplat, constituie părţi comune din condominiu, dacă actele de proprietate nu prevăd altfel, următoarele:</p>
              <ol>
                <li>a) curţile, grădinile, căile de acces;</li>
                <li>b) elementele de echipament comun, inclusiv părţile de canalizare aferente, care traversează proprietăţile private;</li>
                <li>c) corpurile de clădiri destinate serviciilor comune;</li>
                <li>d) locurile de trecere; instalaţiile de apă şi canalizare, electrice, de telecomunicaţii, de încălzire şi de gaze de la branşament/racord până la punctul de distribuţie către părţile aflate în proprietate individuală; canalele pluviale; rezervoarele de apă;</li>
                <li>e) alte bunuri care, potrivit legii, actului de proprietate sau voinţei părţilor, sunt în folosinţă comună.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.36. – Schimbarea destinaţiei proprietăţii comune este permisă cu îndeplinirea cumulativă a următoarelor condiţii:</p>
              <ol>
                <li>a) numai dacă prin aceasta nu sunt lezate drepturile celorlalţi coproprietari;</li>
                <li>b) numai dacă prin aceasta nu se împiedică folosirea în condiţii normale a condominiului de către toţi proprietarii;</li>
                <li>c) în baza acordului în scris al tuturor proprietarilor din condominiu;</li>
                <li>d) după obţinerea prealabilă a acordurilor, avizelor şi autorizaţiilor necesare prevăzute de legislaţia în vigoare, emise în condiţiile legii de instituţiile/autorităţile abilitate în acest sens;</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.37. – (1) Zidurile ce separă proprietăţile individuale şi/sau părţile comune dintr-un condominiu sunt proprietate comună.</p>
              <p>(2) Zidurile dintre proprietăţile individuale, care nu fac parte din structura de rezistenţă a condominiului, pot fi reamplasate cu acordul proprietarilor apartamentelor sau spaţiilor respective, în baza unei expertize tehnice şi a autorizaţiei de construire, eliberate de autoritatea publică locală, în conformitate cu legislaţia în vigoare, cu înştiinţarea asociaţiei de proprietari.</p>
              <p>(3) Zidurile dintre proprietăţile individuale şi proprietatea comună care nu fac parte din structura de rezistenţă a condominiului pot fi reamplasate numai în baza unei expertize tehnice, prin modificarea acordului de asociere, cu acordul tuturor proprietarilor din condominiu şi a autorizaţiei de construire, eliberate de autoritatea administraţiei publicecompetente în conformitate cu legislaţia în vigoare, numai dacă prin aceste modificări nu sunt lezate drepturile celorlalţi proprietari din condominiu.</p>
              <p>(4) Proprietarii din condominii au obligaţia de a depune la preşedintele asociaţiei de proprietari fotocopii după autorizaţiile deconstruire pentru executarea lucrărilor, eliberate în condiţiile legii, în scopul recalculării suprafeţelor utile şi a suprafeţelor construite.</p>
              <p>(5) Orice modificare a proprietăţii individuale care necesită emiterea unei autorizaţii de construire/desfiinţare, în conformitate cu legislaţia în vigoare, se aduce la cunoştinţa asociaţiei pentru înscrierea în jurnalul evenimentelor din cartea tehnică a construcţiei, prin depunerea în copie a actelor de autorizare, a procesului-verbal de recepţie la terminarea lucrărilor şi a planurilor din proiectul tehnic.</p>
              <p>(6) Modificarea instalaţiilor interioare ale condominiului aflate în proprietate comună, se realizează numai în condiţiile legii, pe baza unui referat tehnic de specialitate, emis de furnizorul utilităţii, în care se arată efectele modificării instalaţiei respective şi a autorizaţiei de construire.</p>
              <p>Pe baza referatului tehnic, adunarea generală hotărăşte, cu votul majorităţii a două treimi din numărul membrilor asociaţiei, asupra necesităţii şi oportunităţii efectuării modificării. În cazul producerii locale, la nivel de condominiu, a energiei termice şi a apei calde de consum, nu este necesar referatul tehnic menţionat.</p>
              <p>(7) În cazul în care unul sau mai mulţi proprietari din condominiu doresc modificarea instalaţiilor de distribuţie a gazelor şi modificarea caracteristicilor puterii termice a elementelor de încălzire în interiorul proprietăţilor individuale, aceste lucrări se pot efectua numai în condiţiile legii, pe baza referatului tehnic de specialitate emis de furnizorul serviciului de utilităţi publice, în care se arată efectele modificării instalaţiei respective. Pe baza referatului tehnic, comitetul executiv al asociaţiei de proprietari, cu acordul tuturor proprietarilor direct afectaţi de vecinătate, decide asupra efectuării modificării. În</p>
              <p>cazul producerii locale, la nivel de condominiu, a energiei termice şi a apei calde de consum, nu este necesar referatul tehnic menţionat.</p>
              <p>&nbsp;</p>
              <p>Art.38. – (1) Fiecare proprietar din condominiu poate folosi, în condiţiile acordului de asociere, atât spaţiul care constituie bunul principal, cât şi părţile comune, fără a aduce atingere drepturilor celorlalţi proprietari şi fără a schimba destinaţia clădirii.</p>
              <p>(2) Părţile comune pot fi utilizate de către terţi, persoane fizice sau juridice de drept public şi/sau privat, numai ca urmare a hotărârii adunării generale a asociaţiei de proprietari, adoptată cu acordul a două treimi din numărul total al proprietarilor din condominiu şi cu acordul tuturor proprietarilor direct afectaţi de vecinătate, în baza unui contract de închiriere, de folosinţă sau de concesiune, semnat de preşedinte în numele asociaţiei şi avizat de cenzor/comisia de cenzori.</p>
              <p>&nbsp;</p>
              <p>Art.39. – Modificările constructive şi utilizarea în alte scopuri a unor părţi sau elemente de construcţie ale condominiului, precum şi amplasarea de mijloace publicitare pe faţada şi/sau pe terasa/învelitoarea imobilului, suspendarea de stâlpi, pereţi, scări interioare şi altele asemenea, se fac numai cu acordul a două treimi din proprietarii membri ai asociaţiei de proprietari, al tuturor proprietarilor direct afectaţi, şi pe baza autorizaţiei de construire, cu respectarea legislaţiei în vigoare.</p>
              <p>&nbsp;</p>
              <p>Art.40. – (1) Schimbarea destinaţiei locuinţelor, precum şi a spaţiilor cu altă destinaţie decât aceea de locuinţă, prezente în proiectul iniţial al imobilului, se poate face numai cu avizul scris al comitetului executiv şi cu acordul scris, prealabil, al proprietarilor direct afectaţi cu care se învecinează, pe plan orizontal şi vertical, spaţiul supus schimbării.</p>
              <p>(2) Odată cu acordul menţionat la alin.(1), proprietarii direct afectaţi cu care se învecinează, pe plan orizontal şi vertical, spaţiul supus schimbării, şi proprietarul care solicită acordul pentru schimbarea destinaţiei locuinţei sau spaţiului cu altă destinaţie decât aceea de locuinţă semnează şi o convenţie cu privire la declararea activităţii care se va desfăşura în spaţiul respectiv, precum şi numărul de persoane în funcţie de care vor fi calculate cheltuielile pe număr de persoane.</p>
              <p>(3) O copie a convenţiei prevăzute la alin.(2) se înmânează preşedintelui asociaţiei de proprietari în vederea anexării acesteia la acordul de asociere.</p>
              <p>&nbsp;</p>
              <p>Art.41. – Anterior modificărilor constructive în cadrul proprietăţilor individuale, proprietarii obţin avizele şi autorizaţiile aşa cum sunt reglementate de legislaţia în vigoare, şi au obligaţia să nu pună în pericol integritatea structurală a condominiului sau a altor proprietăţi individuale, precum şi sănătatea populaţiei, conform legislaţiei în vigoare.</p>
              <p>&nbsp;</p>
              <p>Art.42. – În cazul distrugerii în întregime sau parţială a condominiului, se aplică prevederile art.657 din Legea nr.287/2009, republicată, cu modificările ulterioare.</p>
              <p>&nbsp;</p>
              <p>Capitolul IV</p>
              <p>Încetarea destinaţiei folosinţei comune pentru părţile comune din condominiu</p>
              <p>&nbsp;</p>
              <p>Art.43. – (1) Părţile comune pot fi atribuite proprietarilor în folosinţă exclusivă numai dacă prin aceasta nu sunt lezate drepturile celorlalţi proprietari.</p>
              <p>(2) Hotărârea de atribuire în folosinţă exclusivă se adoptă cu acordul tuturor proprietarilor din condominiu, şi al cotelor-părţi indivize.</p>
              <p>(3) Atribuirea în folosinţă exclusivă se face în baza unui contract încheiat între asociaţia de proprietari şi proprietarul căruia i se atribuie în folosinţă exclusivă partea de proprietate comună.</p>
              <p>(4) Contractul prevăzut la alin.(3) se încheie pe o perioadă de maximum 12 luni şi poate fi reînnoit numai cu acordul ambelor părţi şi cu respectarea prevederilor prezentei legi.</p>
              <p>(5) Veniturile obţinute din atribuirea părţilor comune în folosinţă exclusivă respectă prevederile art. 70 din prezenta lege.</p>
              <p>&nbsp;</p>
              <p>Art.44. – (1) Încetarea destinaţiei de folosinţă comună pentru părţile comune din condominii se poate hotărî motivat cu acordul scris al tuturor proprietarilor din condominiu.</p>
              <p>(2) În cazul prevăzut la alin.(1), devin aplicabile dispoziţiile privitoare la coproprietatea obişnuită. Cu toate acestea, înstrăinarea sau ipotecarea se poate realiza numai cu acordul tuturor proprietarilor din condominiu.</p>
              <p>(3) Constatarea încetării destinaţiei de folosinţă comună pentru părţile comune se face prin hotărâre a adunării generale a asociaţiei de proprietari adoptată în unanimitate de către proprietarii din condominiu.</p>
              <p>(4) Imobilul, respectiv partea din condominiu care rezultă din încetarea destinaţiei folosinţei comune se înscrie în mod corespunzător în cartea funciară pe baza documentaţiei cadastrale întocmite în acest scop.</p>
              <p>(5) Prevederile prezentului articol nu se aplică părţilor comune din condominiu pentru care nu poate înceta destinaţia de folosinţă comună, întrucât poate afecta buna funcţionare a condominiului şi pot fi lezate drepturile celorlalţi proprietari.</p>
              <p>(6) Înscrierea în cartea funciară ca unitate individuală distinctă a părţilor de folosinţă comună pentru care încetează această destinaţie potrivit dispoziţiilor legale, implică parcurgerea următoarelor etape:</p>
              <ol>
                <li>a) emiterea hotărârii adunării generale a asociaţiei de proprietari prin care se constată încetarea destinaţiei de folosinţă comună;</li>
                <li>b) recepţia documentaţiei cadastrale pentru recalcularea cotelor părţi indivize, în vederea scoaterii din părţile de folosinţă comună;</li>
                <li>c) încheierea actului în formă autentică prin care toţi proprietarii din condominiu îşi exprimă acordul pentru încetarea destinaţiei de folosinţă comună şi pentru diminuarea corespunzătoare a cotelor părţi din proprietatea comună.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Capitolul V</p>
              <p>Organizarea şi funcţionarea asociaţiilor de proprietari</p>
              <p>&nbsp;</p>
              <p>Art.45. – Organele asociaţiei de proprietari sunt:</p>
              <ol>
                <li>a) adunarea generală;</li>
                <li>b) comitetul executiv;</li>
                <li>c) preşedintele;</li>
                <li>d) cenzorul sau comisia de cenzori.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.46. – (1) La adunarea proprietarilor în vederea constituirii asociaţiei de proprietari, proprietarii vor alege dintre cei prezenţi un comitet executiv format din preşedintele asociaţiei de proprietari şi un număr par de membri, precum şi un cenzor sau o comisie de cenzori, şi vor hotărî numărul membrilor acestora şi durata mandatelor lor.</p>
              <p>Alegerea acestora se face cu jumătate plus unu din numărul proprietarilor prezenţi. Durata mandatelor nu poate fi mai mare de 4 ani şi pot fi reînnoite.</p>
              <p>(2) Dacă dintre membrii asociaţiei de proprietari nu se poate alege un cenzor, atunci adunarea generală a proprietarilor mandatează comitetul executiv pentru angajarea unui cenzor din afara asociaţiei de proprietari, persoană fizică cu studii superioare în domeniul economic sau juridic ori persoană juridică cu domeniul de activitate contabilitate, audit financiar sau consultanţă în domeniul fiscal, pe bază de contract individual de muncă sau, după caz, contract de prestări de servicii.</p>
              <p>(3) Structurile prevăzute la alin.(1) sunt obligatorii pentru asociaţiile de proprietari din condominiile cu mai mult de 10 unităţi de proprietate imobiliară.</p>
              <p>(4) La asociaţiile de proprietari din condominiile care cuprind maximum 10 unităţi de proprietate imobiliară, dacă adunarea generală hotărăşte în acest sens, preşedintele asociaţiei de proprietari poate îndeplini şi funcţia de administrator, şi se constituie următoarele organe:</p>
              <ol>
                <li>a) adunarea generală;</li>
                <li>b) preşedintele;</li>
                <li>c) cenzorul.</li>
              </ol>
              <p>(5) Funcţia de preşedinte, respectiv membru în comitetul executiv este incompatibilă cu funcţia de cenzor sau membru în comisia de cenzori.</p>
              <p>(6) Nu au dreptul de a fi alese sau numite în funcţii de conducere, administrare sau control din cadrul asociaţiilor de proprietari persoanele înrudite până la gradul al III-lea cu cei care deja ocupă aceste funcţii.</p>
              <p>(7) Nu pot fi alese sau numite în funcţie de conducere, administrare ori control din cadrul asociaţiilor de proprietari persoanele care au suferit condamnări, în baza unor hotărâri judecătoreşti rămase definitive, pentru infracţiuni de natură economico-financiară.</p>
              <p>(8) Anterior alegerii sau numirii într-o funcţie de conducere, administrare ori control din cadrul asociaţiilor de proprietari, persoanele candidate trebuie să dovedească în adunarea generală respectarea prevederilor alin.(7), pe baza cazierului judiciar eliberat conform prevederilor legale în vigoare şi, în cazul administratorului şi al cenzorului ori, după caz, al membrilor comisiei de cenzori şi a documentelor care să ateste pregătirea profesională în domeniu.</p>
              <p>(9) Prin excepţie de la prevederile alin. (8), sub sancţiunea anulării hotărârii de numire/alegerii într-o funcţie de conducere, administrare ori control din cadrul asociaţiilor de proprietari, cazierul judiciar poate fi prezentat comitetului executiv în termen de cel mult 5 zile de la data adunării generale.</p>
              <p>&nbsp;</p>
              <p>Art.47. – (1) După constituirea asociaţiei de proprietari, va avea loc cel puţin o dată pe an, în primul trimestru, o întrunire a adunării generale a asociaţiei de proprietari. Responsabilitatea cu privire la convocarea anuală a cel puţin unei adunări generale a asociaţiei de proprietari aparţine preşedintelui asociaţiei de proprietari şi membrilor comitetului executiv.</p>
              <p>(2) Adunarea generală este alcătuită din toţi proprietarii membri ai asociaţiei de proprietari.</p>
              <p>(3) Adunarea generală ordinară se convoacă de către:</p>
              <ol>
                <li>a) preşedintele asociaţiei de proprietari sau comitetul executiv;</li>
                <li>b) cel puţin 20% din numărul proprietarilor membri ai asociaţiei de proprietari.</li>
              </ol>
              <p>(4) Proprietarii sunt anunţaţi, prin afişare la avizier şi pe bază de tabel nominal convocator sau, în cazul proprietarilor care nu au fost anunţaţi pe baza tabelului nominal convocator, prin poştă cu scrisoare recomandată, cu conţinut declarat şi confirmare de primire, asupra oricărei adunări generale a asociaţiei de proprietari, cu cel puţin 10 zile înainte de data stabilită, inclusiv cu privire la ordinea de zi a adunării generale, cu excepţia adunărilor generale extraordinare sau a adunărilor generale reconvocate care se pot convoca, în condiţiile prevăzute la alin. (3), cu cel puţin 3 zile înainte de data stabilită.</p>
              <p>(5) Propunerea de ordine de zi a adunării generale, semnată de preşedinte, membrii comitetului executiv şi de cenzor/comisia de cenzori se aprobă şi se completează prin hotărâre a adunării generale.</p>
              <p>&nbsp;</p>
              <p>Art.48. – (1) Adunarea generală poate adopta hotărâri, dacă majoritatea proprietarilor membri ai asociaţiei de proprietari suntprezenţi personal sau prin reprezentanţi care au o împuternicire scrisă şi semnată de către proprietarii în numele cărora votează.</p>
              <p>(2) Dacă la prima convocare nu este întrunit cvorumul necesar de jumătate plus unu din membrii asociaţiei de proprietari, întrunirea adunării generale se suspendă şi se reconvoacă în termen de maximum 15 zile de la data primei convocări în condiţiile prevăzute la art.47.</p>
              <p>(3) La adunarea generală reconvocată, dacă există dovada că toţi membrii asociaţiei de proprietari au fost convocaţi pe bază de tabel convocator sau prin poştă cu scrisoare recomandată, cu conţinut declarat şi confirmare de primire, şi prin afişare la avizier, hotărârile pot fi adoptate, indiferent de numărul membrilor prezenţi, prin votul majorităţii acestora.</p>
              <p>(4) Hotărârile adunării generale a asociaţiei de proprietari sunt obligatorii de la data afişării acestora la loc vizibil, conform prevederilor prezentei legi, inclusiv pentru proprietarii din condominiu care nu au fost prezenţi la adunarea generală, precum şi pentru proprietarii care nu sunt membri ai asociaţiei de proprietari.</p>
              <p>(5) Hotărârile luate, în conformitate cu prevederile alin.(1)-(4), obligă toţi proprietarii să participe, în proporţiile stabilite de adunarea generală, la plata lucrărilor, precum şi la cheltuielile de funcţionare, administrare, întreţinere şi de înlocuire a părţilor comune sau a elementelor transformate sau create.</p>
              <p>&nbsp;</p>
              <p>Art.49. – (1) Hotărârile asociaţiei de proprietari sunt luate în adunarea generală a proprietarilor, iar executarea lor este încredinţată comitetului executiv şi administratorului.</p>
              <p>(2) În vederea punerii în practică a hotărârilor adunării generale a asociaţiei de proprietari, comitetul executiv al asociaţiei de proprietari emite decizii în conformitate cu prevederile legii.</p>
              <p>(3) Pentru adoptarea hotărârilor în adunarea generală a asociaţiei de proprietari, se au în vedere următoarele:</p>
              <ol>
                <li>a) fiecare proprietar, membru al asociaţiei, are dreptul la un vot pentru unitatea sa de proprietate imobiliară;</li>
                <li>b) pentru hotărârile cu privire la stabilirea fondurilor pentru consolidare, reabilitare şi modernizare, votul fiecărui proprietar, membru al asociaţiei, are o pondere egală cu cota-parte indiviză din proprietatea comună;</li>
                <li>c) în situaţia în care un proprietar deţine o cotă-parte indiviză de părţi comune mai mare de jumătate din totalul cotelor-părţi indivize de proprietate comună din condominiu, ponderea voturilor de care el dispune este limitată, fiind egală cu suma ponderii voturilor celorlalţi proprietari;</li>
                <li>d) proprietarul, membru al asociaţiei, poate fi reprezentat în adunarea generală de către un membru al familiei sau de către un alt reprezentant care are o împuternicire scrisă şi semnată de către proprietarul în numele căruia votează;</li>
                <li>e) un membru al asociaţiei de proprietari poate reprezenta cel mult un membru absent, dacă prezintă împuternicire scrisă şi semnată de către proprietarii în numele cărora votează. O copie a împuternicirii se ataşează procesului-verbal al şedinţei;</li>
                <li>f) preşedintele, membrii comitetului executiv, administratorul, cenzorul/comisia de cenzori sau alt membru al familiilor acestora nu pot primi mandat pentru a reprezenta un alt proprietar în cadrul adunării generale;</li>
                <li>g) în cazul unui vot paritar, votul preşedintelui asociaţiei de proprietari este decisiv;</li>
                <li>h) administratorul, reprezentantul administratorului, soţul/soţia acestuia şi alţi membri ai familiei sale, dacă sunt membri ai asociaţiei de proprietari la care acesta este angajat, nu au drept de vot în probleme referitoare la activitatea administratorului.</li>
              </ol>
              <p>(4) Hotărârile adunărilor generale se iau în cadrul adunării generale convocată sau reconvocată, după caz. În statutul propriu al asociaţiei de proprietari pot fi prevăzute şi alte condiţii de luare a hotărârilor, dar nu cu mai puţin de jumătate plus unu din voturile celor prezenţi la adunarea generală convocată sau reconvocată. Pentru situaţiile care vizează numai proprietarii unui tronson sau ai unei scări, hotărârile sunt luate prin acordul scris al majorităţii proprietarilor scării sau tronsonului respectiv,</p>
              <p>membri ai asociaţiei de proprietari, pe bază de tabel nominal.</p>
              <p>(5) Hotărârile adunărilor generale se consemnează, numai în timpul şedinţei, în registrul unic de procese-verbale al asociaţiei de proprietari, se semnează de către toţi membrii prezenţi ai adunării generale, de cenzor/comisia de cenzori şi se afişează la avizier.</p>
              <p>(6) În condiţiile prevăzute la art.28, cu cel puţin 3 zile înainte de adunarea generală, proprietarii au acces la toate documentele referitoare la execuţia bugetară pe anul trecut şi la proiectul de buget pe anul în curs, precum şi la orice alte documente necesare bunei desfăşurări a adunării generale.</p>
              <p>(7) Procesul-verbal al şedinţei, care descrie evenimentele în desfăşurare, hotărârile adoptate şi rezultatele voturilor va fi redactat de un membru al asociaţiei numit secretar al adunării generale prin votul majorităţii celor prezenţi. În termen de 7 zile de la data la care a avut loc adunarea generală, procesul-verbal al adunării generale este adus la cunoştinţa tuturor proprietarilor, prin grija preşedintelui asociaţiei de proprietari, prin afişarea unei fotocopii datate la avizierul asociaţiei de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.50. – Acordul de voinţă al asociaţiei de proprietari se realizează:</p>
              <ol>
                <li>a) în adunarea generală a asociaţiei de proprietari, sau</li>
                <li>b) în baza declaraţiilor scrise şi semnate ale fiecărui proprietar.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.51. – Adunarea generală a asociaţiei de proprietari hotărăşte, cu acordul a două treimi din numărul total al proprietarilor din condominiu, asupra oricărei acţiuni vizând modernizarea condominiului, cum ar fi transformarea unuia sau mai multor elemente de echipament existent aparţinând imobilului respectiv, adăugarea de elemente noi, amenajarea corpurilor de clădiri destinate folosinţei comune sau crearea unor astfel de corpuri, cu condiţia ca ea să fie conformă destinaţiei condominiului, cu respectarea legislaţiei în vigoare.</p>
              <p>&nbsp;</p>
              <p>Art.52. – (1) Dacă o hotărâre a adunării generale este contrară legii, statutului sau acordului de asociere a asociaţiei de proprietari ori este de natură să prejudicieze interesele proprietarilor, aceştia sau orice persoană care se consideră vătămată într-un drept al său pot ataca în justiţie respectiva hotărâre.</p>
              <p>(2) Acţionarea în justiţie în conformitate cu prevederile alin.(1) nu întrerupe executarea hotărârii decât în cazul în care instanţa dispune suspendarea acesteia.</p>
              <p>&nbsp;</p>
              <p>Art.53. – Adunarea generală a proprietarilor membri ai asociaţiei de proprietari are următoarele atribuţii:</p>
              <ol>
                <li>a) alege şi revocă din funcţie preşedintele, ceilalţi membri ai comitetului executiv şi cenzorul/comisia de cenzori;</li>
                <li>b) adoptă şi modifică statutul şi regulamentul condominiului;</li>
                <li>c) adoptă, modifică sau revocă hotărâri;</li>
                <li>d) adoptă şi modifică bugetul de venituri şi cheltuieli;</li>
                <li>e) adoptă hotărâri privind executarea lucrărilor de întreţinere, reparaţii, modernizare, consolidare şi reabilitare termică şi eficienţă energetică a condominiului;</li>
                <li>f) în baza acordului scris al tuturor proprietarilor, adoptă hotărâri privind contractarea de împrumuturi de la bănci în vederea acoperirii cheltuielilor pentru consolidarea şi modernizarea condominiului, modernizarea instalaţiilor şi dotărilor aferente, reabilitarea termică în scopul creşterii performanţei energetice, precum şi pentru reabilitarea structural-arhitecturală a anvelopei în vederea creşterii calităţii ambiental-arhitecturale a condominiului, potrivit prevederilor legale;</li>
                <li>g) adoptă hotărâri asupra fondului anual de salarii şi indemnizaţii;</li>
                <li>h) adoptă hotărâri asupra cuantumului indemnizaţiilor precum şi asupra numărului şi funcţiilor personalului încadrat cu contract individual de muncă sau contract de prestări servicii în cadrul asociaţiei de proprietari pentru buna administrare, gestionare şi funcţionare a condominiului, dar şi asupra valorii şi a modalităţilor de contractare, în limita bugetului de venituri şi cheltuieli;</li>
                <li>i) stabileşte modalităţile şi tranşele de plată a contribuţiilor fiecărui proprietar, pentru fiecare dintre categoriile de cheltuieli, conform reglementărilor în vigoare;</li>
                <li>j) stabileşte sistemul de penalizări pentru restanţele afişate pe lista de plată ce privesc cheltuielile asociaţiei de proprietari, conform prevederilor legale în vigoare;</li>
                <li>k) mandatează preşedintele şi comitetul executiv pentru angajarea şi eliberarea din funcţie a administratorului, cenzorului/comisiei de cenzori sau a altor angajaţi ori prestatori, în scopul administrării şi bunei funcţionări a condominiului;</li>
                <li>l) poate stabili plafoanele minime de la care achiziţionarea bunurilor sau contractarea serviciilor se face pe baza ofertelor operatorilor economici care satisfac cerinţele asociaţiei de proprietari, conform legislaţiei în vigoare;</li>
                <li>m) exercită alte atribuţii care i-au fost conferite prin statut, prin acordul de asociere sau prin votul proprietarilor membrii ai asociaţiei.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.54. – (1) Membrii comitetului executiv sunt desemnaţi de către adunarea generală a asociaţiei de proprietari, dintre membrii asociaţiei de proprietari.</p>
              <p>(2) Comitetul executiv este format din preşedintele asociaţiei şi un număr par de membri, nu mai mare de patru, astfel încât, numărul membrilor comitetului executiv nu poate fi mai mare de cinci.</p>
              <p>(3) În cazul în care o persoană juridică, proprietară a unei locuinţe sau a unui spaţiu cu altă destinaţie decât aceea de locuinţă din cadrul condominiului, este aleasă ca membru al comitetului executiv, aceastaeste reprezentată în comitetul executiv de un reprezentant care are o împuternicire scrisă şi semnată de către aceasta.</p>
              <p>(4) În cazurile prevăzute la art.46 alin.(4), preşedintele asociaţiei de proprietari preia toate atribuţiile şi răspunderile comitetului executiv.</p>
              <p>&nbsp;</p>
              <p>Art.55. – (1) Comitetul executiv are următoarele atribuţii:</p>
              <ol>
                <li>a) convoacă adunarea generală a asociaţiei de proprietari cel puţin o dată pe an şi ori de câte ori este necesar;</li>
                <li>b) duce la îndeplinire hotărârile adunării generale şi urmăreşte respectarea prevederilor legale, a statutului, a acordului de asociere şi a regulamentului condominiului;</li>
                <li>c) emite, dacă este cazul, decizii scrise privind reguli pentru ducerea la îndeplinire a hotărârilor adunării generale şi pentru respectarea prevederilor legale, a statutului şi a acordului de asociere, precum şi alte decizii ce privesc activitatea asociaţiei de proprietari;</li>
                <li>d) propune spre adoptare adunării generale proiectul de regulament al condominiului, cu respectarea dispoziţiilor prezentei legi;</li>
                <li>e) întocmeşte proiectul bugetului de venituri şi cheltuieli şi pregăteşte desfăşurarea adunărilor generale;</li>
                <li>f) stabileşte condiţiile privind folosirea, întreţinerea, repararea, înlocuirea şi modificarea părţilor proprietăţii comune, conform prevederilor legale;</li>
                <li>g) ia măsuri pentru recalcularea cotelor-părţi indivize rezultate în urma modificării suprafeţelor utile ale locuinţelor sau spaţiilor cu altă destinaţie din condominiu;</li>
                <li>h) întocmeşte şi propune adunării generale planuri de măsuri şi activităţi şi urmăreşte realizarea lor;</li>
                <li>i) supraveghează desfăşurarea tuturor activităţilor din cadrul asociaţiei de proprietari, inclusiv situaţia încasărilor şi plăţilor lunare;</li>
                <li>j) stabileşte programul de încasări al asociaţiei de proprietari;</li>
                <li>k) îşi asumă obligaţii, în baza hotărârii adunării generale, în numele proprietarilor membri ai asociaţiei de proprietari, în ceea ce priveşte administrarea condominiului, luând toate măsurile legale necesare;</li>
                <li>l) este consultat în legătură cu toate activităţile care implică asociaţia de proprietari;</li>
                <li>m) asigură urmărirea comportării în timp a construcţiei, pe toată durata de existenţă a acesteia;</li>
                <li>n) gestionează situaţiile excepţionale şi de criză;</li>
                <li>o) urmăreşte recuperarea eventualelor creanţe ale asociaţiei;</li>
                <li>p) gestionează, conform hotărârilor adunării generale, derularea creditelor obţinute pentru consolidare, reabilitare termică, creşterea calităţii ambiental-arhitecturale a condominiului şi alte lucrări;</li>
                <li>q) propune sistemul propriu de penalizări ale asociaţiei de proprietari pentru restanţele afişate pe lista de plată ce privesccheltuielile asociaţiei, în conformitate cu art.77 şi a prevederilor legale în vigoare;</li>
                <li>r) notifică instituţiilor publice abilitate, cazurile în care există suspiciuni de încălcare a prevederilor legale în vigoare;</li>
                <li>s) ţine evidenţa şi răspunde de păstrarea în bune condiţii a arhivei documentelor financiar contabile a asociaţiei de proprietari;</li>
              </ol>
              <p>ş) propune adunării generale cuantumul indemnizaţiilor precum şi numărul şi funcţiile personalului necesar a fi încadrat cu contract individual de muncă sau contract de prestări servicii în cadrul asociaţiei de proprietari pentru buna administrare, gestionare şi funcţionare a condominiului, în limita bugetului de venituri şi cheltuieli;</p>
              <ol>
                <li>t) exercită alte atribuţii legale, care i-au fost conferite prin hotărârile adunării generale.</li>
              </ol>
              <p>(2) Comitetul executiv avizează toate documentele asociaţiei de proprietari, corespondenţa şi registrele privind gestiunea administratorului.</p>
              <p>(3) Şedinţele comitetului executiv se ţin cel puţin o dată pe lună, şi se convoacă pe bază de tabel convocator, de preşedintele asociaţiei de proprietari sau de jumătate plus unu din numărul membrilor săi, cu cel puţin 5 zile înainte de data stabilită pentru şedinţă.</p>
              <p>(4) Deciziile comitetului executiv se consemnează în registrul unic de procese-verbale al asociaţiei de proprietari şi se semnează de toţi membrii prezenţi, precum şi de către cenzor/comisia de cenzori şi se afişează la avizier în termen de maximum 7 zile de la data şedinţei comitetului executiv.</p>
              <p>(5) Administratorul, reprezentantul/reprezentanţii administratorului, soţul/soţia, ascendenţii, descendenţii săi, precum şi cenzorul sau membrii comisiei de cenzori, chiar dacă sunt proprietari, nu pot fi membri ai comitetului executiv al asociaţiei de proprietari.</p>
              <p>(6) În cazul schimbării sau demisiei comitetului executiv al asociaţiei de proprietari, vechiul comitet executiv este obligat sătransmită noului comitet executiv, în baza unui proces-verbal de predare-primire vizat de preşedintele asociaţiei de proprietari şi de către cenzor/comisia de cenzori, într-un termen de 5 zile de la încetareamandatului său, toate documentele şi bunurile asociaţiei pe care le-a avut în administrare şi în folosinţă.</p>
              <p>(7) În cazul schimbării sau demisiei unui membru al comitetului executiv al asociaţiei de proprietari, acesta este obligat să transmită în termen de 5 zile de la încetarea mandatului său, celorlalţi membri ai comitetului executiv, în baza unui proces-verbal de predare-primire vizat de preşedintele asociaţiei de proprietari şi de către cenzor/comisia de cenzori, toate documentele şi bunurile asociaţiei pe care le-a avut în administrare sau în folosinţă.</p>
              <p>(8) În cazul demisiei unui membru al comitetului executiv al asociaţiei de proprietari, membrul demisionar îşi exercită toate atribuţiile şi răspunderile până la data alegerii unui nou membru al comitetului executiv în locul acestuia.</p>
              <p>&nbsp;</p>
              <p>Art.56. – (1) Preşedintele asociaţiei de proprietari este candidatul, membru al asociaţiei de proprietari, ales prin voinţa sa şi a jumătate plus unu din numărul proprietarilor prezenţi în cadrul adunării generale.</p>
              <p>Preşedintele poate delega, pentru cel mult 90 de zile pe an, atribuţiile sale unui membru al comitetului executiv, fără a fi exonerat de răspundere juridică.</p>
              <p>(2) În caz de încetare a mandatului preşedintelui înainte de termen, comitetul executiv convoacă, în termen de 5 zile de la încetarea mandatului, o adunare generală pentru alegerea unui nou preşedinte.</p>
              <p>(3) În cazul schimbării preşedintelui asociaţiei de proprietari, vechiul preşedinte este obligat să transmită noului preşedinte în termen de 5 zile de la încetarea mandatului său, în baza unui proces-verbal de predare-primire vizat de membrii comitetului executiv şi de către cenzor/comisia de cenzori, toate documentele şi bunurile asociaţiei pe care le-a avut în administrare sau în folosinţă.</p>
              <p>(4) În cazul în care adunarea generală a asociaţiei de proprietari decide în acest sens, şi cu acordul preşedintelui în funcţie, asociaţia de proprietari poate finanţa participarea preşedintelui la cursuri de perfecţionare acreditate conform legii, referitoare la constituirea, organizarea şi funcţionarea asociaţiilor de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.57. – Preşedintele asociaţiei de proprietari are următoarele atribuţii:</p>
              <ol>
                <li>a) în baza mandatului acordat de adunarea generală, reprezintă asociaţia de proprietari în relaţiile cu terţii, inclusiv în acţiunile în instanţă;</li>
                <li>b) semnează documentele asociaţiei de proprietari;</li>
                <li>c) supraveghează şi urmăreşte corecta punere în aplicare a hotărârilor adunării generale, respectarea prevederilor statutului şiacordului de asociere, precum şi aplicarea deciziilor comitetului executiv;</li>
                <li>d) aduce la cunoştinţă proprietarilor prevederile statutului şi regulamentului condominului;</li>
                <li>e) răspunde în scris la sesizările şi contestaţiile scrise ale proprietarilor din condominiu în termen de maximum 10 zile de la primirea acestora;</li>
                <li>f) gestionează modul de îndeplinire a obligaţiilor ce revin asociaţiei de proprietari în raport cu autorităţile publice;</li>
                <li>g) prezintă spre verificare la solicitarea organelor de abilitate, toate documentele asociaţiei, oferind toate informaţiile solicitate;</li>
                <li>h) poate propune, în scris, comitetului executiv sau adunării generale, după caz, măsuri împotriva celor care nu respectăregulamentul condominiului, hotărârile şi deciziile asociaţiei de proprietari, conform prevederilor legale şi statutare;</li>
                <li>i) întocmeşte şi actualizează cartea de imobil, în conformitate cu legislaţia în vigoare;</li>
                <li>j) păstrează şi asigură completarea la zi a cărţii tehnice a construcţiei, precum şi a celorlalte documente privitoare la activitatea asociaţiei de proprietari;</li>
                <li>k) întocmeşte tabelul convocator şi afişul pentru avizier pentru adunările generale şi asigură semnarea acestuia de către proprietari;</li>
              </ol>
              <p>1) asigură informarea proprietarilor cu privire la ordinea de zi a adunărilor generale, cu 10 zile înainte de data stabilită pentru aceasta;</p>
              <ol>
                <li>m) afişează programul de încasări şi datele de contact ale administratorului, ale membrilor comitetului executiv şi ale membrilor comisiei de cenzori ori, după caz, ale cenzorului;</li>
                <li>n) afişează la avizier hotărârile adunării generale şi ale şedinţelor comitetului executiv în termen de maximum 7 zile de la data la care au avut loc acestea;</li>
                <li>o) în cazul modificării cadrului legislativ privind asociaţiile de proprietari, preşedintele convoacă adunarea generală a asociaţiei de proprietari pentru modificarea conformă a statutului asociaţiei;</li>
                <li>p) informează proprietarii din condominiu cu privire la toate restricţiile referitoare la culoare, aspect, materiale şi altele asemenea, stabilite prin regulamentul local de urbanism, conform informaţiilor furnizate de autorităţile administraţiei publice locale;</li>
                <li>q) conduce şedinţele adunărilor generale şi ale comitetului executiv;</li>
                <li>r) afişează la avizier lista contractelor asociaţiei de proprietari cu furnizorii de utilităţi publice, cu administratorul şi cu personalul angajat sau contractual al asociaţiei;</li>
                <li>s) afişează la avizier lista şi datele de contact ale instituţiilor cu atribuţii de inspecţie şi control la care pot fi sesizate eventualele nereguli legate de activitatea organelor de conducere ale asociaţiei de proprietari, ale administratorului, respectiv ale membrilor asociaţiei.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.58. – (1) Preşedintele asociaţiei de proprietari şi membrii comitetului executiv pot fi remuneraţi pe baza unui contract de mandat, conform hotărârii adunării generale a proprietarilor, consemnată în procesul-verbal.</p>
              <p>(2) Pentru neîndeplinirea atribuţiilor ce le revin, sau pentru depăşirea atribuţiilor legale şi statutare, preşedintele asociaţiei deproprietari, membrii comitetului executiv, respectiv membrii comisiei de cenzori ori, după caz, cenzorul răspund personal sau solidar, pentru daunele şi prejudiciile cauzate proprietarilor sau terţilor, după caz.</p>
              <p>&nbsp;</p>
              <p>Art.59. – (1) Cenzorul/comisia de cenzori sunt persoanele mandatate de asociaţia de proprietari să urmărească aplicareaprevederilor prezentei legi de către organele de conducere ale asociaţiei de proprietari şi să verifice execuţia bugetului de venituri şi cheltuieli conform prevederilor legale în vigoare.</p>
              <p>(2) Comisia de cenzori este formată dintr-un număr impar de membri, nu mai mare de cinci.</p>
              <p>&nbsp;</p>
              <p>Art.60. – (1) În cazul în care cenzorul/membrii comisiei de cenzori sunt persoane fizice, acestea trebuie să aibă cel puţin studii medii în domeniul economic sau studii în domeniul juridic.</p>
              <p>(2) În cazul în care cenzorul este persoană juridică, acesta trebuie să aibă domeniul de activitate contabilitate, audit financiar sau consultanţă în domeniul fiscal, conform legislaţiei în vigoare.</p>
              <p>(3) Cenzorul sau membrii comisiei de cenzori depun, dacă adunarea generală a asociaţiei de proprietari hotărăşte astfel, în contul bancar al asociaţiei de proprietari, o garanţie, pe baza unui contract de garanţie încheiat în acest sens. Cuantumul garanţiei nu poate fi mai mic decât media anuală a totalului cheltuielilor lunare ale asociaţiei.</p>
              <p>(4) Deponenţii garanţiei prevăzute la alin.(3) nu pot dispune în niciun mod de suma depusă drept garanţie şi nici de dobânda aferentă, decât după îndeplinirea cumulativă a următoarelor condiţii:</p>
              <ol>
                <li>a) adunarea generală a asociaţiei de proprietari a aprobat descărcarea de gestiune privind exerciţiul financiar precedent;</li>
                <li>b) încetarea efectelor contractului încheiat între asociaţie şi cenzor/membrii comisiei de cenzori.</li>
              </ol>
              <p>(5) În cazul în care se constată de către reprezentanţii asociaţiei de proprietari sau de către proprietari deficit în gestiune, asociaţia poate dispune asupra unei sume de bani egale cu cuantumul minusului în gestiune, direct din contul bancar menţionat la alin.(3), cu aprobarea scrisă anterioară a adunării generale, fără a avea nevoie de consimţământul deponentului garanţiei.</p>
              <p>(6) Rapoartele de verificare a gestiunii asociaţiei de proprietari se întocmesc de către cenzor/comisia de cenzori trimestrial şi se prezintă comitetului executiv în cadrul şedinţelor comitetului executiv, pentru luare de măsuri în cazul în care se constată nereguli financiar contabile, şi anual, în cadrul adunărilor generale.</p>
              <p>(7) În cazul constatării unor nereguli în gestiunea financiar contabilă a asociaţiei de proprietari sau referitoare la nerespectareaprevederilor prezentei legi în cazul hotărârilor adunării generale sau comitetului executiv, cenzorul/comisia de cenzori, înştiinţează proprietarii prin afişare la avizierul asociaţiei de proprietari, în termen de cel mult 5 zile de la constatare.</p>
              <p>(8) Cenzorul/membrii comisiei de cenzori, angajaţi ai asociaţiei de proprietari, sunt remuneraţi pe baza unui contract de mandat, conform hotărârii adunării generale a asociaţiei de proprietari, consemnată în procesul-verbal.</p>
              <p>(9) Pentru neîndeplinirea atribuţiilor ce le revin, cenzorul/membrii comisiei de cenzori răspund personal sau în solidar pentru prejudiciile cauzate din vina lor, asociaţiei şi/sau proprietarilor.</p>
              <p>&nbsp;</p>
              <p>Art.61. – Cenzorul/comisia de cenzori a asociaţiei de proprietari are, în principal, următoarele atribuţii:</p>
              <ol>
                <li>a) urmăreşte aplicarea prevederilor prezentei legi de către organele de conducere ale asociaţiei de proprietari;</li>
                <li>b) verifică gestiunea financiar-contabilă;</li>
                <li>c) verifică lunar execuţia bugetului de venituri şi cheltuieli conform documentelor, registrelor şi situaţiei soldurilor elementelor de activ şi pasiv întocmite de către administrator;</li>
                <li>d) cel puţin o dată pe an, întocmeşte şi prezintă adunării generale, rapoarte asupra activităţii sale şi asupra gestiunii asociaţiei de proprietari, propunând măsuri;</li>
                <li>e) execută controale inopinate, împreună cu 2 membri ai comitetului executiv pentru verificarea contabilităţii şi a activităţii de casierie, şi consemnează rezultatele controalelor în registrul unic de procese-verbale al asociaţiei de proprietari;</li>
                <li>f) participă la adunările generale ale asociaţiei şi ale comitetului executiv, asigurând legalitatea desfăşurării acestora.</li>
              </ol>
              <p>Art.62. – În vederea facilitării comunicării dintre autorităţile administraţiei publice locale şi asociaţiile de proprietari, preşedintele asociaţiei de proprietari este obligat să transmită, în termen de 30 de zile de la data modificării, compartimentelor prevăzute la art.10 datele de contact actualizate privind preşedintele, membrii comitetului executiv, membrii comisiei de cenzori sau, după caz, cenzorul şi administratorul.</p>
              <p>&nbsp;</p>
              <p>Capitolul VI</p>
              <p>Administrarea condominiilor</p>
              <p>&nbsp;</p>
              <p>Art.63. – (1) Administrarea, exploatarea, întreţinerea, repararea, reabilitarea şi/sau modernizarea, după caz, a proprietăţii comune aferente condominiului sunt în sarcina asociaţiei de proprietari, iar cheltuielile legate de aceste activităţi reprezintă cheltuieli comune.</p>
              <p>(2) În scopul administrării şi întreţinerii condominiului, asociaţia de proprietari încheie contracte în numele proprietarilor cu persoane fizice, persoane fizice autorizate sau cu persoane juridice cu obiect de activitate administrarea condominiilor, înfiinţate potrivit prevederilor legale în vigoare.</p>
              <p>(3) Desemnarea administratorului şi încheierea contractului de administrare se fac de către comitetul executiv reprezentat depreşedintele asociaţiei de proprietari, în baza mandatului acordat de adunarea generală, şi decizia se comunică tuturor proprietarilor.</p>
              <p>&nbsp;</p>
              <p>Art.64. – (1) Administrarea condominiului poate fi realizată de către persoane fizice, persoane fizice autorizate, sau persoane juridice specializate, autorizate conform legii.</p>
              <p>(2) Activitatea de administrare include activităţi de administrare tehnică şi întreţinere a proprietăţii comune, de contabilitate şi de casierie.</p>
              <p>(3) Orice alte activităţi de administrare care nu sunt reglementate prin lege sau prin statutul asociaţiei, sunt condiţionate de hotărârea adunării generale a asociaţiei de proprietari.</p>
              <p>(4) Administratorii sunt angajaţi pe bază de contract individual de muncă sau contract de prestări servicii, cu respectarea prevederilor legale în vigoare.</p>
              <p>(5) Candidaţii pentru funcţia de administrator trebuie să prezinte comitetului executiv al asociaţiei de proprietari documentele prevăzute de lege, precum şi alte documente solicitate printre care, obligatoriu:</p>
              <ol>
                <li>a) atestatul prevăzut la art.2 lit.e);</li>
                <li>b) cazierul judiciar care să ateste că nu a suferit nicio condamnare, prin hotărâre judecătorească rămasă definitivă, pentru o infracţiune de natură economico-financiară.</li>
              </ol>
              <p>(6) Persoanele juridice specializate sunt operatori economici înfiinţaţi şi înregistraţi conform legii, care au ca obiect de activitate administrarea condominiilor.</p>
              <p>(7) În vederea îndeplinirii obiectului principal de activitate, persoanele juridice prevăzute la alin.(6) au obligaţia obţinerii atestatului în condiţiile legii pentru toţi angajaţii acestora care prestează activitatea de administrare a condominiilor.</p>
              <p>(8) Atestatul se afişează, prin grija administratorului, la avizier, la data intrării în vigoare a contractului de administrare.</p>
              <p>(9) Atestatul administratorului poate fi suspendat sau retras, urmare a unei hotărâri judecătoreşti definitive, în cazul în care instanţele judecătoreşti competente au stabilit existenţa unui prejudiciu cauzat de administrator.</p>
              <p>(10) Atestatul este valabil 4 ani de la data emiterii şi poate fi reînnoit în aceleaşi condiţii în care a fost emis.</p>
              <p>(11) Expirarea, suspendarea sau retragerea atestatului, determină automat imposibilitatea exercitării activităţii de administrare a condominiilor.</p>
              <p>Art.65. – Urmare a hotărârii adunării generale a asociaţiei de proprietari, administratorul, conform contractului de administrare şi în baza împuternicirii acordate de adunarea generală a asociaţiei, poate negocia contractele de furnizare/prestare a serviciilor între furnizori/prestatori şi asociaţia de proprietari.</p>
              <p>Art.66. – (1) Administratorul are, în principal, următoarele atribuţii:</p>
              <ol>
                <li>a) administrează imobilul, propune şi supraveghează lucrări având ca scop conservarea şi întreţinerea acestuia;</li>
                <li>b) supraveghează atât lucrările care privesc administrarea, cât şi personalul angajat;</li>
                <li>c) în caz de urgenţă, cu acordul comitetului executiv, iniţiază executarea tuturor lucrărilor necesare protejării imobilului;</li>
                <li>d) prestează serviciile prevăzute în contractul de administrare cu responsabilitate şi în mod profesional;</li>
                <li>e) execută dispoziţiile prevăzute în hotărârile adunării generale a asociaţiei de proprietari, în conformitate cu prezenta lege, regulamentul condominiului şi conform contractului de administrare;</li>
                <li>f) organizează şi conduce contabilitatea în partidă simplă şi activitatea de casierie;</li>
                <li>g) gestionează, separat pentru fiecare asociaţie, bunurile materiale şi fondurile băneşti ale asociaţiei, conform hotărârilor adunării generale şi deciziilor comitetului executiv;</li>
                <li>h) propune, cel târziu până la sfârşitul perioadei de recalculare a cheltuielilor, bugete anuale şi prognoze pe termen mediu în care trebuie să includă sumele achitate, lucrările de întreţinere şi lucrările de reabilitare şi îmbunătăţire, sumele necesare din fondurile asociaţiei, alte cheltuieli previzibile şi o estimare generală a cheltuielilor asociaţiei de proprietari;</li>
                <li>i) efectuează formalităţile necesare în angajarea contractelor cu furnizorii de servicii pentru exploatarea şi întreţinerea condominiului, derularea şi urmărirea realizării acestor contracte;</li>
                <li>j) asigură gestionarea condominiului conform hotărârilor adunării generale şi deciziilor comitetului executiv, cu respectarea prevederilor legale în vigoare;</li>
                <li>k) are obligaţia prezentării tuturor datelor, documentelor şi informaţilor privind conţinutul şi respectarea contractului deadministrare, precum şi a acordurilor de plată în rate a cheltuielilor anuale, a cuantumului prestaţiilor convenite, la solicitarea oricărui proprietar;</li>
              </ol>
              <p>1) gestionează, conform hotărârilor adunării generale sau comitetului executiv, fondul de rulment şi fondul de reparaţii constituite la dispoziţia asociaţiei de proprietari, precum şi alte fonduri constituite de către aceasta;</p>
              <ol>
                <li>m) calculează, întocmeşte, supune verificării cenzorilor/comisiei de cenzori, supune aprobării comitetului executiv şi afişează la avizier lista lunară a cheltuielilor de întreţinere, întocmită conform reglementărilor în vigoare, în termen de maximum 5 zile de la primirea ultimei facturi de la furnizorii de servicii;</li>
                <li>n) întocmeşte lunar şi depune semestrial la compartimentul specializat în sprijinirea şi îndrumarea asociaţiilor de proprietari pe a cărei rază teritorială se află condominiul, situaţia soldurilor elementelor de activ şi pasiv;</li>
                <li>o) afişează lunar, la avizier, lista de venituri şi cheltuieli ale asociaţiei, care cuprinde inclusiv veniturile obţinute din exploatarea proprietăţii comune;</li>
                <li>p) asigură, prin controale periodice, respectarea normelor generale de apărare împotriva incendiilor la utilizarea părţilor comune, funcţionalitatea permanentă, la gabaritele proiectate, a căilor de evacuare în caz de incendiu şi a celor de acces, intervenţie şi salvare şi comunică de urgenţă preşedintelui asociaţiei de proprietari/comitetului executiv neregulile identificate;</li>
                <li>q) îndeplineşte orice alte atribuţii stabilite în cadrul adunării generale a asociaţiei de proprietari în conformitate cu legea.</li>
              </ol>
              <p>(2) Administratorul nu poate utiliza în niciun fel fondurile asociaţiei de proprietari, fără hotărârea scrisă a adunării generale sau a deciziei comitetului executiv, după caz.</p>
              <p>(3) Administratorul are obligaţia de a ţine evidenţe contabile separate pentru fiecare condominiu în parte pe care îl are în administrare.</p>
              <p>Art.67. – (1) Pentru realizarea lucrărilor de întreţinere, reparaţii sau investiţii privind proprietatea comună, administratorul, ţinând seama de preţ, durata de execuţie şi calitate, supune spre analiză şi aprobare comitetului executiv, oferte ale operatorilor economici care satisfac cerinţele asociaţiei de proprietari, conform legislaţiei în vigoare.</p>
              <p>(2) Administratorul este obligat să folosească pentru plăţile curente şi pentru încasări contul curent al asociaţiei prevăzut la art.20 alin.(1), la care au acces pentru informare, în condiţiile art.2184-2190 din Legea nr.287/2009, republicată, cu modificările ulterioare, toţi proprietarii. În acest cont sunt vărsate, în termen de 24 de ore de la primirea acestora,toate sumele sau valorile primite în numele sau în contul asociaţiei.</p>
              <p>(3) Contul menţionat la alin. (2) poate fi utilizat şi pentru plata cotelor lunare de contribuţie la cheltuielile asociaţiei de către proprietari.</p>
              <p>(4) În cazul în care administratorul nu îşi îndeplineşte obligaţiile, sau le îndeplineşte în mod defectuos, cauzând prejudicii asociaţiei de proprietari, asociaţia de proprietari, în baza hotărârii adunării generale, poate retrage din garanţia administratorului o sumă de bani pentru acoperirea prejudiciilor create, sau poate hotărî diminuarea venitului înfuncţie de gravitatea faptei, în condiţiile prevăzute în contractul de administrare, precum şi de a acţiona în instanţă pentru recuperarea prejudiciilor provocate.</p>
              <p>(5) Prin excepţie de la prevederile alin. (2), cu acordul adunării generale a asociaţiei de proprietari, exclusiv în vederea efectuăriioperaţiuniilor de plăţi pentru cheltuieli neprevăzute, administratorul poate păstra în casierie numerar, în limita unui plafon lunar de 1.000 lei.</p>
              <p>Art.68. – Pentru protejarea intereselor proprietarilor şi ale asociaţiei de proprietari împotriva riscurilor aferente activităţii de administrare într-un condominiu, administratorii, la solicitarea asociaţiilor de proprietari, sunt obligaţi să încheie, pe cheltuială proprie, poliţe de asigurare de răspundere civilă profesională.</p>
              <p>Art.69. – (1) În cazul schimbării administratorului, vechiul administrator este obligat să transmită noului administrator, în bazaunui proces-verbal de predare-primire vizat de preşedintele asociaţiei şi de către cenzor/comisia de cenzori, într-un termen de 5 zile de la încetarea contractului său, totalitatea documentelor, bunurilor şi valorilor aflate în administrarea sa, situaţia financiar-contabilă a asociaţiei şi a fiecărui proprietar în raport cu asociaţia, situaţia soldurilor elementelor de activ şi de pasiv la data predării. Procesul-verbal cuprinde şi chitanţa de plată cu care s-a făcut ultima încasare, numeleproprietarilor restanţieri, sumele restante, contractele în derulare, plăţile ce urmează a fi făcute după data predării.</p>
              <p>(2) În caz de neîndeplinire a prevederilor prevăzute la alin.(1), administratorul nu este descărcat de gestiune şi poate fi acţionat în instanţă de asociaţia de proprietari.</p>
              <p>&nbsp;</p>
              <p>CAPITOLUL VII</p>
              <p>Veniturile şi cheltuielile asociaţiei de proprietari</p>
              <p>Secţiunea 1</p>
              <p>Veniturile asociaţiei de proprietari</p>
              <p>&nbsp;</p>
              <p>Art.70. – (1) Toate veniturile obţinute din exploatarea proprietăţii comune, inclusiv veniturile din dobânzi bancare, aparţin asociaţiei de proprietari.</p>
              <p>(2) Veniturile prevăzute la alin.(1) alimentează numai fondul de reparaţii al asociaţiei de proprietari şi nu se plătesc proprietarilor.</p>
              <p>Lista acestor venituri, precum şi cheltuielile aferente lor sunt prezentate semestrial, într-un raport afişat la avizierul asociaţiei, şi se evidenţiază în registrul unic de venituri şi cheltuieli al asociaţiei de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.71. – (1) Anul fiscal şi exerciţiul financiar al asociaţiei de proprietari este anul calendaristic. Excedentele anuale rezultate dinexecuţia bugetelor de venituri şi cheltuieli, rămase neutilizate la finele exerciţiului bugetar, se reportează în anul următor cu aceeaşi destinaţie.</p>
              <p>(2) Proprietarii membri ai asociaţiei de proprietari au obligaţia să aprobe un fond de reparaţii anual, necesar pentru repararea şi îmbunătăţirea proprietăţii comune. Comitetul executiv fundamentează şi prezintă adunării generale suma anuală necesară pentru constituirea sau completarea fondului de reparaţii, care se alimentează în avans, în tranşe lunare egale, prevăzute în lista de plată a cheltuielilor asociaţiei deproprietari. Fondul de reparaţii este utilizat numai pentru consolidarea condominiului, reabilitarea termică, creşterea calităţii ambiental arhitecturale a construcţiilor, precum şi pentru repararea şi îmbunătăţirea proprietăţii comune.</p>
              <p>(3) Sumele încasate pentru constituirea fondului de reparaţii se depun, în conformitate cu prevederile prezentei legi, în contul asociaţiei de proprietari prevăzut la art.20 alin.(1).</p>
              <p>(4) Pentru încasarea fondului de reparaţii, administratorul eliberează chitanţă nominală separată.</p>
              <p>(5) În termen de 5 zile de la încetarea contractului de administrare, administratorul este obligat să prezinte, în condiţiile prevăzute la art.69 alin.(1), preşedintelui asociaţiei de proprietari şi cenzorului/comisiei de cenzori contabilitatea fondului de reparaţii, a fondului de rulment şi să o transfere noului administrator.</p>
              <p>&nbsp;</p>
              <p>Art.72. – (1) În scopul asigurării sumelor necesare pentru plăţi curente, asociaţia de proprietari este obligată să stabilească cuantumul şi cota de participare a proprietarilor la constituirea fondului de rulment.</p>
              <p>Fondul de rulment se stabileşte astfel încât să poată acoperi cheltuielile curente ale condominiului la nivelul unei luni calendaristice. Stabilirea cuantumului se face prin echivalare cu suma care a fost necesară pentru acoperirea cheltuielilor lunare înregistrate de asociaţia de proprietari în anul anterior, la nivelul lunii cu cheltuielile cele mai mari, majorate cu rata inflaţiei, iar în cazurile asociaţiilor de proprietari nou-înfiinţate, prin aproximarea acestuia cu fondul de rulment stabilit la alte asociaţii de proprietari echivalente ca mărime.</p>
              <p>(2) Fondul de rulment se depune în contul curent al asociaţiei de proprietari, menţionat la art.20 alin.(1).</p>
              <p>(3) Fondul de rulment se constituie prin plata anticipată a cotei ce revine fiecărui proprietar, potrivit hotărârii adunării generale a asociaţiei de proprietari, iar reîntregirea fondului de rulment se face lunar, prin plata sumelor afişate pe lista de plată.</p>
              <p>(4) Pentru încasarea fondului de rulment, administratorul eliberează chitanţă nominală separată.</p>
              <p>(5) Fondul de rulment încasat se restituie la transmiterea dreptului de proprietate, dacă prin actele translative de proprietate nu se stipulează altfel.</p>
              <p>&nbsp;</p>
              <p>Art.73. – (1) În cadrul adunării generale, proprietarii membri ai asociaţiei de proprietari pot aproba şi alte fonduri cu caracter special, precum şi modul de constituire al acestora.</p>
              <p>(2) Toate fondurile speciale ale asociaţiei de proprietari se depun în contul asociaţiei de proprietari, prevăzut la art.20, au evidenţă separată şi pentru fiecare se emite chitanţă separată.</p>
              <p>&nbsp;</p>
              <p>Art.74.- (1) Administratorul organizează şi conduce contabilitatea în partidă simplă a veniturilor şi cheltuielilor asociaţiei deproprietari cu respectarea prevederilor legale în vigoare privind legislaţia financiar-contabilă.</p>
              <p>(2) Dacă din calculele efectuate rezultă un excedent, în sensul că sumele încasate depăşesc în valoare totalul cheltuielilor, aceste sume trebuie regularizate prin lista de plată aferentă lunii următoare sau rambursate proprietarilor îndreptăţiţi în termen de maximum 30 de zile de la constatarea acestora.</p>
              <p>(3) În cazul în care din calculele efectuate rezultă un debit faţă de sumele încasate, acesta trebuie acoperit de cei în drept în termen de 30 de zile de la constatare.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 2-a</p>
              <p>Cheltuielile asociaţiei de proprietari</p>
              <p>&nbsp;</p>
              <p>Art.75. – (1) Cheltuielile asociaţiei de proprietari sunt cheltuieli legate de exploatarea, repararea sau întreţinerea proprietăţii comune, şi cheltuielile pentru asigurarea serviciilor de care beneficiază proprietarii şi care nu sunt facturate individual către proprietăţile individuale.</p>
              <p>(2) Cheltuielile asociaţiilor de proprietari, în funcţie de modul de calcul şi de criteriile de repartizare pe proprietăţi individuale sunt următoarele:</p>
              <ol>
                <li>a) cheltuieli pe număr de persoane care locuiesc sau desfăşoară activităţi în proprietăţi individuale;</li>
                <li>b) cheltuieli pe consumuri individuale;</li>
                <li>c) cheltuieli pe cota-parte indiviză, în funcţie de suprafaţa utilă a proprietăţii individuale;</li>
                <li>d) cheltuieli pe beneficiari, aferente serviciilor individuale ale proprietarilor, dar gestionate financiar prin intermediul asociaţiei de proprietari;</li>
                <li>e) cheltuieli pe consumatori tehnici;</li>
                <li>f) cheltuieli de altă natură.</li>
              </ol>
              <p>(3) Asociaţia de proprietari are obligaţia de a respecta prevederile legale privind modul de repartizare a cheltuielilor comune, în caz contrar, hotărârile luate de adunarea generală a proprietarilor cu încălcarea acestor dispoziţii sunt nule de drept.</p>
              <p>(4) Niciun proprietar din condominiu nu este exceptat de la obligaţia de a contribui la plata cheltuielilor comune, ca urmare a renunţării la folosirea unei părţi din proprietatea comună.</p>
              <p>(5) Prin excepţie de la prevederile alin.(4), în baza hotărârii adunării generale, pot fi exceptate de la plata cheltuielilor aferente consumului de energie electrică pentru funcţionarea ascensorului/ ascensoarelor persoanele care locuiesc în apartamente situate la subsol, demisol, parter, mezanin, precum şi la etajul 1 din clădirile fără mezanin.</p>
              <p>&nbsp;</p>
              <p>Art.76. – Stabilirea şi repartizarea sumei care priveşte proprietatea comună ce revine fiecărui proprietar din cadrul condominiului se fac în conformitate cu prevederile art.48 alin.(5) coroborate cu prevederile art.75 alin.(2).</p>
              <p>&nbsp;</p>
              <p>Art.77. – (1) Asociaţia de proprietari poate stabili un sistem propriu de penalităţi pentru orice sumă cu titlu de restanţă, afişată pe lista de plată. Penalităţile nu vor fi mai mari de 0,2% pentru fiecare zi de întârziere şi se vor aplica numai după o perioadă de 30 de zile de la termenul scadent pentru plată, fără ca suma penalităţilor să poată depăşi suma la care s-au aplicat.</p>
              <p>(2) Termenul de plată a cotelor de contribuţie la cheltuielile asociaţiei de proprietari, afişate pe lista lunară de plată, este de maximum 30 de zile calendaristice de la data afişării.</p>
              <p>(3) Sumele rezultate din aplicarea penalităţilor de întârziere, prevăzute la alin.(1), se includ în fondul de penalităţi al asociaţiei de proprietari şi se vor utiliza cu prioritate pentru plata penalităţilor impuse asociaţiei de proprietari de către terţi, precum şi pentru cheltuieli cu reparaţiile asupra proprietăţii comune, reabilitarea termică sau consolidarea condominiului. Sumele rezultate din aplicarea penalităţilor de întârziere nu pot fi utilizate şi în alte scopuri.</p>
              <p>&nbsp;</p>
              <p>Art.78. – (1) Administratorul este obligat să notifice, în scris, proprietarul care are plăţi restante la cheltuielile comune ale imobilului, asupra datoriilor, şi să înştiinţeze preşedintele şi comitetul executiv al asociaţiei de proprietari despre restanţe.</p>
              <p>(2) Asociaţia de proprietari, prin preşedinte, are dreptul de a acţiona în instanţă proprietarul care se face vinovat de neplata cotelor de contribuţie la cheltuielile asociaţiei mai mult de 60 de zile de la termenul scadent, respectiv 90 zile de la afişarea listei, informând membrii asociaţiei prin afişare la avizier.</p>
              <p>(3) Actiunea asociatiei de proprietari este scutita de taxa de timbru, atât în primă instanţă cât şi în cazul exercitării de către aceasta a căilor de atac, ordinare sau extraordinare.</p>
              <p>(4) Sentinţa dată în favoarea asociaţiei de proprietari, pentru sumele datorate de oricare proprietar din condominiu, poate fi pusă în executare pentru acoperirea datoriilor prin orice modalitate permisă de Legea nr.134/2010 privind Codul de procedură civilă, republicată, cu modificările ulterioare.</p>
              <p>&nbsp;</p>
              <p>Art.79. – Autorităţile administraţiei publice locale au drept de preempţiune la preţ egal asupra locuinţelor aflate în procedura de executare silită pentru recuperarea datoriilor proprietarilor la cotele de contribuţie la cheltuielile asociaţiei, cu respectarea normelor de procedură civilă în vigoare. Aceste locuinţe vor fi folosite ulterior numai ca locuinţe sociale, proprietarii executaţi silit având prioritate la repartizarea acestora, numai dacă îndeplinesc condiţiile de acces la locuinţele sociale, în conformitate cu prevederile legale în vigoare.</p>
              <p>&nbsp;</p>
              <p>Art. 80 alin. (1) Asociaţia de proprietari are ipotecă legală asupra apartamentelor şi altor spaţii proprietăţi individuale ale proprietarilor din condominiu, precum şi un privilegiu asupra tuturor bunurilor mobile ale acestora, pentru sumele datorate cu titlu de cotă de contribuţie la cheltuielile asociaţiei de proprietari.</p>
              <p>(2) De asemenea, pentru aceleaşi creanţe, asociaţia de proprietari beneficiază de un privilegiul general mobiliar asupra bunurilor mobile situate în apartamentele şi celelalte spaţii proprietăţi individuale amplasate în condominiul respectiv. Privilegiul vizează doar bunurile mobile situate în imobilul cu privire la care există sumele datorate.</p>
              <p>(3) Ipoteca imobiliară legală se înscrie în cartea funciară la cererea preşedintelui asociaţiei de proprietari, pe baza extraselor de pe ultima listă lunară de plată a cotelor de contribuţie din care rezultă suma datorată cu titlu de restanţă, numai după împlinirea termenelor prevăzute la art.78 alin.(2).</p>
              <p>Ipoteca imobiliară legală se radiază la cererea preşedintelui asociaţiei de proprietari sau a proprietarului, pe baza chitanţei ori a altui înscris prin care asociaţia confirmă plata sumei datorate.</p>
              <p>(4) Preşedintele asociaţiei de proprietari are obligaţia radierii ipotecii imobiliare, legale, în termen de cel mult 10 zile de la data la care suma datorată cu titlul de restanţă a fost plătită de către proprietar.</p>
              <p>(5) Pentru nerespectarea termenului prevăzut la alin.(4), preşedintele asociaţiei de proprietari răspunde pentru prejudiciile directe sau indirecte aduse proprietarului.</p>
              <p>(6) Operaţiunile de publicitate imobiliară privitoare la ipotecă imobiliară legală şi privilegiul general mobiliar prevăzute la alin.(1) şi (2) sunt scutite de tarif sau taxă de timbru.</p>
              <p>(7) Executorii judecătoreşti sunt obligaţi să înştiinţeze în scris, potrivit prevederilor art. 666 din Legea nr. 134/2010, republicată, cu modificările ulterioare, asociaţia de proprietari cu privire la termenele de intervenţie şi la posibilitatea intervenţiei în cursul executării silite pornite de un creditor împotriva debitorului, proprietar în condominiul în care este constituită respectiva asociaţie de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.81. – Orice proprietar are dreptul de a contracta, în nume şi pe cheltuiala proprie, lucrări de expertiză tehnică a proprietăţii sale individuale sau a proprietăţii comune şi/sau expertiză contabilă a cheltuielilor asociaţiei de proprietari, cu o persoană fizică sau juridică autorizată, conform legislaţiei în vigoare.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 3-a</p>
              <p>Repartizarea cheltuielilor pe număr de persoane care locuiesc saudesfăşoară activităţi în proprietăţi individuale</p>
              <p>&nbsp;</p>
              <p>Art.82. – (1) Cheltuielile repartizate proporţional cu numărul de persoane reprezintă cheltuielile asociaţiei pentru asigurarea serviciilor de utilităţi publice de care beneficiază persoanele care locuiesc sau desfăşoară activităţi în mod curent în condominiu şi care nu pot fi individualizate pe fiecare unitate de proprietate individuală în parte pe baza unor dispozitive de măsurare/înregistrare, fie datorită naturii şi caracteristicilor anumitor servicii, fie din lipsa dispozitivelor de măsurare/înregistrare.</p>
              <p>(2) Cheltuielile repartizate după numărul de persoane reprezintă cheltuielile asociaţiei pentru: apă rece, caldă şi canalizare; combustibil pentru prepararea apei calde de consum; energia electrică utilizată pentru funcţionarea instalaţiilor comune inclusiv a ascensoarelor; colectarea deşeurilor menajere, vidanjare.</p>
              <p>(3) Modul de repartizare a cheltuielilor prevăzute la alin.(1) se stabileşte printr-un regulament aprobat de comitetul executiv al asociaţiei de proprietari.</p>
              <p>(4) Comitetul executiv stabileşte cotele de participare pe persoană la cheltuielile aferente persoanelor cu domiciliul în clădire.</p>
              <p>(5) Dacă adunarea generală a asociaţiei de proprietari hotărăşte în acest sens, comitetul executiv stabileşte cotele de participare pe persoană la cheltuielile aferente persoanelor care locuiesc temporar, minimum 15 zile pe lună, şi stabileşte perioada pentru care proprietarii, respectiv chiriaşii, au dreptul de a solicita, în scris, scutirea de la plata cheltuielilorpe persoană şi care trebuie să fie de cel puţin 15 zile pe lună, conform hotărârii adunării generale.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 4-a</p>
              <p>Repartizarea cheltuielilor pe consumuri individuale</p>
              <p>&nbsp;</p>
              <p>Art.83. – (1) Cheltuielile pe consumuri individuale reprezintă cheltuielile asociaţiei pentru: apă rece şi canalizare; apă caldă deconsum; energia termică, combustibilul şi apă rece pentru prepararea apei calde; gaze naturale; încălzirea proprietăţii&nbsp; individuale.</p>
              <p>(2) Cheltuielile pe consumuri individuale se repartizează în funcţie de indexul contoarelor individuale, în funcţie de indexul repartitoarelor de costuri, conform normelor tehnice şi metodologiilor elaborate de autorităţile naţionale de reglementare sau de autorităţile administraţiei publice locale, sau în funcţie de alte criterii în baza cărora se pot individualiza consumurile, cu respectarea legislaţiei şi a reglementărilor în vigoare.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 5-a</p>
              <p>Repartizarea cheltuielilor pe cota-parte indiviză de proprietate</p>
              <p>&nbsp;</p>
              <p>Art.84.- (1) Cheltuielile repartizate după cota-parte de proprietate reprezintă cheltuielile asociaţiei efectuate pentru întreţinerea, repararea, exploatarea şi, după caz, consolidarea şi/sau reabilitarea părţilor de construcţii şi instalaţii aferente condominiului aflate în proprietate comună indiviză. Aceste cheltuieli se repartizează proporţional cu cota parte din proprietatea comună a fiecărui proprietar.</p>
              <p>(2) Cheltuielile pe cota-parte indiviză de proprietate reprezintă cheltuielile efectuate pentru administrarea, întreţinerea, repararea, exploatarea şi, după caz, consolidarea părţilor de construcţii şi instalaţii din condominiu aflate în proprietate comună.</p>
              <p>&nbsp;</p>
              <p>Art.85. – Cheltuielile pe cota-parte indiviză de proprietate reprezintă cheltuielile asociaţiei cu privire la proprietatea comună, cu privire la: fondul sau fondurile pentru lucrări de întreţinere, service, reparaţii şi, după caz, de consolidare, reabilitare, eficienţă energetică, modernizare la părţile de construcţii şi instalaţii aflate în/şi pe proprietatea comună, astfel cum este descrisă în cartea tehnică a construcţiei şi în acordul de asociere: subsolul; conductele de distribuţie şi coloanele instalaţiilor de apă, canalizare, încălzire, energie electrică; echipamente de echilibrare hidraulică a instalaţiilor; casa scării; podul; spălătoria; uscătoria; terasa; ascensorul; interfonul – partea de instalaţie de pe proprietatea comună; centrala termică proprie; crematoriul; tubulatura de evacuare a deşeurilor menajere; structura de rezistenţă; faţadele; acoperişul şi altele asemenea, conform prevederilor din acordul de asociere; personalul angajat sau contractat al asociaţiei: salarii sau remuneraţii pentru administrator, contabil, instalator, electrician, portar, îngrijitor, personalul care asigură curăţenia etc.; indemnizaţii acordate membrilor asociaţiei de proprietari ori persoanelor alese: preşedintele, membrii comitetului executiv şi cenzorului/comisiei de cenzori; prime; credite bancare; contracte sau poliţe de asigurări; cheltuieli cu produse şi accesorii pentru curăţenie, alte servicii administrative către proprietatea comună.</p>
              <p>&nbsp;</p>
              <p>Art.86. – (1) Suprafaţa utilă a proprietăţilor individuale şi cota-parte indiviză de proprietate sunt cele înscrise în cartea funciară în baza unei documentaţii cadastrale sau, în lipsă, cele din actul de proprietate.</p>
              <p>(2) În cazul în care suma cotelor-părţi indivize din actele de proprietate, care sunt fracţiuni abstracte pentru fiecare proprietateindividuală din clădirea care constituie condominiul, nu este egală cu 100%, preşedintele asociaţiei de proprietari este obligat să solicite întocmirea unei documentaţii cadastrale, prin punerea la dispoziţie a cărţii tehnice a imobilului şi cu respectarea prevederilor în vigoare.</p>
              <p>Secţiunea a 6-a</p>
              <p>Repartizarea cheltuielilor pe beneficiari</p>
              <p>&nbsp;</p>
              <p>Art.87. – (1) Cheltuielile pe beneficiari reprezintă cheltuielile aferente serviciilor de care beneficiază în mod individual proprietarii, toţi sau în parte, gestionate financiar prin intermediul asociaţiei de proprietari.</p>
              <p>(2) Cheltuielile pe beneficiari se repartizează conform valorii care revine fiecărui beneficiar în cauză, pe baza facturilor/documentelor justificative.</p>
              <p>&nbsp;</p>
              <p>Art.88. – Cheltuielile pe beneficiari sunt cheltuielile cu privire la: citirea, calculul şi gestiunea consumurilor individuale de apă rece, apă caldă, căldură, gaze, energie electrică, deşeuri municipale; servicii cu privire la repartitoarele de costuri; chiriile spaţiilor comune – boxe, casa scării, holuri, spaţiu ghenă, terasă etc.; gestiunea financiară; gestiunea contractelor cu caracter individual; întreţinerea interfonului şi a părţii de instalaţie din interiorul proprietăţii individuale; verificarea metrologică a</p>
              <p>contoarelor individuale de energie termică, în cazul distribuţiei orizontale; reviziile instalaţilor interioare de gaze, apă şi încălzire; alte servicii individuale către proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.89. – Pentru proprietarii care beneficiază de anumite servicii de utilităţi şi determină consumuri suplimentare, în condiţiile în care nu există nicio interdicţie legală în acest sens, calculul cotei de contribuţie aferente cheltuielilor pe beneficiari se face conform baremelor stabilite prin normativele tehnice în vigoare sau, în lipsa acestora, conform deciziei comitetului executiv, în funcţie de cantitatea consumată.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 7-a</p>
              <p>Repartizarea cheltuielilor pe consumatori tehnici</p>
              <p>&nbsp;</p>
              <p>Art.90. – Cheltuielile pe consumatori tehnici reprezintă cheltuielile asociaţiei de proprietari pentru încălzirea proprietăţilor individuale – locuinţe sau spaţii cu altă destinaţie decât aceea de locuinţă: energia termică sub formă de căldură, combustibilul şi energia electrică pentru încălzire, salariu/remuneraţie pentru fochist şi echipament de lucru, costuri pentru funcţionarea instalaţiilor de încălzire centrală</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 8-a</p>
              <p>Repartizarea cheltuielilor de altă natură</p>
              <p>&nbsp;</p>
              <p>Art.91. – Cheltuielile de altă natură reprezintă cheltuielile efectuate pentru plata unor servicii care nu pot fi repartizate după criteriile prevăzute la art.75 alin.(2) lit.a)-e).</p>
              <p>&nbsp;</p>
              <p>Art.92. – Cheltuielile de altă natură reprezintă cheltuielile asociaţiei de proprietari pentru: lucrările de întreţinere, reparaţii şi înlocuiri la antenă ori antenele colective de televiziune, care se repartizează în raport cu numărul prizelor de televizoare aflate în locuinţe şi în spaţiile cu altă destinaţie decât aceea de locuinţă, în condiţiile în care nu există o hotărâre a adunării generale prin care sistemul de antenă colectivă să fie desfiinţat sau casat; cutiile poştale, cheile de la uşa de intrare comună în</p>
              <p>clădire, care se repartizează în raport cu numărul proprietarilor; alte cheltuieli, repartizarea făcându-se în conformitate cu clauzele contractuale sau conform modului de facturare; curăţatul coşurilor de fum, care se repartizează fiecărui proprietar proporţional cu numărul sobelor şi al maşinilor de gătit racordate la coşurile de fum ale clădirii.</p>
              <p>&nbsp;</p>
              <p>Art.93. – Alte cheltuieli în cadrul asociaţiei de proprietari se repartizează în conformitate cu normele şi normativele avute în vedere la facturarea comună a acestora şi/sau în conformitate cu clauzele prevăzute în contractele încheiate cu furnizorii respectivelor servicii.</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>CAPITOLUL VIII</p>
              <p>Contractarea şi facturarea serviciilor de utilităţi publice în condominii</p>
              <p>Secţiunea 1</p>
              <p>Contractarea şi facturarea serviciilor de utilităţi publice în condominii</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Art.94. – (1) Raporturile dintre furnizorii serviciilor de utilităţi publice şi asociaţiile de proprietari – în calitate de consumatori colectivi, sau proprietari – în calitate de consumatori individuali, după caz, se stabilesc la nivelul branşamentului, respectiv al racordului, în punctul de delimitare/separare a instalaţiilor între reţeaua publică de distribuţie a utilităţilor şi reţeaua interioară a utilizatorului, respectiv a utilizatorului colectiv sau a consumatorului individual, după caz.</p>
              <p>(2) Calitatea de utilizator, titular al contractului de furnizare/prestare servicii de utilităţi publice, revine:</p>
              <ol>
                <li>a) asociaţiei de proprietari, în cazul în care instalaţiile aflate în proprietatea sau administrarea furnizorului se branşează /racordează la instalaţiile comune interioare ale condominiului aflate în proprietatea comună a tuturor proprietarilor;</li>
                <li>b) proprietarului, în cazul în care instalaţiile aflate în proprietatea sau administrarea furnizorului se branşează/racordează direct la instalaţiile interioare individuale ale apartamentului sau spaţiului cu altă destinaţie decât cea de locuinţă ce aparţin proprietăţii individuale.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.95. – În imobilele de tip condominiu, facturarea serviciilor de utilităţi publice se face de către furnizor cu respectarea dispoziţiilor prezentei legi, într-unul din următoarele două moduri:</p>
              <ol>
                <li>a) facturarea în comun la nivel de asociaţie de proprietari, reprezentând contravaloarea serviciului furnizat/prestat pentru întreg imobilul care se repartizează tuturor proprietarilor din condominiu, în conformitate cu regulile de repartizare stabilite prin prezenta lege şi/sau prin legislaţia specifică serviciului de utilitate publică respectiv;</li>
                <li>b) facturarea individuală la nivel de proprietar, reprezentând contravaloarea serviciului furnizat/prestat pentru fiecare unitate de proprietate imobiliară din condominiu.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.96. – (1) Facturarea individuală la nivel de proprietar în cadrul condominiilor se face în baza:</p>
              <ol>
                <li>a) convenţiilor de facturare individuală, anexe la contractul de furnizare/ prestare încheiat cu asociaţia de proprietari sau</li>
                <li>b) contractului individual de furnizare/ prestare a serviciilor încheiat cu fiecare proprietar.</li>
              </ol>
              <p>(2) Facturarea individuală este permisă numai dacă determinarea consumului se realizează prin intermediul contoarelor supuse controlului metrologic legal, prevăzute la branşamentele condominiului în situaţia prevăzută la art.95 lit.a) ori la branşamentele proprietăţii individuale în situaţia prevăzută la art.95 lit.b).</p>
              <p>(3) Pentru serviciul public de alimentare cu apă şi de canalizare se pot încheia contracte individuale de furnizare/prestare între furnizor şi proprietari fără a fi necesară executarea racordurilor proprii individuale pentru evacuarea apelor uzate şi pluviale de la fiecare proprietate individuală, dacă sunt îndeplinite cumulativ următoarele condiţii:</p>
              <ol>
                <li>a) există branşamente proprii individuale de alimentare cu apă realizate între reţeaua publică şi reţeaua interioară a fiecărei proprietăţi individuale, dotate cu aparate de măsură legale pentru determinarea consumurilor la nivel de proprietate individuală;</li>
                <li>b) furnizarea serviciului de alimentare cu apă, precum şi prestarea serviciului de canalizare se face de către acelaşi operator;</li>
                <li>c) toţi proprietarii din condominiu au fost de acord, în scris, cu introducerea în contractul individual de furnizare/prestare încheiat în nume propriu a clauzei de sistare/întrerupere a serviciului de alimentare cu apă în caz de neplată a contravalorii serviciului de canalizare;</li>
                <li>d) soluţia tehnică de realizare a branşamentelor proprii de alimentare cu apă precum şi tipul de contoare achiziţionate /montate de către proprietari au fost avizate de către furnizor.</li>
              </ol>
              <p>(4) Pentru serviciul public de alimentare cu energie termică, contractele individuale de furnizare între furnizor şi proprietari pot fi încheiate numai dacă sunt îndeplinite cumulativ următoarele condiţii:</p>
              <ol>
                <li>a) există branşamente proprii individuale de alimentare cu energie termică realizate între reţeaua publică şi reţeaua interioară a fiecarei proprietaţi individuale, dotate cu aparate de măsură legale pentru determinarea consumurilor la nivel de proprietate individuală;</li>
                <li>b) toţi proprietarii din condominiu încheie contracte individuale de facturare;</li>
                <li>c) soluţia tehnică de realizare a branşamentelor proprii de alimentare cu energie termică şi tipul de contoare achizitionate /montate de către proprietar au fost avizate de către furnizor;</li>
                <li>d) citirea contoarelor individuale şi defalcarea consumurilor individuale şi comune din condominiu se realizează de către o societate specializată, avizată de autoritatea de reglementare competentă, cheltuielile cu citirea contoarelor fiind suportate de către asociaţia de proprietari.</li>
              </ol>
              <p>(5) Operatorii economici care furnizează/prestează servicii de utilităţi publice către consumatorii alimentaţi/racordaţi printr-unbranşament/racord comun au obligaţia să asigure aceste servicii numai dacă la nivel de condominiu există o asociaţie de proprietari constituită în condiţiile legii care să aibă calitatea de utilizator, titular de contract, sau dacă în termen de 180 de zile de la data intrării în vigoare a prezentei legi se constituie asociaţii de proprietari conform legii.</p>
              <p>(6) Contractele de furnizare/prestare servicii de utilităţi publice în care asociaţia de proprietari este titular de contract se încheie pentru toţi consumatorii din condominiu, inclusiv pentru proprietarii din condominiu care nu sunt semnatari ai acordului de asociere şi nu au calitatea de membri ai asociaţiei de proprietari.</p>
              <p>(7) Niciun proprietar nu poate să lezeze dreptul altui proprietar de a beneficia de facturarea individuală şi să afecteze&nbsp; furnizarea /prestarea serviciului pentru întreg condominiul ca urmare a neachitării cotelor de contribuţie ce îi revin la cheltuielile asociaţiei de proprietari.</p>
              <p>&nbsp;</p>
              <p>Art.97. – (1) În situaţia în care asociaţia de proprietari este titulara contractului de furnizare/prestare servicii de utilităţi publice, în vederea facturării individuale, contractul de furnizare/prestare servicii de utilităţi publice este însoţit de convenţii de facturare individuală anexe la acesta.</p>
              <p>Convenţiile de facturare individuală se încheie în condiţiile stabilite în legislaţia specifică aplicabilă fiecărui serviciu de utilităţi publice.</p>
              <p>(2) Furnizorul serviciilor de utilităţi publice încheie convenţii de facturare individuală pentru fiecare unitate de proprietate imobiliară din condominiu, în conformitate cu legislaţia în vigoare.</p>
              <p>(3) În baza convenţiilor de facturare individuală, furnizorii emit facturi individuale către toţi proprietarii din condominiu care includ atât contravaloarea consumului repartizat proprietăţii individuale, cât şi contravaloarea cotei părţi ce le revine din consumul comun.</p>
              <p>(4) Convenţiile individualede facturare a serviciului public de alimentare cu apă şi cu energie termică încheiate între furnizor şiproprietarii din condominii în calitate de consumatori individuali, anexate la contractul de furnizare/prestare încheiat la nivelul asociaţiei se încheie în una din următoarele condiţii:</p>
              <ol>
                <li>a) la propunerea furnizorului serviciilor de utilităţi publice, cu acordul scris al tuturor proprietarilor din condominiu;</li>
                <li>b) la solicitarea asociaţiei de proprietari, în urma hotărârii adunării generale cu acordul scris al tuturor proprietarilor din condominiu;</li>
              </ol>
              <p>(5) În situaţiile prevăzute la alin.(4) lit.b) furnizorii serviciilor de utilităţi publice sunt obligaţi să dea curs solicitării asociaţiei de</p>
              <p>proprietari, iar proprietarii suportă toate costurile suplimentare generate de facturarea individuală a respectivului serviciu. Costurile suplimentare se includ în facturile lunare individuale.</p>
              <p>(6) În funcţie de modul de facturare practicat în condominiu, fiecare proprietar are obligaţia să plătească asociaţiei de proprietari, respectiv furnizorului serviciilor de utilităţi publice, contravaloarea serviciilor aferente unităţii de proprietate imobiliară.</p>
              <p>(7) În baza contractelor individuale de furnizare/prestare servicii de utilităţi publice sau în baza convenţiilor individuale de facturare, anexe la contractul de furnizare/prestare încheiat la nivelul asociaţiei, pentru recuperarea debitelor, furnizorii pot acţiona direct împotriva proprietarilor restanţieri.</p>
              <p>&nbsp;</p>
              <p>Art.98. – (1) Furnizorul serviciilor de utilităţi publice are dreptul de a acţiona în instanţă proprietarul care se face vinovat de neplata facturilor individuale emise în baza contractelor individuale sau a convenţiilor individuale, după caz, mai mult de 60 de zile de la termenul scadent, respectiv 90 zile de la afişarea listei, cu notificarea proprietarului restant cu cel puţin 7 zile înainte de demararea procedurii de acţiune în instanţă.</p>
              <p>(2) Sentinţa dată în favoarea furnizorului serviciilor de utilităţi publice, pentru sumele datorate, poate fi pusă în executare pentru acoperirea datoriilor la zi prin orice modalitate permisă de Legea nr.134/2010, republicată, cu modificările ulterioare.</p>
              <p>&nbsp;</p>
              <p>Art.99. – (1) Pentru contractele de furnizare cu caracter individual, drepturile şi obligaţiile cu privire la serviciul furnizat aparţin, pe de o parte, furnizorului şi de cealaltă parte, proprietarului.</p>
              <p>(2) În cazul contractelor încheiate între furnizorii serviciilor de utilităţi publice şi asociaţia de proprietari, în lipsa convenţiilorindividuale de facturare, asociaţia de proprietari repartizează facturile către proprietari prin lista de plată lunară, încasează şi achită sumele reprezentând contravaloarea consumului facturat şi înştiinţează furnizorul cu privire la debitori.</p>
              <p>(3) În cazul facturării individuale la nivel de proprietar, conform art.95 lit.b), furnizarea serviciilor de utilităţi publice la nivelul întregului condominiu nu poate fi întreruptă/sistată temporar pentru neplata cotelor de contribuţie la cheltuielile asociaţiei de către unul sau mai mulţi proprietari. Furnizarea serviciilor comunitare de utilităţi publice poate fi întreruptă/sistată temporar numai proprietarilor restanţieri, până la achitarea facturilor/sumelor restante, în baza contractului de furnizare/prestare încheiat la nivel individual, respectiv a convenţiei individuale de facturare încheiată între furnizor şi proprietarii din condominii în calitate de consumatori individuali, anexate la contractul de furnizare/prestare încheiat la nivelul asociaţiei.</p>
              <p>(4) În cazul facturării individuale la nivel de proprietar, conform art.95 lit.b), proprietăţile individuale din condominii pot fideconectate/debranşate de la serviciile de utilităţi publice la cerere, în baza solicitării în scris a proprietarilor acestora şi cu respectarea prevederilor legale în vigoare, sau în caz de neplată a sumelor datorate, în baza contractelor individuale sau a convenţiilor individuale de facturare anexate la contractul de furnizare/prestare încheiat la nivelul asociaţiei, după caz.</p>
              <p>(5) În cazul serviciilor de utilităţi publice care se facturează individual este interzisă sistarea, limitarea sau întrerupereafurnizării/prestării serviciului la nivel de condominiu, pentru neplata facturilor de către unul sau mai mulţi proprietari.</p>
              <p>(6) Condominiile pot fi deconectate/debranşate de la serviciile de utilităţi publice numai la cererea asociaţiei de proprietari, în baza solicitării în scris a tuturor proprietarilor din condominiu şi cu respectarea prevederilor legale în vigoare.</p>
              <p>(7) Pentru recuperarea debitelor cu privire la serviciile de utilităţi publice facturate individual la nivel de proprietar, furnizorul serviciului acţionează strict împotriva proprietarilor restanţieri, în baza contractului de furnizare/prestare individual sau, după caz, a convenţiei individuale anexate la contractul de furnizare/prestare încheiat la nivelul asociaţiei.</p>
              <p>&nbsp;</p>
              <p>Secţiunea a 2-a</p>
              <p>Contractarea şi facturarea serviciilor de utilităţi publice înansamblurile rezidenţiale</p>
              <p>Art.100.- (1) Proprietarii/dezvoltatorii ansamblurilor rezidenţiale au obligaţia să asigure furnizarea/prestarea serviciilor de utilităţi publice către toţi utilizatorii din ansamblul rezidenţial în baza contractelor de furnizare/prestare întocmite cu respectarea clauzelor din Contractele cadru elaborate de către autoritatea de reglementare competentă.</p>
              <p>(2) În ansamblurile rezidenţiale calitatea de utilizator, titular al contractului de furnizare/prestare servicii de utilităţi publice, revine:</p>
              <ol>
                <li>a) asociaţiei de proprietari constituită la nivelul întregului ansamblu rezidenţial, în cazul în care instalaţiile aflate în proprietatea sau administrarea furnizorului se branşează/racordează la instalaţiile comune interioare ale ansamblului rezidenţial aflate în proprietatea comună a tuturor proprietarilor din ansamblul rezidenţial;</li>
                <li>b) proprietarului/dezvoltatorului ansamblului rezidenţial, în cazul în care instalaţiile aflate în proprietatea sau administrarea furnizorului se branşează/racordează la instalaţiile aflate în proprietatea proprietarului/dezvoltatorului ansamblului rezidenţial;</li>
                <li>c) asociaţiei de proprietari constituită la nivelul unui condominiu de tip bloc, în cazul în care instalaţiile aflate în proprietatea sau administrarea furnizorului se branşează/racordează la instalaţiile comune interioare ale condominiului de tip bloc aflate în proprietatea comună a tuturor proprietarilor din acel condominiu de tip bloc;</li>
                <li>d) proprietarului, în cazul în care instalaţiile aflate în proprietatea sau administrarea furnizorului se branşează/racordează direct la instalaţiile interioare ce aparţin proprietăţii individuale.</li>
              </ol>
              <p>(3) În situaţiile prevăzute la alin. (2) lit. a) şi b), asociaţia de proprietari constituită la nivelul ansamblului rezidenţial, respectivproprietarul/dezvoltatorul ansamblului rezidenţial predă autorităţii administraţiei publice locale, în termen de 6 luni de la finalizarea lucrărilor de construcţii, cu titlu gratuit, reţelele comune şi terenul aferent până la branşamentele imobilelor din ansamblul rezidenţial, în vederea preluării în administrare şi exploatare a acestor reţele de către furnizorul care asigură aceste servicii în unitatea administrativ-teritorială respectivă. În cazul asociaţilor de proprietari constituite la nivelulansamblurilor rezidenţiale, decizia de predare în administrare se face cu acordul scris al tuturor proprietarilor.</p>
              <p>(4) În cazul ansamblurilor rezidenţiale finalizate la data intrării în vigoare a prezentei legi, proprietarul/dezvoltatorul ansamblului rezidenţial predă autorităţii administraţiei publice locale, în termen de 3 luni de la intrarea în vigoare a prezentei legi, cu titlu gratuit, reţelele comune şi terenul aferent până la branşamentele imobilelor din ansamblul rezidenţial, învederea preluării în administrare şi exploatare a acestor reţele de către furnizorul care asigură aceste servicii în unitatea administrativ-teritorială respectivă.</p>
              <p>(5) În situaţia prevăzută la alin.(2) lit.b), proprietarul/dezvoltatorul ansamblului rezidenţial are obligaţia să asigure furnizarea /prestarea serviciilor de utilităţi publice către toţi utilizatorii din ansamblul rezidenţial în baza contractelor de furnizare/prestare întocmite cu respectarea clauzelor din Contractele-cadru elaborate de către autoritatea de reglementare competentă.</p>
              <p>&nbsp;</p>
              <p>Art.101. – (1) Preţurile şi tarifele practicate pentru furnizarea serviciilor de utilităţi publice de către proprietarii/dezvoltatorii ansamblurilor rezidenţiale se aprobă de către autoritatea de reglementare competentă.</p>
              <p>Aceştia au obligaţia să ţină evidenţa contabilă separată pentru fiecare serviciu de utilitate publică furnizat/prestat.</p>
              <p>(2) Proprietarii/dezvoltatorii ansamblurilor rezidenţiale au obligaţia să monteze la branşamentele imobilelor contoare de măsurare-înregistrare a consumurilor, conform legislaţiei în vigoare. Determinarea consumurilor care se facturează se face numai prin metoda directă în baza indicaţiilor contoarelor montate la branşamentul fiecărui imobil din ansamblul rezidenţial.</p>
              <p>(3) Proprietarii/dezvoltatorii ansamblurilor rezidenţiale care asigură furnizarea serviciilor de utilităţi publice prin reţele private au aceleaşi drepturi şi obligaţii ca şi operatorii care furnizează/prestează servicii de utilităţi publice prin intermediul reţelelor publice, fiindu-le aplicabile sancţiunile din Legea serviciilor comunitare de utilităţi publice nr.51/2006, republicată, cumodificările şi completările ulterioare, şi din legislaţia specifică serviciului furnizat/prestat.</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>CAPITOLUL IX</p>
              <p>Contravenţii şi sancţiuni</p>
              <p>&nbsp;</p>
              <p>Art.102.- (1) Următoarele fapte constituie contravenţii, dacă nu au fost săvârşite în astfel de condiţii încât să constituie, potrivit legii penale, infracţiuni:</p>
              <p>&nbsp;</p>
              <ol>
                <li>a) neconvocarea adunării generale, conform prevederilor prezentului act normativ;</li>
                <li>b) necomunicarea de către proprietar a modificării suprafeţei utile către asociaţia de proprietari şi administraţia financiară locală;</li>
                <li>c) neluarea de către asociaţia de proprietari, de preşedinte sau de către comitetul executiv al asociaţiei de proprietari a tuturor măsurilor necesare pentru repararea, reabilitarea şi menţinerea în stare de siguranţă şi funcţionare a condominiului şi a instalaţiilor comune aferente pe toată durata existenţei acestora, conform prevederilor legale în vigoare;</li>
                <li>d) nerespectarea de către proprietari a prevederilor art.31 alin.(1);</li>
                <li>e) nerespectarea statutului, a acordului de asociere, precum şi neîndeplinirea sau depăşirea atribuţiilor de către preşedinte, membrii comitetului executiv, cenzor/comisia de cenzori sau de către administrator;</li>
                <li>f) punerea în pericol de către proprietari a integrităţii structurale a condominiului sau a altor proprietăţi individuale, precum şi a bunei funcţionări a instalaţiilor aferente acestora;</li>
                <li>g) schimbarea de către proprietar a destinaţiei locuinţei sau spaţiului cu altă destinaţie decât aceea de locuinţă fără autorizaţiile şi aprobările legale;</li>
                <li>h) neexecutarea de către proprietar a lucrărilor de întreţinere, reparaţii sau de înlocuire a elementelor de construcţii aflate înproprietatea sa individuală, dacă aceasta este de natură să aducă prejudicii celorlalţi proprietari. Fac excepţie cazurile în care au fost îndeplinite prevederile art.30 alin.(5);</li>
                <li>i) modificarea aspectului proprietăţii comune de către proprietari, precum şi a elementelor constructive ale condominiului fără respectarea prevederilor prezentei legi;</li>
                <li>j) neluarea măsurilor în scopul constituirii fondului de reparaţii sau a fondului de rulment;</li>
                <li>k) neîncheierea de către administrator a asigurării pentru răspunderea civilă profesională a administratorului;</li>
              </ol>
              <p>1) utilizarea cu altă destinaţie a fondului de rulment sau a fondului de reparaţii, altfel decât este prevăzut la art.71 şi 72;</p>
              <ol>
                <li>m) utilizarea şi gestionarea veniturilor asociaţiei de proprietari, altfel decât este prevăzut la art.20, art.70 şi art.74;</li>
                <li>n) neaplicarea sau nerespectarea prevederilor art.95-97 şi ale art.99 din prezenta lege de către furnizorii serviciilor de utilităţi publice;</li>
                <li>o) neaplicarea sau nerespectarea prevederilor prezentei legi de către proprietarii ansamblurilor rezidenţiale, conform prevederilor art.14;</li>
                <li>p) nefurnizarea sau refuzul proprietarilor/dezvoltatorilor ansamblurilor rezidenţiale de a furniza/presta serviciile de utilităţi</li>
              </ol>
              <p>publice prin intermediul reţelelor aflate în proprietatea acestora către locuinţele sau spaţiile cu altă destinaţie din respectivele ansambluri rezidenţiale;</p>
              <ol>
                <li>q) desfăşurarea de către persoane fizice sau juridice a activităţii de administrare a condominiilor fără îndeplinirea condiţiilor prevăzute la art.64;</li>
                <li>r) nerespectarea prevederilor art.55 alin.(1) lit.s);</li>
                <li>s) nerespectarea prevederilor art.104 alin.(1).</li>
              </ol>
              <p>(2) Contravenţiile prevăzute la alin.(1) se sancţionează după cum urmează:</p>
              <ol start="3">
                <li>a) faptele prevăzute la alin. (1) lit.d), f) şi k) se sancţionează cu amendă de la 500 lei la 3.000 lei;</li>
                <li>b) faptele prevăzute la alin. (1) lit.a), b), c), r) şi s) cu amendă de la 200 lei la 1.000 lei;</li>
                <li>c) faptele prevăzute la alin. (1) lit.e) şi j) cu amendă de la 2.500 lei la 5.000 lei;</li>
                <li>d) faptele prevăzute la alin. (1) lit.g), h), i), m), p) şi q) cu amendă de la 5.000 lei la 10.000 lei;</li>
                <li>e) faptele prevăzute la alin. (1) lit.l), n), şi o) cu amendă de la 4.000 lei la 9.000 lei.</li>
              </ol>
              <p>(3) Sancţiunile pentru contravenţiile prevăzute la alin.(1) se aplică persoanelor fizice sau juridice vinovate.</p>
              <p>(4) Constatarea contravenţiilor prevăzute la art.102 alin.(1), făcută la sesizarea oricărei persoane interesate sau din oficiu, precum şi aplicarea sancţiunilor corespunzătoare se fac după cum urmează:</p>
              <ol>
                <li>a) de către Inspectoratul de Stat în Construcţii în cazul faptelor prevăzute la alin.(1) lit.f), g) şi h);</li>
                <li>b) de către primari sau de împuterniciţii acestora sau de către Poliţia Locală în cazul faptelor prevăzute la alin.(1) lit.a)-e), i)-m), o)-s);</li>
                <li>c) de către Autoritatea Naţională de Reglementare pentru Serviciile Comunitare de Utilităţi Publice sau, după caz, de către Autoritatea Naţională de Reglementare în domeniul Energiei în cazul faptelor prevăzute la alin.(1) lit.n).</li>
              </ol>
              <p>(5) În cazul în care contravenientul se află la prima încălcare a obligaţiei legale, organul constatator poate să aplice sancţiunea avertismentului, conform prevederilor legale în vigoare, numai în cazul faptelor prevăzute la alin.(1) lit. a), b), j) sau s).</p>
              <p>&nbsp;</p>
              <p>Art.103.- Prevederile referitoare la contravenţii din prezenta lege se completează cu dispoziţiile Ordonanţei Guvernului nr.2/2001 privind regimul juridic al contravenţiilor, aprobată cu modificări şi completări prin Legeanr.180/2002, cu modificările şi completările ulterioare.</p>
              <p>&nbsp;</p>
              <p>Capitolul X</p>
              <p>Dispoziţii tranzitorii şi finale</p>
              <p>&nbsp;</p>
              <p>Art.104. – (1) În termen de 12 luni de la intrarea în vigoare a prezentei legi, asociaţiile de locatari din condominiile ale căror unităţi de proprietate individuală au fost dobândite în proprietate în condiţiile legii şi care nu aufost reorganizate în asociaţii de proprietari au obligaţia să se reorganizeze în conformitate cu prevederile prezentei legi.</p>
              <p>(2) Asociaţiile de proprietari înfiinţate anterior intrării în vigoare a prezentei legi îşi păstrează statutul juridic obţinut la data înfiinţării acestora, dar se vor organiza şi vor funcţiona în conformitate cu prevederile prezentei legi.</p>
              <p>&nbsp;</p>
              <p>Art.105. – În termen de 60 de zile de la data intrării în vigoare a prezentei legi, preşedinţii asociaţiilor de proprietari sunt obligaţi să transmită autorităţilor administraţiei publice locale numele şi datele de contactactualizate privind preşedintele comitetul executiv, comisia de cenzori/cenzorul şi administratorul.</p>
              <p>&nbsp;</p>
              <p>Art.106. – Persoanele fizice atestate pentru funcţia de administrator în conformitate cu prevederile Legii nr.230/2007 privind înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari, cu modificările şi completărileulterioare, pot îndeplini funcţia de administrator numai dacă, în termen de 12 luni de la intrarea în vigoare a prezentei legi obţin certificate de calificare profesională care dovedesc calificarea profesională pentru a îndepliniocupaţia de administrator, în conformitate cu prezenta lege.</p>
              <p>&nbsp;</p>
              <p>Art.107. – În termen de 12 luni de la data intrării în vigoare a prezentei legi:</p>
              <ol>
                <li>a) proprietarii din condominii sunt obligaţi să aprobe un regulament al condominiului;</li>
                <li>b) proprietarii care nu au informat asociaţia de proprietari, în conformitate cu art.34 alin.(3), respectiv cu privire la modificările</li>
              </ol>
              <p>suprafeţelor construite sau utile efectuate anterior intrării în vigoare a prezentei legi, sunt obligaţi să comunice asociaţiei de proprietari modificările suprafeţelor respective;</p>
              <ol>
                <li>c) preşedinţii asociaţiilor de proprietari sunt obligaţi să actualizeze, înbaza actelor de proprietate asupra locuinţelor şi/sau spaţiilor cu altă destinaţiedecât aceea de locuinţă din condominiu, toate informaţiile privindproprietăţile, suprafeţele şi cotele-părţi indivize aferente acestora, în vedereacalculului cotelor de contribuţie la cheltuielile asociaţiei pentru respectiva locuinţă.</li>
              </ol>
              <p>&nbsp;</p>
              <p>Art.108. – (1) În termen de 3 luni de la data intrării în vigoare a prezentei legi, Ministerul Educaţiei Naţionale şi Ministerul Dezvoltării Regionale şi Administraţiei Publice, în colaborare cu asociaţiile profesionale şi dereprezentare ale asociaţiilor de proprietari propun actualizarea standardului ocupaţional privind ocupaţia de administrator de condominii.</p>
              <p>(2) În termen de 3 luni de la data intrării în vigoare a prezentei legi, se emite un ordin al ministrului dezvoltării regionale şi administraţiei publice privind aprobarea conţinutului-cadru al statutului şi al regulamentului de condominiu.</p>
              <p>&nbsp;</p>
              <p>Art.109. – Prezenta lege intră în vigoare la 60 de zile de la data publicării în Monitorul Oficial al României, Partea I.</p>
              <p>&nbsp;</p>
              <p>Art.110. – La data intrării în vigoare a prezentei legi, se abrogă Legeanr.230/2007 privind înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari, publicată în Monitorul Oficial al României, Partea I, nr.490 din 23 iulie 2007, cu modificările şi completările ulterioare, precum şi HotărâreaGuvernului nr.1588/2007 pentru aprobarea Normelor metodologice de aplicare a Legii nr.230/2007 privind înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari, publicată în Monitorul Oficial al României, Partea I, nr.43 din 18 ianuarie 2008, precum şi orice alte prevederi contrare.</p>
              <p>&nbsp;</p>
              <p>Această lege a fost adoptată de Parlamentul României,</p>
              <p>cu respectarea prevederilor art. 75 şi ale art. 76 alin. (2) din Constituţia</p>
              <p>României, republicată.</p>

            </div>
          </div>
        </div>
      </div>

      <hr class="space"/>
    </div>
  </div>
</div>

<app-footer></app-footer>
