import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legislation',
  templateUrl: './legislation.component.html',
  styleUrls: ['./legislation.component.css']
})
export class LegislationComponent implements OnInit {
  showfull: boolean;

  constructor() { }

  ngOnInit(): void {
  }


  showfullText(): void {
    this.showfull = true;
  }
}
