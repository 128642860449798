import { Component, OnInit } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navbarCollapsed: boolean;
  private width: number;

  constructor(private router: Router) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if (this.width < 992) {
          this.navbarCollapsed = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.navbarCollapsed = false;
    this.width = window.innerWidth;
    if (window.innerWidth < 992) {
      this.navbarCollapsed = true;
    }
  }

  navbarToggle() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }
  onResize(event) {
    if (event.target.innerWidth < 992) {
      this.navbarCollapsed = true;
      this.width = event.target.innerWidth;
    } else {
      this.navbarCollapsed = false;
      this.width = event.target.innerWidth;
    }
  }
}
