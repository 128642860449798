<app-header></app-header>


<div class="section-bg-image" style="background-image: url(&quot;assets/images/bckg1.png&quot;);">
  <div class="container content">
    <div class="row vertical-row">
      <div class="col-md-12">
        <div class="title-base text-left">
          <hr/>
          <h2>Servicii Oferite</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container content no-padding-top">

    <div class="row ">

      <div class="col-lg-12 ">
        <div class="advs-box advs-box-top-icon-img boxed-inverse shadow-1">
          <div class="advs-box-content">

            <div class="col-md-12">
              <h3>
                Impreuna cu colaboratorii si partenerii nostri, oferim o gama completa de servicii pentru administrarea imobilelor, pentru asociatiile de proprietari si pentru firmele care doresc externalizarea activitatii de administrare a imobilelor
              </h3>
              <h3>
                Oferim de asemenea si expertiza pentru infiintarea asociatiilor de proprietari.
              </h3>
              <a routerLink="/contact" class="circle-button btn btn-whatsapp btn-lg shadow-1 margin-bottom-50"><i class="fa fa-envelope"></i>
                Cereti o oferta acum</a>

            </div>



          </div>
        </div>
      </div>
    </div>
    <hr class="space"/>
    <div class="row vertical-row">
      <div class="col-md-5 hidden-sm visible-xs">
        <img src="/assets/images/administrare-blocuri.png" alt=""/>
      </div>
      <div class="col-md-8">
        <h1 class="text-color">Administrare imobile</h1>
        <hr class="space m"/>
        <div class="row">
          <div class="col-md-12">
            <p>Oferim servicii complete pentru administrarea imobilelor: case, vile, blocuri, ansambluri rezidentiale si cladiri de birouri.
            Prin personal autorizat cu experienta, va stam la dispozitie pentru o gama completa de servicii.</p>
          </div>

        </div>
      </div>
    </div>
    <hr class="space"/>
    <div class="row vertical-row">

      <div class="col-md-8">
        <h1 class="text-color">Mentenanta</h1>
        <p>
          Impreuna cu colaboratorii nostri prestam orice fel de serviciu de mententanta prevazut in contractul de administrare.
          Propunem si supraveghem orice fel de lucrare care are ca scop conservarea si intretinerea imobilului,
        </p>
        <p>
          Pentru realizarea lucrarilor de intretinere, reparatii sau investitii privind proprietatea comuna, administratorul, tinând seama de pret, durata de executie si calitate, supune spre analiza si aprobare comitetului executiv oferte ale operatorilor economici care satisfac cerintele asociatiei de proprietari, conform legislatiei in vigoare.
        </p>


      </div>
      <div class="col-md-4 text-right hidden-sm visible-xs">
        <img src="/assets/images/intretinere-ansambluri.png" alt=""/>
      </div>
    </div>
    <hr class="space"/>
    <div class="row vertical-row">
      <div class="col-md-5 hidden-sm visible-xs">
        <img src="/assets/images/administrare-blocuri.png" alt=""/>
      </div>
      <div class="col-md-8">
        <h1 class="text-color">Reparatii</h1>
        <hr class="space m"/>
        <div class="row">
          <div class="col-md-12">
            <p>Datorita partenerilor nostri, oferim personal calificat pentru executarea lucrarilor de reparatii si intretinere a imobilului dumneavoastra. Folositi zona de <a routerLink="/contact" >Contact</a> de pe site daca doriti sa solicitati ajutorul unui specialist sau doriti sa deschideti o sesizare.</p>
          </div>

        </div>
      </div>
    </div>
    <hr class="space"/>
  </div>
</div>

<app-footer></app-footer>
