<app-header></app-header>

<div id="block-signflowblocksectioncontentleaderofarchitecture"
     class="section-bg-image border-bottom bg-top bg-no-repeat block-bg"
     style="background-image: url(&quot;assets/images/bckg1.png&quot;);">
  <div class="container content padding-top-20">
    <div class="row">
      <div class="col-lg-12 aligncenter">
        <h4>Servicii complete pentru administrarea imobilelor in Bucuresti</h4>
        <img src="assets/images/index-background-1600-1.png" alt="Administrare Imobile Bucuresti">
      </div>


      <div class="col-lg-12 ">
        <div class="advs-box advs-box-top-icon-img boxed-inverse shadow-1">
          <div class="advs-box-content">

            <div class="col-md-12">
              <br/>
              <h3>
                Metropolitan Expert Solutions ofera servicii complete pentru administrarea imobilelor, pentru asociatiile de proprietari si pentru firmele care doresc externalizarea activitatii de administrare a imobilelor
              </h3>
                <a routerLink="/contact" class="circle-button btn btn-whatsapp btn-lg shadow-1 margin-bottom-50"><i class="fa fa-envelope"></i>
                  Cereti o oferta acum</a>

            </div>



          </div>
        </div>
      </div>



    </div>
  </div>


  <div class="section-bg-image services" style="background-color: #0e1a35 ">
    <div class="container content">
      <div class="row vertical-row">
        <div class="col-md-12">
          <div class="title-base text-left">
            <h3>SERVICIILE NOASTRE</h3>

          </div>
        </div>
      </div>
      <hr class="space"/>
      <div class="row vertical-row">
        <div class="col-md-5 hidden-sm visible-xs">
          <img src="/assets/images/administrare-blocuri.png" alt=""/>
        </div>
        <div class="col-md-8">
          <h3 class="text-color">Administrare autorizata</h3>
          <hr class="space m"/>
          <div class="row">
            <div class="col-md-12">
              <ul class="fa-ul">
                <li><i class="fa-li fa fa-check"></i> Admninistrare ansambluri rezidentiale</li>
                <li><i class="fa-li fa fa-check"></i> Administrare blocuri de locuinte</li>
                <li><i class="fa-li fa fa-check"></i> Administrare cladiri de birouri</li>
                <li><i class="fa-li fa fa-check"></i> Administrare case, vile</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <hr class="space"/>
      <div class="row vertical-row">

        <div class="col-md-8">
          <h3 class="text-color">Mentenanta</h3>

          <hr class="space s"/>
          <div class="row">
            <div class="col-md-12">
              <ul class="fa-ul">
                <li><i class="fa-li fa fa-check"></i> Intretinerea si repararea proprietatii comune.</li>
                <li><i class="fa-li fa fa-check"></i> Reabilitarea si modernizarea proprietatii comune.</li>
                <li><i class="fa-li fa fa-check"></i> Mentinerea in stare buna a imobilului.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-right hidden-sm visible-xs">
          <img src="/assets/images/intretinere-ansambluri.png" alt=""/>
        </div>
      </div>
      <hr class="space"/>
      <div class="row vertical-row">
        <div class="col-md-5 hidden-sm visible-xs">
          <img src="/assets/images/reparatii-intretinere-ansambluri-rezidentiale.png" alt=""/>
        </div>
        <div class="col-md-8">
          <h3 class="text-color">Reparatii</h3>
          <hr class="space m"/>
          <div class="row">
            <div class="col-md-12">
              <ul class="fa-ul">
                <li><i class="fa-li fa fa-check"></i> Reparatii instalatii comune</li>
                <li><i class="fa-li fa fa-check"></i> Reparatii instalatii individuale</li>
                <li><i class="fa-li fa fa-check"></i> Service in perioada de garantie si post garantie</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <hr class="space"/>
      <div class="row vertical-row">
        <div class="col-md-12 ">
          <a routerLink="/contact" class="alignright circle-button btn btn-whatsapp btn-lg shadow-1 margin-bottom-50"><i class="fa fa-envelope"></i>
            Cereti o oferta acum</a>

        </div>
      </div>
    </div>
  </div>


  <div id="block-signflowblocksectioncontentleaderofarchitecture"
       class="section-bg-image border-bottom bg-top bg-no-repeat block-bg"
       style="background-image: url(&quot;assets/images/bckg2.png&quot;);">
    <div class="container content">
      <div class="row vertical-row">
        <div class="col-md-12">
          <div class="title-base text-left">
            <h3>CONTACT</h3>
          </div>
        </div>
      </div>
      <hr class="space"/>
      <div class="row vertical-row">
        <div class="col-md-12 boxed-inverse shadow-1">
          <div class="row">
            <div class="col-md-8">
              <h4><b>Metropolitan Expert Solutions</b><br/>Telefon: <a href="tel:0720695418">0720.695.418</a>
                / <a href="tel:0741101036">0741.101.036</a><br>
                Email: <a href="mailto:contact@administrator-bloc-bucuresti.ro">contact@administrator-bloc-bucuresti.ro</a></h4>
              <div>
                <br/>
                <p>Va stam oricand la dispozitie pentru orice intrebare sau solicitare</p>
                <p>Oferim si suport juridic pentru orice fel de situatie cu care va confruntati</p>
                <p>Nu ezitati sa ne contactati!</p>
              </div>
              <hr class="space s">
            </div>
            <div class="col-md-4">
              <img src="assets/images/logo.png" alt="Servicii Administrare Blocuri" class="img-rounded">
            </div>
          </div>
        </div>
      </div>

      <hr class="space"/>

    </div>
  </div>

  <app-footer></app-footer>
  <script src="assets/scripts/flexslider/jquery.flexslider-min.js"></script>
