<app-header></app-header>

<div class="section-bg-image bg-center " style="background-image: url(&quot;assets/images/bckg4.png&quot;);">
  <div class="container content">
    <div class="row vertical-row">
      <div class="col-md-7 col-sm-6">
        <div class="title-base text-left">
          <h2>Acces Proprietari</h2>
        </div>
      </div>
    </div>
    <hr class="space"/>
    <h3 style="text-align: center">! Pagina in lucru ! </h3>
    <hr class="space"/>
    <div class="col-md-11 boxed-inverse shadow-1">
      <p><b>Date autentificare:</b></p>
      <!--<form class="form-box form-ajax" #userForm="ngForm" (ngSubmit)="onFormSubmit(userForm)">-->
      <form action="" class="form-box form-ajax" method="post">
        <div class="row">

          <div class="col-md-6">
            <p>E-mail</p>
            <input type="email" name="email" id="email" class="form-control" placeholder="Adresa Email"
                   [(ngModel)]="data.email" required/>
            <span class="error_msg" id="email_error_msg"></span>
          </div>
          <div class="col-md-6">
            <p>Parola</p>
            <input type="password" name="password" id="password" class="form-control" placeholder="Parola" [(ngModel)]="data.password"
                   required/>
            <span class="error_msg" id="name_error_msg"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 padding-top-20">
            <div class="alert alert-success" *ngIf="showSuccessMessage">
              <strong>Succes!</strong> Datele dumneavoastra au fost trimise cu succes.
            </div>
            <div class="alert alert-warning" *ngIf="showErrorMessage">
              <strong>Eroare!</strong> {{errorMessage}}.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 padding-left-70">
            <input type="checkbox" name="agree" id="agree" [(ngModel)]="data.agree"/>&nbsp;&nbsp;&nbsp;
              Am citit si sunt de acord cu <a href="/#/termeni-conditii" target="_blank">Termenii si conditiile</a> administrator-bloc-bucuresti.ro
          </div>
        </div>



        <div class="row">
          <div class="col-md-12 align-right">
            <button type="submit" name="submit" id="submit" class="btn btn-default" >Autentificare
            </button>
          </div>
        </div>
        <hr class="space"/>

      </form>
    </div>

  </div>
  <hr class="space s">
</div>
<app-footer></app-footer>
